define("frontend/routes/dashboard", ["exports", "frontend/mixins/route/secure", "frontend/util/features"], function (exports, _secure, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_secure.default, {
    auth: Ember.inject.service(),

    model: function model() {
      if (!(0, _features.default)("dashboard") || !this.get("auth.user.employee")) {
        this.transitionTo("employees");
      }

      return Ember.RSVP.hash({
        organisation: this.get("auth.user.organisation"),
        user: this.get("auth.user")
      });
    }
  });
});