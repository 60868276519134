define("frontend/controllers/employees/employee/index", ["exports", "ember-data", "moment", "frontend/util/timecalculations", "frontend/util/computed"], function (exports, _emberData, _moment, _timecalculations, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // Controller receives no, partial or full selection of a calendar view
    month: null,
    year: null,
    day: null,

    queryParams: ["month", "year", "day"],

    reloadCounter: 0,

    notify: Ember.inject.service(),
    logger: Ember.inject.service(),
    ajax: Ember.inject.service(),
    auth: Ember.inject.service(),
    payment: Ember.inject.service(),
    router: Ember.inject.service(),

    hasSubscription: Ember.computed.readOnly("payment.hasSubscription"),

    selectedYear: (0, _computed.yearWithDefault)("year"),
    selectedMonth: (0, _computed.monthWithDefault)("month"),
    selectedDay: (0, _computed.parseIntWithDefault)("day"),

    selectionEnabled: true,

    // Actually not by day, but the index is one off (so 1st day of month is index 0)
    presencesByDay: Ember.computed("selectedMonth", "selectedYear", "model.{id,status}", "reloadCounter", function () {
      var _this = this;

      var fromDate = (0, _moment.default)([this.get("selectedYear"), this.get("selectedMonth") - 1, 1]);
      var toDate = (0, _moment.default)([this.get("selectedYear"), this.get("selectedMonth") - 1, fromDate.daysInMonth()]);

      var firstDayToShow = _timecalculations.default.calculateFirstDayToShow(fromDate).format("YYYY-MM-DD");
      var lastDayToShow = _timecalculations.default.calculateLastDayToShow(toDate).format("YYYY-MM-DD");

      var promise = this.get("ajax").request("/api/presences/report?employee_id=" + this.get("model.id") + "&from=" + firstDayToShow + "&to=" + lastDayToShow);
      promise.catch(function (error) {
        _this.get("logger").catch(error, "Error getting report for employee", {
          employeeId: _this.get("model.id")
        });
      });

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    vacationReport: Ember.computed("selectedYear", "model.id", "reloadCounter", function () {
      var _this2 = this;

      var promise = this.get("ajax").request("/api/vacations/report?employee_id=" + this.get("model.id") + "&year=" + this.get("selectedYear"));
      promise.catch(function (error) {
        _this2.get("logger").catch(error, "Error getting vacation report for employee", { employeeId: _this2.get("model.id") });
      });

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    hasActiveEmployment: Ember.computed("vacationReport.report", function () {
      var report = this.get("vacationReport.report");
      return report && (!!report.takenVacations || report.entitlement > 0);
    }),

    refresh: function refresh() {
      this.incrementProperty("reloadCounter");
    },
    disableSelection: function disableSelection() {
      this.set("selectionEnabled", false);
    },
    enableSelection: function enableSelection() {
      this.set("selectionEnabled", true);
    },


    actions: {
      edit: function edit() {
        this.get('router').transitionTo("employee.edit", this.get("model"));
      },
      delete: function _delete() {
        var _this3 = this;

        var employee = this.get("model");
        employee.destroyRecord().then(function () {
          // Make sure to unload record, so a restore will load the record into the store again (otherwise it keeps on being deleted)
          employee.unloadRecord();

          _this3.get("notify").success("Personal gelöscht");
          _this3.get("router").transitionTo("employees");
        }, function (error) {
          _this3.get("logger").catch(error, "Error deleting employee", {
            employeeId: _this3.get("model.id")
          });
        });
      },
      statusUpdated: function statusUpdated() {
        this.refresh();
      },
      onCalendarSelect: function onCalendarSelect(_ref) {
        var year = _ref.year,
            month = _ref.month,
            day = _ref.day;

        if (!this.get("selectionEnabled")) {
          // Prevent selection while transitioning (causes race condition for active route - see #25055)
          return;
        }
        var selectedYear = this.get("selectedYear"),
            selectedMonth = this.get("selectedMonth"),
            isDefault = this.get("year") === null || this.get("month") === null;
        if (year !== selectedYear || month !== selectedMonth || isDefault) {
          this.setProperties({
            year: year,
            month: month,
            day: day
          });
        } else {
          this.setProperties({
            day: day
          });
        }
      },
      reload: function reload() {
        this.incrementProperty("reloadCounter");
      }
    }
  });
});