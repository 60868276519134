define("frontend/helpers/feature-enabled", ["exports", "frontend/util/features"], function (exports, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.featureEnabled = featureEnabled;
  function featureEnabled(featureName) {
    return (0, _features.default)(featureName);
  }

  exports.default = Ember.Helper.helper(featureEnabled);
});