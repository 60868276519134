define("frontend/controllers/application", ["exports", "frontend/util/error-status", "frontend/util/features"], function (exports, _errorStatus, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    ajax: Ember.inject.service(),
    stream: Ember.inject.service(),
    logger: Ember.inject.service(),

    user: Ember.computed.readOnly("auth.user"),
    organisation: Ember.computed.readOnly("user.organisation"),
    employee: Ember.computed.readOnly("user.employee"),
    email: Ember.computed.readOnly("auth.user.email"),
    isAuthenticated: Ember.computed.readOnly("auth.authenticated"),
    connected: Ember.computed.readOnly("stream.connected"),
    isTrackingEnabled: (0, _features.default)('tracking'),

    actions: {
      logout: function logout() {
        var _this = this;

        this.get("ajax").post("/api/logout", {
          dataType: "text"
        }).then(function () {
          _this.get("auth").reset();
          window.location.href = "/";
        }, function (error) {
          if ((0, _errorStatus.default)(error) === 401) {
            // No op, if not authenticated, all is well
            return;
          }
          _this.get("logger").catch(error, "Error performing logout");
        });
      }
    }
  });
});