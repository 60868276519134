define("frontend/models/organisation", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Organisation = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    street: _emberData.default.attr("string"),
    zip: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),

    customer: _emberData.default.attr("object"),
    subscription: _emberData.default.attr("object"),

    trialEnd: _emberData.default.attr("date"),

    hasStreet: Ember.computed.notEmpty("street"),
    hasZip: Ember.computed.notEmpty("zip"),
    hasCity: Ember.computed.notEmpty("city"),
    addressIsComplete: Ember.computed.and("street", "zip", "city"),
    enabledFeatures: _emberData.default.attr("object")
  });
  exports.default = Organisation;
});