define("frontend/controllers/account", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),

    user: Ember.computed.readOnly("auth.user"),
    employee: Ember.computed.readOnly("user.employee"),
    organisation: Ember.computed.readOnly("user.organisation")
  });
});