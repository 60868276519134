define("frontend/routes/reports/monthly", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (Object.keys(transition.queryParams).length > 0) {
        var queryParams = {
          month: transition.queryParams.month ? parseInt(transition.queryParams.month) : null,
          year: transition.queryParams.year ? parseInt(transition.queryParams.year) : null
        };
        var thisMonth = (0, _moment.default)().month() + 1; // moment is zero-based
        var thisYear = (0, _moment.default)().year();
        var effectiveYear = queryParams.year || thisYear;
        var effectiveMonth = queryParams.month || thisMonth;
        var effectiveDate = (0, _moment.default)([effectiveYear, effectiveMonth - 1, 1]);

        if (effectiveDate.isAfter((0, _moment.default)().startOf("month"))) {
          // Transition to current month in current year
          transition.abort();
          this.transitionTo("reports.monthly", {
            queryParams: {
              year: thisYear.toString(),
              month: thisMonth.toString()
            }
          });
        }
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        employees: this.store.findAll("employee", { reload: true })
      });
    }
  });
});