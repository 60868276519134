define("frontend/routes/not-found", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    logger: Ember.inject.service(),

    activate: function activate() {
      var url = window.location.pathname;
      this.get("logger").catch("UnrecognizedURLError", "Not Found", { url: url }, false, "warning");
    }
  });
});