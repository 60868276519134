define("frontend/routes/employees/archive", ["exports", "frontend/util/error-status"], function (exports, _errorStatus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model: function model() {
      var promise = this.get("ajax").request("/api/employees?archived=1");
      return promise.then(function (_ref) {
        var employees = _ref.employees;

        return employees.map(function (employee) {
          return _extends({}, employee, {
            deleted: new Date(employee.deleted)
          });
        });
      }, function (error) {
        var reason = void 0;
        if ((0, _errorStatus.default)(error) === 401) {
          reason = Ember.get(error, "payload.error");
        } else {
          reason = error;
        }
        return Ember.RSVP.reject(reason);
      });
    },


    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
});