define("frontend/routes/employees", ["exports", "frontend/mixins/route/secure"], function (exports, _secure) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_secure.default, {
    stream: Ember.inject.service("stream"),

    model: function model() {
      return this.store.findAll("employee", { reload: true });
    },

    activate: function activate() {
      var _this = this;

      this.get("stream").on("reconnected", this, this.refresh);

      this._refreshOnFocus = function () {
        _this.refresh();
      };
      window.addEventListener("focus", this._refreshOnFocus);
    },
    deactivate: function deactivate() {
      this.get("stream").off("reconnected", this, this.refresh);

      window.removeEventListener("focus", this._refreshOnFocus);
    }
  });
});