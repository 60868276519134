define("frontend/components/reports/monthly-list/day", ["exports", "moment", "frontend/mixins/presence-statistics-calculation"], function (exports, _moment, _presenceStatisticsCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_presenceStatisticsCalculation.default, {
    init: function init() {
      this._super.apply(this, arguments);
    },


    tagName: "",
    clock: Ember.inject.service("slow-clock"),

    workDurationBalanceThisMonth: Ember.computed("monthlyPresenceReport", "clock.time", function () {
      var now = (0, _moment.default)(this.get("clock.time")).utc();
      var noTargetWork = -1;
      if (this.get("monthlyPresenceReport")) {
        return this.get("monthlyPresenceReport").filter(function (entry) {
          return _moment.default.utc(entry.date).isBefore(now, "day") && entry.targetWorkDuration !== noTargetWork;
        }).map(function (entry) {
          return entry.workDuration - entry.targetWorkDuration;
        }).reduce(function (value, sum) {
          return value + sum;
        }, 0);
      }
      return 0;
    }),

    hasOvertime: Ember.computed("workDurationBalanceThisMonth", function () {
      return this.get("workDurationBalanceThisMonth") > 0 ? true : false;
    })
  });
});