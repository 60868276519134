define("frontend/routes/employees/employee", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /*
     * Make sure we always have the most recent data when loading employee details
     *
     * Only use the `link-to` helper with ids to make this possible.
     */
    model: function model(params) {
      var promise = this.store.findRecord("employee", params.employee_id, {
        reload: true
      });
      promise.then(function (data) {
        // sort groups alphabetical
        data.set("groups", data.get("groups").sortBy("name"));
      });
      return promise;
    },
    activate: function activate() {
      var _this = this;

      this._refreshOnFocus = function () {
        _this.refresh();
        // Also reload data fetched by the controller, we need to explicity specify the controller name here
        _this.controllerFor("employees.employee.index").send("reload");
      };
      window.addEventListener("focus", this._refreshOnFocus);
    },
    deactivate: function deactivate() {
      window.removeEventListener("focus", this._refreshOnFocus);
    }
  });
});