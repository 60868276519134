define("frontend/components/employee/employments-list/item", ["exports", "moment", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "frontend/models/employment"], function (exports, _moment, _emberChangeset, _emberChangesetValidations, _validators, _employment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function validateWorkingDurations() {
    return function (key, newValue) {
      var workingDurations = newValue;
      if (!workingDurations) {
        return "Required";
      }
      if (workingDurations.length !== 7) {
        return "Invalid length";
      }
      if (workingDurations.any(function (val) {
        return val === null;
      })) {
        return "All values required";
      }
      if (workingDurations.any(function (val) {
        return Ember.typeOf(val) !== "number";
      })) {
        return "is not a number";
      }
      if (workingDurations.any(function (val) {
        return val < 0;
      })) {
        return "must not be negative";
      }
      if (!workingDurations.any(function (val) {
        return val > 0;
      })) {
        return "At least one greater zero";
      }
      return true;
    };
  }

  var EmploymentValidations = {
    validFrom: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    workingDurations: validateWorkingDurations(),
    workingTimeModel: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    vacationDays: [(0, _validators.validateNumber)({ integer: true, message: "must be an integer" }), (0, _validators.validateNumber)({ gte: 0, message: "must not be negative" })],
    locationCountry: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    locationState: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    carryOverWorkDurationHours: (0, _validators.validateNumber)({ message: "is not a number" })
  };

  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    classNames: ["item"],

    employment: null,
    newEmploymentActive: false,
    key: 0,
    // Bind editing for controlled editing from the list
    editing: false,
    "on-edit": NOOP,
    "on-save": NOOP,
    "on-cancel": NOOP,
    "on-delete": NOOP,

    logger: Ember.inject.service(),
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var employment = this.get("employment");
      this.changeset = new _emberChangeset.default(employment, (0, _emberChangesetValidations.default)(EmploymentValidations), EmploymentValidations);
    },


    isExpiredEmployment: Ember.computed("employment.validTo", function () {
      var validTo = this.get("employment.validTo");
      if (validTo) {
        return (0, _moment.default)(validTo).isBefore((0, _moment.default)());
      }
      return false;
    }),

    isNewEmploymentActive: Ember.computed("newEmploymentActive", function () {
      return !!this.get("newEmploymentActive");
    }),

    isActiveEmployment: Ember.computed("employment.{validFrom,validTo}", function () {
      var employment = this.get("employment");

      return (0, _moment.default)(employment.validFrom).isSameOrBefore((0, _moment.default)()) && (!employment.validTo || (0, _moment.default)(employment.validTo).isAfter((0, _moment.default)()));
    }),
    // Computed properties for validations + computations on changes
    workingDurations: Ember.computed.alias("changeset.workingDurations"),
    workingDurationHours: _employment.workingDurationHours,
    workingTimePerDay: _employment.workingTimePerDay,

    actions: {
      edit: function edit() {
        this.get("on-edit")();
      },
      save: function save() {
        var _this = this;

        var employment = this.get("employment");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              if (_this.get("on-save")) {
                _this.get("on-save")(employment);
              }
            }, function (error) {
              if (!employment.get("isValid")) {
                var modelErrors = employment.get("errors");
                modelErrors.forEach(function (modelError) {
                  changeset.pushErrors(modelError.attribute, modelError.message);
                });
                // Tricky part: reset (existing) model to prevent view of invalid changes, changeset keeps modifications for later save
                if (!employment.get("isNew")) {
                  employment.rollbackAttributes();
                }
              } else {
                _this.get("logger").catch(error, "Error saving employment", {
                  employementId: _this.get("employment.id")
                });
              }
            });
          }
        });
      },
      cancel: function cancel() {
        this.changeset.rollback();
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      },
      delete: function _delete() {
        var _this2 = this;

        var employment = this.get("employment");
        employment.destroyRecord().then(function () {
          _this2.get("notify").success("Anstellungsverhältnis gelöscht");
          if (_this2.get("on-delete")) {
            _this2.get("on-delete")(employment);
          }
        }, function (error) {
          _this2.get("logger").catch(error, "Error deleting employment", {
            employementId: _this2.get("employment.id")
          });
        });
      }
    }
  });
});