define("frontend/routes/reset-password", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    notify: Ember.inject.service(),
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get("auth.authenticated")) {
        this.get("notify").alert("Bitte Abmelden zum Zurücksetzen des Passworts");
        this.transitionTo("index");
      }
    },
    activate: function activate() {
      document.body.classList.add("public");
    },
    deactivate: function deactivate() {
      document.body.classList.remove("public");
    }
  });
});