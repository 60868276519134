define("frontend/components/employee/presence/interval-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    classNames: ["interval-form", "row"],

    "on-remove": NOOP,

    employee: null,
    presence: null,
    changeset: null,
    intervalChangesets: null,

    itemIndex: Ember.computed("intervalChangesets.length", "changeset", function () {
      return this.get("intervalChangesets").indexOf(this.get("changeset"));
    }),

    isLast: Ember.computed("itemIndex", "intervalChangesets.length", function () {
      return this.get("itemIndex") === this.get("intervalChangesets.length") - 1;
    }),

    isPendingInterval: Ember.computed.and("isLast", "employee.isPaused", "presence.isPending"),

    today: Ember.computed(function () {
      // Do not bind to a clock here
      return new Date();
    }),

    actions: {
      remove: function remove() {
        this.get("on-remove")();
      }
    }
  });
});