define("frontend/components/ui/changeset-global-errors", ["exports", "frontend/mixins/component/mixed-validation"], function (exports, _mixedValidation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui", "error", "message"],

    changeset: null,

    globalErrors: Ember.computed.alias("changeset.error." + _mixedValidation.GLOBAL_ERROR_ATTRIBUTE + ".validation")
  });
});