define("frontend/routes/employees/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord("employee");
    },
    afterModel: function afterModel() {
      var _this = this;

      return this.store.findAll("group").then(function (groups) {
        _this.set("availableGroups", groups);
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("availableGroups", this.get("availableGroups"));
    },
    deactivate: function deactivate() {
      if (this.currentModel.get("isNew")) {
        this.currentModel.destroyRecord();
      }
    }
  });
});