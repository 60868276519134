define("frontend/components/ui/confirmation-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    type: null,
    title: null,
    disabled: false,
    okLabel: "Löschen",
    "on-confirm": NOOP,
    buttonSize: "tiny",
    buttonLabel: "",

    tagName: "span",

    actions: {
      showConfirmation: function showConfirmation() {
        this.$(".ui.modal").modal("show");
      },
      confirm: function confirm() {
        this.get("on-confirm")();
      }
    }
  });
});