define("frontend/react/components/form/InputField", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var InputField = function InputField(_ref) {
    var _ref$type = _ref.type,
        type = _ref$type === undefined ? "text" : _ref$type,
        name = _ref.name,
        value = _ref.value,
        onUpdate = _ref.onUpdate,
        disabled = _ref.disabled,
        error = _ref.error,
        label = _ref.label,
        required = _ref.required,
        width = _ref.width,
        fieldProps = _objectWithoutProperties(_ref, ["type", "name", "value", "onUpdate", "disabled", "error", "label", "required", "width"]);

    var classes = ["field", "wide"];

    var handleUpdate = function handleUpdate(evt) {
      onUpdate(name, evt.target.value);
    };

    required && classes.push("required");
    width && classes.push(width);
    error && classes.push("error");

    return React.createElement(
      "div",
      { className: classes.join(" ") },
      React.createElement(
        "label",
        null,
        label
      ),
      React.createElement("input", _extends({
        type: type,
        name: name,
        disabled: disabled,
        value: value,
        onChange: handleUpdate,
        required: required
      }, fieldProps)),
      error && React.createElement(
        "div",
        { className: "ui pointing red label" },
        error
      )
    );
  };

  exports.default = InputField;
});