define("frontend/components/employee/presence-form", ["exports", "ember-changeset"], function (exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["presence-form"],

    logger: Ember.inject.service(),

    employee: null,
    presence: null,

    changeset: Ember.computed("employee.status", "presence", "day.date", function () {
      var changeset = new _emberChangeset.default(this.get("presence"));

      return changeset;
    }),

    intervalChangesets: Ember.computed("presence", "employee.status", "day.date", function () {
      var presence = this.get("presence");
      var pauseIntervals = presence.get("pauseIntervals");
      if (!pauseIntervals) {
        return [];
      }
      return pauseIntervals.map(function (interval) {
        return new _emberChangeset.default(interval);
      });
    }),

    actions: {
      save: function save() {
        var _this = this;

        var presence = this.get("presence");
        var changeset = this.get("changeset");

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            _this.get("intervalChangesets").forEach(function (intervalChangeset) {
              return intervalChangeset.execute();
            });
            changeset.save().then(function () {
              if (_this.get("on-success")) {
                _this.get("on-success")();
              }
            }, function (error) {
              // Remote validation error
              if (!presence.get("isValid")) {
                var modelErrors = presence.get("errors");
                modelErrors.forEach(function (_ref) {
                  var attribute = _ref.attribute,
                      message = _ref.message;

                  // Check for nested attribute errors and push errors to individual changesets
                  var match = attribute.match(/pauseIntervals\.([0-9]+)\.(.*)/);
                  if (match !== null) {
                    var intervalChangeset = _this.get("intervalChangesets").get(match[1]);
                    intervalChangeset.pushErrors(match[2], message);
                  } else {
                    changeset.pushErrors(attribute, message);
                  }
                });
              }
              // Other remote error
              else {
                  _this.get("logger").catch(error, "Error saving presence", {
                    presenceId: _this.get("presence.id")
                  });
                }
            });
          }
        });
      },
      addInterval: function addInterval() {
        var intervals = this.get("changeset").get("pauseIntervals"),
            checkin = this.get("changeset").get("checkin");
        var newInterval = {
          start: new Date(checkin.toISOString()),
          end: new Date(checkin.toISOString())
        };
        intervals.insertAt(0, newInterval);
        this.get("intervalChangesets").insertAt(0, new _emberChangeset.default(newInterval));
        this.get("changeset").set("pauseIntervals", intervals);
      },
      insertInterval: function insertInterval(intervalChangeset) {
        var intervals = this.get("changeset").get("pauseIntervals");
        var index = this.get("intervalChangesets").indexOf(intervalChangeset);
        if (!Ember.get(intervalChangeset, "end")) {
          Ember.set(intervalChangeset, "end", new Date(intervalChangeset.get("start").toISOString()));
        }
        var newIntervalEnd = new Date(Ember.get(intervalChangeset, "end").toISOString());
        var newInterval = {
          start: Ember.get(intervalChangeset, "end") ? new Date(Ember.get(intervalChangeset, "end").toISOString()) : new Date(Ember.get(intervalChangeset, "start").toISOString()),
          end: newIntervalEnd
        };

        intervals.insertAt(index + 1, newInterval);
        this.get("intervalChangesets").insertAt(index + 1, new _emberChangeset.default(newInterval));
        this.get("changeset").set("pauseIntervals", intervals);
      },
      removeInterval: function removeInterval(intervalChangeset) {
        var intervals = this.get("changeset").get("pauseIntervals"),
            index = this.get("intervalChangesets").indexOf(intervalChangeset);
        intervals.removeAt(index);
        this.get("intervalChangesets").removeAt(index);
        this.get("changeset").set("pauseIntervals", intervals);
      },
      close: function close() {
        this.get("changeset").rollback();
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      },
      setCheckin: function setCheckin(checkin) {
        var changeset = this.get("changeset");
        var checkout = changeset.get("checkout");
        // reset the checkout once, to trigger a change
        changeset.set("checkout", null);
        changeset.set("checkout", checkout);
        changeset.set("checkin", checkin);
      }
    }
  });
});