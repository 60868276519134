define("frontend/util/promise-polyfill", [], function () {
  "use strict";

  // Promise finally polyfill for IE / Edge
  Promise.prototype.finally = Promise.prototype.finally || {
    finally: function _finally(fn) {
      var onFinally = function onFinally(callback) {
        return Promise.resolve(fn()).then(callback);
      };
      return this.then(function (result) {
        return onFinally(function () {
          return result;
        });
      }, function (reason) {
        return onFinally(function () {
          return Promise.reject(reason);
        });
      });
    }
  }.finally;
});