define("frontend/util/promises", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reloadablePromiseObject = reloadablePromiseObject;


  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin, {
    reload: function reload() {
      // Create callback (promise creator) again and set new promise (should work)
      var callback = this.get("callback");
      var promise = callback();
      this.set("promise", promise);
    },

    // Promise proxy itself should _not_ be a promise anymore (otherwise model hook would resolve it completely without keeping the proxy)
    then: null
  });

  function reloadablePromiseObject(callback) {
    var promise = callback();

    var proxy = ObjectPromiseProxy.create({
      promise: promise,
      callback: callback
    });
    // Do a Promise masquerade to make sure initially the Promise was resolved when returning the proxy as a model
    return new Ember.RSVP.Promise(function (resolve, reject) {
      return promise.then(function () {
        return resolve(proxy);
      }, function () {
        return reject(proxy);
      });
    }, "ObjectPromiseProxy");
  }
});