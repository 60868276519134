define("frontend/components/status-icon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ICON_LIST = ["ban", "bed", "bell", "bicycle", "binoculars", "birthday-cake", "book", "calendar", "camera", "car ", "child", "clock-o", "coffee ", "comments", "cutlery", "desktop", "envelope", "exclamation-triangle", "file ", "flag", "flask", "gavel", "globe", "graduation-cap", "heart ", "home", "info-circle", "laptop", "medkit", "music ", "paint-brush", "phone ", "plane", "question-circle", "road ", "search", "shopping-cart", "spoon", "star ", "stethoscope", "suitcase ", "times-circle", "tree", "trophy", "truck", "users", "wrench"];

  // TODO Rename to form-input/status-icon
  exports.default = Ember.Component.extend({
    open: false,

    statusIcons: ICON_LIST,
    icon: null,

    actions: {
      toggle: function toggle() {
        this.toggleProperty("open");
      },
      set: function set(iconName) {
        this.get("action")(iconName);
        this.set("open", false);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$(document).on("click.status-icon", "button.status", function () {
        return false;
      }).on("click.status-icon", function () {
        _this.set("open", false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(document).off("click.status-icon");
    }
  });
});