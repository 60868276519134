define("frontend/controllers/dashboard/index", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    slowClock: Ember.inject.service("slow-clock"),

    user: Ember.computed.readOnly("auth.user"),
    // Needed by template
    employee: Ember.computed.readOnly("user.employee"),

    presenceDate: Ember.computed("model.summaryOnDay", "slowClock.time", function () {
      var aborted = this.get("didAbortPreviously");

      var abortedPresenceDate = this.get("lastWeekPresencesByDay");
      if (aborted) {
        for (var i = abortedPresenceDate.length; i--; i >= 0) {
          var presence = abortedPresenceDate[i];
          if (presence.aborted) {
            return {
              date: (0, _moment.default)(presence.date).toDate(),
              day: (0, _moment.default)(presence.date).format("D"),
              month: (0, _moment.default)(presence.date).format("M"),
              year: (0, _moment.default)(presence.date).format("YYYY")
            };
          }
        }
      }

      return this.get("thisDate");
    }),

    thisDate: Ember.computed("slowClock.time", function () {
      return {
        date: (0, _moment.default)(this.get("slowClock.time")).toDate(),
        day: (0, _moment.default)(this.get("slowClock.time")).format("D"),
        month: (0, _moment.default)(this.get("slowClock.time")).format("M"),
        year: (0, _moment.default)(this.get("slowClock.time")).format("YYYY")
      };
    }),

    lastWeekPresencesByDay: Ember.computed("model.presencesByDay.content", function () {
      var presencesByDay = this.get("model.presencesByDay.content");
      if (!Ember.isArray(presencesByDay)) {
        return [];
      }
      // TODO No slow clock here yet, we should see if we rather trigger a reload of presencesByDay on day-break
      var from = (0, _moment.default)().utc().startOf("day").subtract(7, "days"),
          to = (0, _moment.default)().utc().startOf("day").subtract(1, "day");
      return presencesByDay.filter(function (_ref) {
        var date = _ref.date;

        return _moment.default.utc(date).isBetween(from, to, "day", "[]");
      });
    }),

    monthlyPresenceReport: Ember.computed("model.presencesByDay.content", function () {
      var presencesByDay = this.get("model.presencesByDay.content");
      if (!Ember.isArray(presencesByDay)) {
        return [];
      }

      // TODO No slow clock here yet, we should see if we rather trigger a reload of presencesByDay on day-break
      var from = _moment.default.utc().startOf("month");
      // The filtering is not inclusive at the end, so we can use the dart of day here
      var to = _moment.default.utc().startOf("day");

      // On first of month we show the summary of the last day of the previous month
      if (_moment.default.utc().date() === 1) {
        from = from.subtract(1, "day");
      }

      return presencesByDay.filter(function (_ref2) {
        var date = _ref2.date;

        return _moment.default.utc(date).isBetween(from, to, "day", "[)");
      });
    }),

    lastThirtyPresencesByDay: Ember.computed("model.presencesByDay.content", function () {
      var presencesByDay = this.get("model.presencesByDay.content");
      if (!Ember.isArray(presencesByDay)) {
        return [];
      }
      // TODO No slow clock here yet, we should see if we rather trigger a reload of presencesByDay on day-break
      var from = (0, _moment.default)().utc().startOf("day").subtract(30, "days"),
          to = (0, _moment.default)().utc().startOf("day").subtract(1, "day");
      return presencesByDay.filter(function (_ref3) {
        var date = _ref3.date;

        return _moment.default.utc(date).isBetween(from, to, "day", "[]");
      });
    }),

    hasEditPermission: Ember.computed("user.permissions", function () {
      var permissions = this.get("user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      return permissions.indexOf("permissionSelfPresenceWrite") >= 0;
    }),

    // TODO This should go in the auth service / helper
    hasVacationEditPermission: Ember.computed("auth.user.permissions", function () {
      var permissions = this.get("user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      if (permissions.indexOf("permissionAllVacationWrite") >= 0) {
        return true;
      }
      if (this.get("employee.id") === this.get("user.employee.id")) {
        return this.get("user.employee.vacationSelfServiceLevel") === "all";
      }
      return false;
    }),

    trend7Days: Ember.computed("lastWeekPresencesByDay", function () {
      var lastWeekPresencesByDay = this.get("lastWeekPresencesByDay");

      if (lastWeekPresencesByDay.length > 0) {
        return lastWeekPresencesByDay.map(function (element) {
          return element.workDuration - element.targetWorkDuration;
        }).reduce(function (acc, curVal) {
          return acc + curVal;
        });
      }

      return null;
    }),

    didAbortPreviously: Ember.computed("lastWeekPresencesByDay,model.{summaryOnDay.aborted,summaryOnDay.checkin}", "presence", function () {
      var summary = this.get("model.summaryOnDay");
      // If the current summary is aborted, there was an abort
      if (summary.get("aborted")) {
        return true;
      }
      // If the current summary has a checkin, do not show an abort (even if there was an abort in the last 7 days)
      if (summary.get("checkin") !== null) {
        return false;
      }

      // Iterate in reverse through presences and check if a previous presence was aborted (if a checkin occured after an aborted presence it's okay)
      var presencesByDay = this.get("lastWeekPresencesByDay");
      for (var i = presencesByDay.length; i--; i >= 0) {
        var presence = presencesByDay[i];
        if (presence.aborted) {
          return true;
        }
        if (presence.checkin !== null) {
          return false;
        }
      }

      return false;
    }),

    presence: Ember.computed("employee.{presence,status,id}", "day.date", function () {
      var checkin = this.get("employee.presence.checkin");

      if (checkin) {
        return this.get("employee.presence");
      } else {
        return this.get("store").createRecord("presence", {
          employeeId: this.get("employee.id"),
          checkin: (0, _moment.default)(this.get("day.date")).set({
            hour: 9,
            minute: 0,
            second: 0,
            millisecond: 0
          }).toDate(),
          checkout: (0, _moment.default)(this.get("day.date")).set({
            hour: 18,
            minute: 0,
            second: 0,
            millisecond: 0
          }).toDate(),
          pauseIntervals: Ember.A([])
        });
      }
    }),

    actions: {
      summaryChanged: function summaryChanged(from, to) {
        var summaryOnDay = this.get("model.summaryOnDay");

        // If current summary is changed it will be reloaded
        if (summaryOnDay.inRange(from, to)) {
          summaryOnDay.reload();
        }

        var presencesByDay = this.get("model.presencesByDay.content");
        if (presencesByDay.length > 0) {
          // Get displayed range from presences that are displayed
          var presencesByDayFrom = _moment.default.utc(presencesByDay[0].date);
          var presencesByDayTo = _moment.default.utc(presencesByDay[presencesByDay.length - 1].date);

          // If the range of the changed summary is in the last 7 days, we neet to reload last week presences
          if (presencesByDayFrom.isSameOrBefore(to) && presencesByDayTo.isSameOrAfter(from)) {
            this.get("model.presencesByDay").reload();
          }
        }
      },
      vacationChanged: function vacationChanged(from) {
        var currentYear = (0, _moment.default)(this.get("slowClock.time")).get("year");

        // If vacation is in current year or before (because of vacation carry over), we need to reload the vacation report
        if (from.get("year") <= currentYear) {
          this.get("model.vacationReport").reload();
        }
      },
      toggleTutorialWeek: function toggleTutorialWeek() {
        document.querySelector(".week-tutorial").classList.toggle("no-display");
        document.getElementsByClassName("chart-overview")[0].classList.toggle("no-opacity");
      },
      toggleTutorialDay: function toggleTutorialDay() {
        document.querySelector(".day-tutorial").classList.toggle("no-display");
        document.getElementsByClassName("daily-overview")[0].classList.toggle("no-display");
      },
      showPresenceModal: function showPresenceModal() {
        Ember.$(".ui.daily-presence-modal.modal").modal("show");
      },
      onCancelUpdate: function onCancelUpdate() {
        Ember.$(".ui.daily-presence-modal.modal").modal("hide");
      },
      onSuccessUpdate: function onSuccessUpdate() {
        Ember.$(".ui.daily-presence-modal.modal").modal("hide");
      }
    }
  });
});