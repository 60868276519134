define("frontend/react/hooks/useApiClient", ["exports", "react", "frontend/config/environment", "frontend/react/hooks/useAuth", "frontend/react/api/client"], function (exports, _react, _environment, _useAuth, _client) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var useApiClient = function useApiClient() {
    var auth = (0, _useAuth.default)();
    var client = (0, _react.useMemo)(function () {
      return (0, _client.default)({
        apiUrlPrefix: _environment.default.apiUrlPrefix,
        authToken: auth.token,
        clientId: auth.clientId
      });
    }, [_environment.default.apiUrlPrefix, auth.token, auth.clientId]);

    return client;
  };

  exports.default = useApiClient;
});