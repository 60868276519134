define("frontend/models/plan", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    employeeCount: _emberData.default.attr("string"),
    monthlyPriceCents: _emberData.default.attr("number"),

    monthlyPrice: Ember.computed("monthlyPriceCents", function () {
      return this.get("monthlyPriceCents") / 100;
    })
  });
});