define("frontend/react/components/accounting/Payment", ["exports", "frontend/react/components/accounting/payment/PaymentData", "react", "frontend/react/components/accounting/payment/PaymentFormContainer"], function (exports, _PaymentData, _react, _PaymentFormContainer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Payment = function Payment(_ref) {
    var customer = _ref.customer,
        payment = _ref.payment,
        onUpdated = _ref.onUpdated;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        showForm = _useState2[0],
        setShowForm = _useState2[1];

    var handleShowForm = function handleShowForm() {
      return setShowForm(true);
    };
    var handleHideForm = function handleHideForm() {
      return setShowForm(false);
    };

    var handleUpdated = function handleUpdated(payment) {
      onUpdated(payment);
      handleHideForm();
    };

    if (showForm) {
      return React.createElement(_PaymentFormContainer.default, {
        customer: customer,
        payment: payment,
        onCancel: handleHideForm,
        onUpdated: function onUpdated(payment) {
          return handleUpdated(payment);
        }
      });
    }

    return React.createElement(_PaymentData.default, { payment: payment, onShowForm: handleShowForm });
  };

  exports.default = Payment;
});