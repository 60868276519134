define("frontend/components/ui/form-group", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["field"],
    classNameBindings: ["hasError:error"],

    hasError: Ember.computed("errors.length", function () {
      return this.get("errors.length") > 0;
    })
  });
});