define("frontend/util/computed", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseIntWithDefault = parseIntWithDefault;
  exports.monthWithDefault = monthWithDefault;
  exports.yearWithDefault = yearWithDefault;
  function parseIntWithDefault(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    return Ember.computed(key, {
      get: function get() {
        if (this.get(key)) {
          var v = parseInt(this.get(key));
          if (isNaN(v)) {
            v = defaultValue;
          }
          return v;
        } else {
          return defaultValue;
        }
      },
      set: function set(key, value) {
        this.set(key, value);
        return value;
      }
    });
  }

  function monthWithDefault(monthKey) {
    var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    return Ember.computed(monthKey, {
      get: function get() {
        if (this.get(monthKey)) {
          return parseInt(this.get(monthKey));
        } else {
          // Do not bind to a clock here
          var d = (0, _moment.default)(),
              m = calculateMonthYearByOffset(d, offset).month;
          return m;
        }
      },
      set: function set(key, value) {
        this.set(monthKey, value);
        return value;
      }
    });
  }

  function yearWithDefault(yearKey) {
    var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    return Ember.computed(yearKey, {
      get: function get() {
        if (this.get(yearKey)) {
          return parseInt(this.get(yearKey));
        } else {
          // Do not bind to a clock here
          var d = (0, _moment.default)(),

          // Current year
          y = calculateMonthYearByOffset(d, offset).year;
          return y;
        }
      },
      set: function set(key, value) {
        this.set(yearKey, value);
        return value;
      }
    });
  }

  // Calculates the Year and the Month, if you add (+offset)/sub (-offset) offset months
  function calculateMonthYearByOffset(d, offset) {
    var date = (0, _moment.default)(d);
    var newDate = date.add(offset, "months");
    return { month: newDate.month() + 1, year: newDate.year() };
  }
});