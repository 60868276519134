define("frontend/components/dashboard/time-overview", ["exports", "frontend/mixins/presence-statistics-calculation"], function (exports, _presenceStatisticsCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOT_EXISTENT = 0;

  exports.default = Ember.Component.extend(_presenceStatisticsCalculation.default, {
    classNames: ["ui", "three", "column", "divided", "time-overview", "grid"],

    monthlyPresenceReport: null,

    blankPresenceColor: Ember.computed("blankPresences", function () {
      return this.get("blankPresences") > NOT_EXISTENT ? "red" : "grey";
    }),

    abortedPresenceColor: Ember.computed("abortedPresences", function () {
      return this.get("abortedPresences") > NOT_EXISTENT ? "red" : "grey";
    }),

    pauseAbnormalColor: Ember.computed("pauseAbnormalCount", function () {
      return this.get("pauseAbnormalCount") > NOT_EXISTENT ? "red" : "grey";
    }),

    workDurationAbnormalColor: Ember.computed("workDurationAbnormalCount", function () {
      return this.get("workDurationAbnormalCount") > NOT_EXISTENT ? "red" : "grey";
    })
  });
});