define("frontend/components/employee/presence-day-statistics", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["ui", "small", "statistics"],

    aggregation: null,
    pendingWorkDuration: null,

    workDuration: Ember.computed("aggregation.workDuration", "pendingWorkDuration", function () {
      return this.getWithDefault("aggregation.workDuration", 0) + this.get("pendingWorkDuration");
    }),

    targetWorkDuration: Ember.computed("aggregation.targetWorkDuration", function () {
      return this.get("aggregation.targetWorkDuration");
    }),

    provisionallyCalculatedBalance: Ember.computed("aggregation.date", function () {
      return {
        future: (0, _moment.default)(this.get("aggregation.date")).isAfter((0, _moment.default)(), "day"),
        present: (0, _moment.default)(this.get("aggregation.date")).isSame((0, _moment.default)(), "day")
      };
    }),

    workDurationBalance: Ember.computed("aggregation.workDurationBalance", "pendingWorkDuration", function () {
      return this.getWithDefault("aggregation.workDurationBalance", 0) + this.get("pendingWorkDuration");
    })
  });
});