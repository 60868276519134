define("frontend/components/reports/monthly-list", ["exports", "moment", "ember-data", "frontend/util/timecalculations"], function (exports, _moment, _emberData, _timecalculations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    logger: Ember.inject.service(),
    ajax: Ember.inject.service(),

    classNames: ["reports-monthly-list"],

    employees: null,

    // Should be bound to controller for query param support
    selectedMonth: null,
    selectedYear: null,

    clock: Ember.inject.service("slow-clock"),

    loading: Ember.computed.readOnly("employeesAndReport.isPending"),

    employeesAndReport: Ember.computed("fromDate", "toDate", function () {
      var _this = this;

      var firstDayToShow = this.get("fromDate").format("YYYY-MM-DD");
      var lastDayToShow = this.get("toDate").format("YYYY-MM-DD");

      var promise = this.get("ajax").request("/api/presences/report?from=" + firstDayToShow + "&to=" + lastDayToShow);
      promise.catch(function (error) {
        _this.get("logger").catch(error, "Error getting report for employees");
      });

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    fromDate: Ember.computed("selectedMonth", "selectedYear", function () {
      var fromDate = (0, _moment.default)([this.get("selectedYear"), this.get("selectedMonth") - 1, 1]);
      return fromDate;
    }),

    toDate: Ember.computed("clock.time", "fromDate", function () {
      var yesterday = (0, _moment.default)(this.get("clock.time")).subtract(1, "days").endOf("day");
      // Limit end of month by current day - 1 (a.k.a yesterday)
      var toDate = _moment.default.min((0, _moment.default)(this.get("fromDate")).endOf("month"), yesterday);
      return toDate;
    }),

    reportByEmployeeId: Ember.computed("employees", "employeesAndReport.content", "clock.time", function () {
      var employeesAndReport = this.get("employeesAndReport.content");
      var employees = this.get("employees");
      var result = {};
      var reportByEmployeeId = {};
      if (!Ember.isArray(employeesAndReport)) {
        return result;
      }

      employeesAndReport.forEach(function (employeeAndReport) {
        reportByEmployeeId[employeeAndReport.employeeId] = employeeAndReport.report;
      });

      employees.forEach(function (employee) {
        result[employee.id] = reportByEmployeeId[employee.id];
      });

      return result;
    }),

    months: Ember.computed("selectedMonth", "selectedYear", "clock.time", function () {
      var now = new Date(this.get("clock.time")),
          y = now.getFullYear(),
          m = now.getMonth() + 1;
      var months = [];

      for (var i = 0; i < 12; i++) {
        var monthYear = { month: m, year: y };
        months.push(monthYear);

        var _timecalc$previousMon = _timecalculations.default.previousMonth(y, m);

        var _timecalc$previousMon2 = _slicedToArray(_timecalc$previousMon, 2);

        y = _timecalc$previousMon2[0];
        m = _timecalc$previousMon2[1];
      }

      return months;
    }),

    selectedMonthYear: Ember.computed("months", "selectedMonth", "selectedYear", function () {
      var months = this.get("months"),
          selectedMonth = this.get("selectedMonth"),
          selectedYear = this.get("selectedYear");
      return months.find(function (_ref) {
        var month = _ref.month,
            year = _ref.year;
        return month === selectedMonth && year === selectedYear;
      });
    }),

    nextEnabled: Ember.computed("fromDate", "clock.time", function () {
      var fromNextMonth = (0, _moment.default)(this.get("fromDate")).add(1, "month");
      var now = (0, _moment.default)(this.get("clock.time"));
      return fromNextMonth.isBefore(now);
    }),

    yesterday: Ember.computed("clock.time", function () {
      return (0, _moment.default)(this.get("clock.time")).subtract(1, "days").format("DD.MM.YYYY");
    }),

    actions: {
      previousMonth: function previousMonth() {
        var _timecalc$previousMon3 = _timecalculations.default.previousMonth(this.get("selectedYear"), this.get("selectedMonth")),
            _timecalc$previousMon4 = _slicedToArray(_timecalc$previousMon3, 2),
            y = _timecalc$previousMon4[0],
            m = _timecalc$previousMon4[1];

        this.setProperties({
          selectedYear: y,
          selectedMonth: m
        });
      },
      nextMonth: function nextMonth() {
        var _timecalc$nextMonth = _timecalculations.default.nextMonth(this.get("selectedYear"), this.get("selectedMonth")),
            _timecalc$nextMonth2 = _slicedToArray(_timecalc$nextMonth, 2),
            y = _timecalc$nextMonth2[0],
            m = _timecalc$nextMonth2[1];

        this.setProperties({
          selectedYear: y,
          selectedMonth: m
        });
      },
      selectMonthYear: function selectMonthYear(monthYear) {
        this.setProperties({
          selectedYear: monthYear.year,
          selectedMonth: monthYear.month
        });
      }
    }
  });
});