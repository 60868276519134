define("frontend/components/employee/calendar-monthly", ["exports", "moment", "frontend/util/timecalculations"], function (exports, _moment, _timecalculations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    classNames: ["calendar-monthly"],

    employee: null,
    presencesByDay: null,

    // Must be bound read-only to controller for query param support
    selectedMonth: null,
    selectedYear: null,
    selectedDay: null,

    // Compute selected week index from selected calendar entry
    selectedWeek: Ember.computed.readOnly("calendarEntry.weekIndex"),

    loading: Ember.computed.readOnly("presencesByDay.isPending"),

    months: Ember.computed("selectedMonth", "selectedYear", function () {
      // Do not use a clock here to prevent issues of re-rendering components while editing presences
      var now = new Date(),
          currentYear = now.getFullYear();
      var months = [],
          m = this.get("selectedMonth"),
          y = this.get("selectedYear");

      for (var i = 0; i < 6; i++) {
        var _timecalc$nextMonth = _timecalculations.default.nextMonth(y, m),
            _timecalc$nextMonth2 = _slicedToArray(_timecalc$nextMonth, 2),
            nextY = _timecalc$nextMonth2[0],
            nextM = _timecalc$nextMonth2[1];

        if (nextY > currentYear + 1) {
          break;
        }
        y = nextY;
        m = nextM;
      }

      for (var _i = 0; _i < 12; _i++) {
        months.push({ month: m, year: y });

        var _timecalc$previousMon = _timecalculations.default.previousMonth(y, m);

        var _timecalc$previousMon2 = _slicedToArray(_timecalc$previousMon, 2);

        y = _timecalc$previousMon2[0];
        m = _timecalc$previousMon2[1];
      }
      return months;
    }),

    selectedMonthYear: Ember.computed("months", "selectedMonth", "selectedYear", function () {
      var months = this.get("months"),
          selectedMonth = this.get("selectedMonth"),
          selectedYear = this.get("selectedYear");
      return months.find(function (_ref) {
        var month = _ref.month,
            year = _ref.year;
        return month === selectedMonth && year === selectedYear;
      });
    }),

    weeks: Ember.computed("selectedMonth", "selectedYear", function () {
      // Go to first monday at/before first day of month
      var y = this.get("selectedYear"),
          m = this.get("selectedMonth"),

      // Do not use a clock here to prevent issues of re-rendering components while editing presences
      today = (0, _moment.default)().utc(),
          startOfMonth = (0, _moment.default)().utc().year(y).month(m - 1).startOf("month");

      var endOfMonth = (0, _moment.default)(startOfMonth).add(1, "months");

      var w = (0, _moment.default)(startOfMonth).subtract((startOfMonth.day() + 6) % 7, "days");
      var weeks = [],
          i = 0;
      while (w.isBefore(endOfMonth)) {
        var days = [];
        for (i = 0; i < 7; i++) {
          days.push({
            date: (0, _moment.default)(w).toDate(),
            day: w.date(),
            month: w.month() + 1,
            weekIndex: weeks.length,
            inactive: w.year() !== y || w.month() !== m - 1,
            today: w.isSame(today, "day")
          });
          w.add(1, "days");
        }
        weeks.push({
          days: days
        });
      }
      return weeks;
    }),

    // Get the currently selected calendar entry (if a day is selected)
    calendarEntry: Ember.computed("selectedDay", "selectedMonth", "weeks", function () {
      var selectedDay = this.get("selectedDay");
      var selectedMonth = this.get("selectedMonth");
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get("weeks")[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref2 = _step.value;
          var days = _ref2.days;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = days[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var calendarEntry = _step2.value;

              if (calendarEntry.day === selectedDay && calendarEntry.month === selectedMonth) {
                return calendarEntry;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return null;
    }),

    nextEnabled: Ember.computed("selectedMonth", "selectedYear", function () {
      // Do not use a clock here to prevent issues of re-rendering components while editing presences
      var now = new Date(),
          currentYear = now.getFullYear();

      var _timecalc$nextMonth3 = _timecalculations.default.nextMonth(this.get("selectedYear"), this.get("selectedMonth")),
          _timecalc$nextMonth4 = _slicedToArray(_timecalc$nextMonth3, 1),
          yNext = _timecalc$nextMonth4[0];

      return yNext <= currentYear + 1;
    }),

    actions: {
      previousMonth: function previousMonth() {
        var _timecalc$previousMon3 = _timecalculations.default.previousMonth(this.get("selectedYear"), this.get("selectedMonth")),
            _timecalc$previousMon4 = _slicedToArray(_timecalc$previousMon3, 2),
            y = _timecalc$previousMon4[0],
            m = _timecalc$previousMon4[1];

        this.get("on-select")({
          year: y,
          month: m,
          day: null
        });
      },
      nextMonth: function nextMonth() {
        var _timecalc$nextMonth5 = _timecalculations.default.nextMonth(this.get("selectedYear"), this.get("selectedMonth")),
            _timecalc$nextMonth6 = _slicedToArray(_timecalc$nextMonth5, 2),
            y = _timecalc$nextMonth6[0],
            m = _timecalc$nextMonth6[1];

        this.get("on-select")({
          year: y,
          month: m,
          day: null
        });
      },
      selectMonthYear: function selectMonthYear(_ref3) {
        var year = _ref3.year,
            month = _ref3.month;

        this.get("on-select")({
          year: year,
          month: month,
          day: null
        });
      },
      selectDate: function selectDate(date) {
        if (this.get("calendarEntry.date") === date) {
          // Select again means unselect day
          this.get("on-select")({
            year: this.get("selectedYear"),
            month: this.get("selectedMonth"),
            day: null
          });
        } else {
          var select = (0, _moment.default)(date);
          this.get("on-select")({
            year: select.year(),
            month: select.month() + 1,
            day: select.date()
          });
        }
      },
      reload: function reload() {
        this.get("on-reload-data")();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$(document.body).on("click.calendar-monthly", ".calendar-monthly, .date-picker, .ui.dimmer", function (event) {
        event.stopPropagation();
      }).on("click.calendar-monthly", function () {
        if (!(_this.get("isDestroyed") || _this.get("isDestroying"))) {
          _this.get("on-select")({
            year: _this.get("selectedYear"),
            month: _this.get("selectedMonth"),
            day: null
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(document.body).off("click.calendar-monthly");
    }
  });
});