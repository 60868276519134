define("frontend/mixins/serializer/extract-errors", ["exports", "frontend/mixins/component/mixed-validation"], function (exports, _mixedValidation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertFormErrors = convertFormErrors;


  function addError(errors, fieldName, fieldError) {
    if (!errors[fieldName]) {
      errors[fieldName] = [];
    }
    errors[fieldName].push(fieldError.message);
  }

  function convertFormErrors(formErrors) {
    var errors = {};
    Ember.A(formErrors).forEach(function (fieldError) {
      if (Ember.isArray(fieldError.fieldNames) && fieldError.fieldNames.length > 0) {
        Ember.A(fieldError.fieldNames).forEach(function (fieldName) {
          addError(errors, fieldName, fieldError);
        });
      } else {
        addError(errors, _mixedValidation.GLOBAL_ERROR_ATTRIBUTE, fieldError);
      }
    });
    return errors;
  }

  exports.default = Ember.Mixin.create({
    extractErrors: function extractErrors(store, typeClass, payload) {
      if (payload && payload.errors) {
        var converted = convertFormErrors(payload.errors);
        return converted;
      }
      return payload;
    }
  });
});