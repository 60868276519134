define("frontend/models/summary", ["exports", "ember-data", "moment"], function (exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Summary = _emberData.default.Model.extend({
    date: _emberData.default.attr("date"),
    employeeId: _emberData.default.attr("string"),
    status: _emberData.default.attr("string"),
    checkin: _emberData.default.attr("date"),
    checkout: _emberData.default.attr("date"),
    workDuration: _emberData.default.attr("number"),
    targetWorkDuration: _emberData.default.attr("number"),
    pauseDuration: _emberData.default.attr("number"),
    intervals: _emberData.default.attr("intervals"),
    aborted: _emberData.default.attr("boolean", { defaultValue: false }),
    vacation: _emberData.default.belongsTo("vacation", { async: false }),
    holiday: _emberData.default.attr("object"),
    referenceDate: _emberData.default.attr("date"),

    // Client date will be injected after fetch from serializer
    clientDate: _emberData.default.attr("date"),

    inRange: function inRange(from, to) {
      var currentDate = (0, _moment.default)(this.get("date")).utc();
      return currentDate.isBetween(from, to, "day", "[]");
    }
  });

  exports.default = Summary;
});