define("frontend/serializers/summary", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      // Do not serialize embedded vacation
      vacation: { serialize: false, deserialize: "records" }
    },

    normalize: function normalize(modelClass, hash) {
      // Store client date for comparison to referenceDeta for localTimeOffset (pending presence calculations)
      hash.clientDate = new Date().toJSON();
      return this._super.apply(this, arguments);
    }
  });
});