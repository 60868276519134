define("frontend/components/employee/vacation-history/button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    vacation: null,

    versionCount: Ember.computed("vacation.history", function () {
      var history = this.get("vacation.history");
      // There's one version less than the history length
      return history && history.length - 1;
    }),

    actions: {
      buttonClick: function buttonClick(name) {
        this.$('.ui.' + name + '.modal').modal("show");
      }
    }
  });
});