define("frontend/models/employee", ["exports", "ember-data", "frontend/util/error-status"], function (exports, _emberData, _errorStatus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ErrorNotPaused = exports.ErrorNotCheckedInOrAlreadyPaused = exports.ErrorAlreadyCheckedOut = exports.ErrorAlreadyCheckedIn = exports.ActionUnpause = exports.ActionPause = exports.ActionCheckout = exports.ActionCheckin = undefined;
  exports.isAccessForeignCalendarsGrantedByRole = isAccessForeignCalendarsGrantedByRole;
  exports.isVacationSelfServiceGrantedByRole = isVacationSelfServiceGrantedByRole;
  var ActionCheckin = exports.ActionCheckin = "checkin";
  var ActionCheckout = exports.ActionCheckout = "checkout";
  var ActionPause = exports.ActionPause = "pause";
  var ActionUnpause = exports.ActionUnpause = "unpause";

  var ErrorAlreadyCheckedIn = exports.ErrorAlreadyCheckedIn = "Already checked in";
  var ErrorAlreadyCheckedOut = exports.ErrorAlreadyCheckedOut = "State must be present and not paused";
  var ErrorNotCheckedInOrAlreadyPaused = exports.ErrorNotCheckedInOrAlreadyPaused = "Not checked in or already paused";
  var ErrorNotPaused = exports.ErrorNotPaused = "Not paused";

  function presenceActionFactory(action) {
    return function () {
      var _this = this;

      return this.get("ajax").post("/api/employee/" + this.get("id") + "/" + action).then(function (data) {
        _this.store.pushPayload("employee", data);
      }, function (error) {
        var reason = void 0;
        if ((0, _errorStatus.default)(error) === 401) {
          reason = Ember.get(error, "payload.error");
        } else {
          reason = error;
        }
        return Ember.RSVP.reject(reason);
      });
    };
  }

  var Employee = _emberData.default.Model.extend({
    status: _emberData.default.attr("string"),
    firstname: _emberData.default.attr("string"),
    lastname: _emberData.default.attr("string"),
    statusInterval: _emberData.default.attr("object"),
    presence: _emberData.default.belongsTo("presence", { async: false }),
    groups: _emberData.default.hasMany("group", { inverse: null, async: true }),
    imageIds: _emberData.default.attr("object"),
    // For uploading a new image
    image: _emberData.default.attr("file"),
    employments: _emberData.default.hasMany("employment", { inverse: null, async: false }),

    access: _emberData.default.attr("boolean"),
    email: _emberData.default.attr("string", { defaultValue: "" }),
    role: _emberData.default.attr("string", { defaultValue: "" }),
    password: _emberData.default.attr("string", { defaultValue: "" }),
    accessForeignCalendars: _emberData.default.attr("string", { defaultValue: "" }),
    vacationSelfServiceLevel: _emberData.default.attr("string", { defaultValue: "" }),

    created: _emberData.default.attr("date"),
    deleted: _emberData.default.attr("date"),
    restored: _emberData.default.attr("date"),

    ajax: Ember.inject.service(),

    isAbsent: Ember.computed.equal("status", "absent"),
    isPresent: Ember.computed.equal("status", "present"),
    isPaused: Ember.computed.equal("status", "paused"),

    hasImage: Ember.computed("imageIds", function () {
      var imageIds = this.get("imageIds"),
          key;
      for (key in imageIds) {
        if (imageIds.hasOwnProperty(key)) {
          return true;
        }
      }
      return false;
    }),

    accessForeignCalendarsWithDefault: Ember.computed("accessForeignCalendars", {
      get: function get() {
        return this.get("accessForeignCalendars") || "personal";
      },
      set: function set(key, value) {
        if (value == "personal") {
          this.set("accessForeignCalendars", "");
        } else {
          this.set("accessForeignCalendars", value);
        }
        return value;
      }
    }),

    vacationSelfServiceLevelWithDefault: Ember.computed("vacationSelfServiceLevel", {
      get: function get() {
        return this.get("vacationSelfServiceLevel") || "none";
      },
      set: function set(key, value) {
        if (value == "none") {
          this.set("vacationSelfServiceLevel", "");
        } else {
          this.set("vacationSelfServiceLevel", value);
        }
        return value;
      }
    }),

    sortedGroups: Ember.computed("groups", function () {
      return this.get("groups").sortBy("name");
    }),

    checkin: presenceActionFactory(ActionCheckin),
    checkout: presenceActionFactory(ActionCheckout),
    pause: presenceActionFactory(ActionPause),
    unpause: presenceActionFactory(ActionUnpause),

    setstatus: function setstatus(statusDefinitionId) {
      var _this2 = this;

      return this.get("ajax").put("/api/employee/" + this.get("id") + "/status", {
        data: { statusdefinition_id: statusDefinitionId }
      }).then(function (data) {
        _this2.store.pushPayload("employee", data);
      });
    },


    hasAccessEnabled: Ember.computed(function () {
      var changedAttributes = this.changedAttributes();
      if (changedAttributes.access) {
        return changedAttributes.access[1];
      }
      return false;
    }).volatile()
  });

  function isAccessForeignCalendarsGrantedByRole(role) {
    return role === "personnelManagement" || role === "administration";
  }

  function isVacationSelfServiceGrantedByRole(role) {
    return role === "personnelManagement" || role === "administration";
  }

  exports.default = Employee;
});