define("frontend/components/dashboard/daily-overview/pause-bar", ["exports", "frontend/util/dashboard/timeconversions", "frontend/util/timewatcher"], function (exports, _timeconversions, _timewatcher) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var threeHoursInSeconds = 10800; // 10800 Seconds represents a full pause-circle
  var secondStrokeOffset = 180;
  var dashOffsetForThirtyMin = 32;
  var dashOffsetForFourtyMin = -3;

  exports.default = Ember.Component.extend({
    tagName: "",

    employee: null,
    totalWorkDuration: null,
    totalPauseDuration: null,
    pendingTargetPauseDuration: null,
    hasVacation: null,

    /* PAUSE STYLE */
    pauseWidth: null,
    pcxy: null,
    pauseRadius: null,
    lengthOfDash: null,
    dashArray: null,
    dashOffset: null,

    targetPauseReached: Ember.computed("totalPauseDuration", "pendingTargetPauseDuration", function () {
      return (0, _timewatcher.targetTimeReached)(this.get("pendingTargetPauseDuration"), this.get("totalPauseDuration"));
    }),

    pauseDash: Ember.computed("totalPauseDuration", "pendingTargetPauseDuration", "lengthOfDash", "dashArray", function () {
      var pauseDuration = this.get("totalPauseDuration") || 0;
      var pendingTargetPauseDuration = this.get("pendingTargetPauseDuration");

      if (pendingTargetPauseDuration > 0 && pauseDuration >= pendingTargetPauseDuration) {
        return (0, _timeconversions.convertTimeToDash)(pendingTargetPauseDuration, this.get("lengthOfDash"), this.get("dashArray"));
      }

      return (0, _timeconversions.convertTimeToDash)(pauseDuration, this.get("lengthOfDash"), this.get("dashArray"));
    }),

    pauseDash2: Ember.computed("totalPauseDuration", "pendingTargetPauseDuration", "targetPauseReached", "lengthOfDash", "dashArray", function () {
      var targetPauseDuration = this.get("pendingTargetPauseDuration") || 0;
      var pauseDuration = this.get("totalPauseDuration") - targetPauseDuration;
      var fullPauseCircle = threeHoursInSeconds - targetPauseDuration;

      if (this.get("targetPauseReached") && pauseDuration >= fullPauseCircle) {
        return (0, _timeconversions.convertTimeToDash)(fullPauseCircle - secondStrokeOffset, this.get("lengthOfDash"), this.get("dashArray"));
      } else if (this.get("targetPauseReached") && pauseDuration < fullPauseCircle) {
        return (0, _timeconversions.convertTimeToDash)(pauseDuration, this.get("lengthOfDash"), this.get("dashArray"));
      }

      return "0 " + this.get("dashArray");
    }),

    pauseBarStyle: Ember.computed("pauseWidth", "dashOffset", function () {
      return Ember.String.htmlSafe("stroke-width:" + this.get("pauseWidth") + "px; stroke-dashoffset:" + this.get("dashOffset") + ";");
    }),

    pauseBarStyle2: Ember.computed("targetPauseReached", "pendingTargetPauseDuration", "dashOffset", "pauseWidth", function () {
      if (!this.get("targetPauseReached")) {
        return Ember.String.htmlSafe("display: none;");
      } else if (this.get("pendingTargetPauseDuration") <= 0) {
        return Ember.String.htmlSafe("stroke-width: " + this.get("pauseWidth") + "px; stroke-dashoffset: " + this.get("dashOffset") + ";");
      }

      return Ember.String.htmlSafe("stroke-width: " + this.get("pauseWidth") + "px; stroke-dashoffset: " + ((0, _timewatcher.pauseOver45)(this.get("pendingTargetPauseDuration")) ? dashOffsetForFourtyMin : dashOffsetForThirtyMin) + ";");
    }),

    pauseStrokeColor: Ember.computed("employee.{status,presence.isCheckinToday}", "totalPauseDuration", "totalWorkDuration", function () {
      var workDuration = this.get("totalWorkDuration");
      var pauseDuration = this.get("totalPauseDuration");
      var status = this.get("employee.status");

      if (!(0, _timewatcher.enoughPause)(pauseDuration, workDuration) && !this.get("hasVacation")) {
        return "red";
      } else if (status === "absent" && !this.get("employee.presence.isCheckinToday")) {
        return "no-visibility";
      } else if (status !== "paused") {
        return "grey";
      } else {
        return "yellow";
      }
    }),

    actions: {
      showTooltip: function showTooltip(ctx) {
        var data = {
          presence: this.get("employee.presence"),
          targetPause: this.get("pendingTargetPauseDuration"),
          totalPause: this.get("totalPauseDuration"),
          context: "pause-bar"
        };
        this.showTooltip(ctx, data);
      },
      closeTooltip: function closeTooltip() {
        this.closeTooltip();
      }
    }
  });
});