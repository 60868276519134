define("frontend/react/context/NotifyContext", ["exports", "react"], function (exports, _react) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.NotifyContextProvider = undefined;


  var NotifyContext = (0, _react.createContext)({});

  var NotifyContextProvider = exports.NotifyContextProvider = NotifyContext.Provider;

  exports.default = NotifyContext;
});