define("frontend/serializers/employee", ["exports", "ember-data", "frontend/mixins/serializer/extract-errors"], function (exports, _emberData, _extractErrors) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _extractErrors.default, {
    attrs: {
      // Do not serialize embedded presence
      presence: { serialize: false, deserialize: "records" },
      // Do not serialize status
      status: { serialize: false },
      // Do not serialize imageIds
      imageIds: { serialize: false },
      // Use different key
      groups: { deserializeKey: "groupIds", serializeKey: "group_id[]" },
      // Do not serialize embedded presence
      employments: { serialize: false, deserialize: "records" },
      accessForeignCalendars: {
        deserializeKey: "accessForeignCalendars",
        serializeKey: "access_foreign_calendars"
      },
      vacationSelfServiceLevel: {
        deserializeKey: "vacationSelfServiceLevel",
        serializeKey: "vacation_self_service_level"
      }
    },

    _serializing: false,

    serialize: function serialize(snapshot, options) {
      this._serializing = true;
      var json = this._super(snapshot, options);
      this._serializing = false;
      return json;
    },

    keyForAttribute: function keyForAttribute(key) {
      return this._keyFor(key);
    },

    keyForRelationship: function keyForRelationship(key) {
      return this._keyFor(key);
    },

    /* eslint-disable ember/no-attrs-in-components */
    _keyFor: function _keyFor(key) {
      if (this.attrs && this.attrs[key]) {
        if (this._serializing && this.attrs[key].serializeKey) {
          return this.attrs[key].serializeKey;
        }
        if (!this._serializing && this.attrs[key].deserializeKey) {
          return this.attrs[key].deserializeKey;
        }
      }
      return key;
    },

    // Do not use a root property when sending data to the server
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      var data = this.serialize(snapshot, options),
          p;
      for (p in hash) {
        delete hash[p];
      }
      for (p in data) {
        hash[p] = data[p];
      }
    },

    normalize: function normalize(modelClass, hash) {
      if (hash && hash.presence) {
        hash.presence.employeeId = hash.id;
      }
      if (hash) {
        hash.password = "";
      }
      return this._super.apply(this, arguments);
    }
  });
});