define("frontend/services/payment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    auth: Ember.inject.service(),
    ajax: Ember.inject.service(),

    subscription: Ember.computed.readOnly("auth.user.organisation.subscription"),
    // Has subscription in the meaning of "do employee creation / restore update the subscription"
    hasSubscription: Ember.computed("subscription", function () {
      var subscription = this.get("subscription");
      // Treat a subscription canceled at period end (which can be uncanceled) as no subscription
      return subscription && subscription.status !== "canceled" && !subscription.cancelAtPeriodEnd;
    }),

    previewChangeEmployee: function previewChangeEmployee(employeeCountDelta) {
      var currentEmployeeCount = this.get("subscription.employeeCount");
      var newEmployeeCount = currentEmployeeCount + employeeCountDelta;
      return this.get("ajax").request("/api/organisation/" + this.get("auth.user.organisation.id") + "/subscription/preview?employee_count=" + newEmployeeCount).then(function (_ref) {
        var invoice = _ref.invoice;

        if (invoice) {
          return {
            subtotal: invoice.subtotal / 100.0,
            total: invoice.total / 100.0,
            euVatTaxAmount: invoice.euVatTaxAmount / 100.0,
            euVatTaxPercent: invoice.euVatTaxPercent
          };
        }
        return invoice;
      });
      // TODO Handle fail
    }
  });
});