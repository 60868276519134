define("frontend/models/group", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Group = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    organisationId: _emberData.default.attr("string")
  });

  exports.default = Group;
});