define("frontend/controllers/employees/archive", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    logger: Ember.inject.service(),
    store: Ember.inject.service(),
    payment: Ember.inject.service(),

    hasSubscription: Ember.computed.readOnly("payment.hasSubscription"),

    actions: {
      restore: function restore(employeeId) {
        var _this = this;

        return this.get("ajax").post("/api/employee/" + employeeId + "/restore").then(function (data) {
          _this.get("store").pushPayload("employee", data);

          _this.send("reload");
        }).catch(function (error) {
          _this.get("logger").catch(error, "Error restoring employee", {
            employeeId: employeeId
          });
          throw error;
        });
      },
      wipe: function wipe(employeeId) {
        var _this2 = this;

        return this.get("ajax").post("/api/employee/" + employeeId + "/wipe").then(function () {
          _this2.send("reload");
        }).catch(function (error) {
          _this2.get("logger").catch(error, "Error wiping employee", {
            employeeId: employeeId
          });
          throw error;
        });
      }
    }
  });
});