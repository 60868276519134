define("frontend/components/status-definition/status-definition-form", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var StatusDefinitionValidations = {
    name: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    icon: (0, _validators.validatePresence)({ presence: true, message: "Required" })
  };

  exports.default = Ember.Component.extend({
    statusDefinition: null,

    logger: Ember.inject.service(),
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var statusDefinition = this.get("statusDefinition");
      this.changeset = new _emberChangeset.default(statusDefinition, (0, _emberChangesetValidations.default)(StatusDefinitionValidations), StatusDefinitionValidations);
    },


    actions: {
      save: function save() {
        var _this = this;

        var statusDefinition = this.get("statusDefinition");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              if (_this.get("on-success")) {
                _this.get("on-success")(statusDefinition);
              }
            }, function (error) {
              if (!statusDefinition.get("isValid")) {
                var modelErrors = statusDefinition.get("errors");
                modelErrors.forEach(function (modelError) {
                  changeset.pushErrors(modelError.attribute, modelError.message);
                });
                // Tricky part: reset (existing) model to prevent view of invalid changes, changeset keeps modifications for later save
                if (!statusDefinition.get("isNew")) {
                  statusDefinition.rollbackAttributes();
                }
              } else {
                _this.get("logger").catch(error, "Error saving status definition", { statusDefinitionId: _this.get("statusDefinition.id") });
              }
            });
          }
        });
      },
      cancel: function cancel() {
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      }
    }
  });
});