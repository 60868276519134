define("frontend/react/hooks/useOrganisation", ["exports", "react", "frontend/react/context/OrganisationContext"], function (exports, _react, _OrganisationContext) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var useOrganisation = function useOrganisation() {
    return (0, _react.useContext)(_OrganisationContext.default);
  };

  exports.default = useOrganisation;
});