define("frontend/adapters/summary", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var summaryIdDateExample = "2016-02-31";

  exports.default = _application.default.extend({
    auth: Ember.inject.service(),

    paths: {
      single: "summary",
      collection: "summaries"
    },

    urlForFindRecord: function urlForFindRecord(id, modelName) {
      // Summary id has the format "5e78cadfa6c19d3afc83ca1b_2015-02-20"
      var date = id.substr(id.length - summaryIdDateExample.length);
      var employeeId = id.slice(0, -summaryIdDateExample.length - 1);
      var url = this._super() + "/employee/" + employeeId + "/" + modelName + "/" + date;

      return url;
    }
  });
});