define("frontend/mixins/pending-presence-calculation", ["exports", "moment", "frontend/util/timewatcher"], function (exports, _moment, _timewatcher) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    day: null,
    employee: null,
    clock: null,
    presencesByDay: null,
    summaryOnDay: null,

    // TODO Calculate
    localTimeOffset: Ember.computed("summaryOnDay.{referenceDate,clientDate}", function () {
      var offset = Math.round((0, _moment.default)(this.get("summaryOnDay.clientDate")).diff(this.get("summaryOnDay.referenceDate")));
      return offset;
    }),

    pendingPresence: Ember.computed("day.date", "employee.{isAbsent,presence,presence.checkin}", function () {
      if (!this.get("employee.isAbsent")) {
        var checkin = this.get("employee.presence.checkin");
        if (checkin && (0, _moment.default)(checkin).isSame(this.get("day.date"), "day")) {
          return this.get("employee.presence");
        }
      }
      return null;
    }),

    // Work duration for the current (pending) checkin (without taking stored presence into account)
    pendingWorkDuration: Ember.computed("clock.time", "pendingPresence", "pendingPresence.checkin", "pendingPauseDuration", function () {
      var now = this.get("clock.time");
      if (!this.get("pendingPresence")) {
        return 0;
      }
      var checkinDuration = (0, _moment.default)(now).diff(this.get("pendingPresence.checkin"), "seconds");
      var pauseDuration = this.get("pendingPauseDuration");
      var workDuration = checkinDuration - pauseDuration;
      return workDuration;
    }),

    totalWorkDuration: Ember.computed("clock.time", "localTimeOffset", "summaryOnDay.{workDuration,status,referenceDate}", function () {
      var now = this.get("clock.time");

      if (this.get("summaryOnDay.status") !== "present") {
        return this.get("summaryOnDay.workDuration");
      }

      return (0, _moment.default)(now - this.get("localTimeOffset")).diff(this.get("summaryOnDay.referenceDate"), "seconds") + this.get("summaryOnDay.workDuration");
    }),

    pendingTargetWorkDuration: Ember.computed("summaryOnDay.targetWorkDuration", function () {
      return this.get("summaryOnDay.targetWorkDuration") || 0;
    }),

    // Pause duration for the current (pending) checkin (without taking stored presence into account)
    pendingPauseDuration: Ember.computed("clock.time", "pendingPresence", "employee.presence.pauseIntervals", "employee.presence.pauseIntervals.@each.{start,end}", function () {
      var now = this.get("clock.time");

      if (!this.get("pendingPresence")) {
        return 0;
      }
      var pauseDuration = this.getWithDefault("employee.presence.pauseIntervals", []).map(function (interval) {
        if (interval.end) {
          return (0, _moment.default)(interval.end).diff(interval.start, "seconds");
        } else {
          return (0, _moment.default)(now).diff(interval.start, "seconds");
        }
      }).reduce(function (s, x) {
        return s + x;
      }, 0);
      return pauseDuration;
    }),

    totalPauseDuration: Ember.computed("clock.time", "localTimeOffset", "summaryOnDay.{pauseDuration,referenceDate,status}", function () {
      var now = this.get("clock.time");

      if (this.get("summaryOnDay.status") !== "paused") {
        return this.get("summaryOnDay.pauseDuration");
      }
      return (0, _moment.default)(now - this.get("localTimeOffset")).diff(this.get("summaryOnDay.referenceDate"), "seconds") + this.get("summaryOnDay.pauseDuration");
    }),

    pendingTargetPauseDuration: Ember.computed("pendingTargetWorkDuration", "employee.presence.isCheckinToday", "totalWorkDuration", "summaryOnDay.vacation", function () {
      var targetWork = this.get("pendingTargetWorkDuration");
      var isCheckinToday = this.get("employee.presence.isCheckinToday");
      var vacation = this.get("summaryOnDay.vacation");
      var THIRTY_MINUTES_SECONDS = 1800;
      var FOURTYFIVE_MINUTES_SECONDS = 2700;

      var totalWork = void 0;
      // determine value of totalWork
      if (vacation && isCheckinToday) {
        if (this.get("employee.presence.isPending")) {
          totalWork = this.get("pendingWorkDuration");
        } else {
          totalWork = this.get("employee.presence.workDuration");
        }
      } else if (!vacation && isCheckinToday) {
        totalWork = this.get("totalWorkDuration");
      } else {
        totalWork = 0;
      }

      if (targetWork > totalWork && targetWork !== 0 && !vacation) {
        if (_timewatcher.default.workingOverSix(targetWork) && !_timewatcher.default.workingOverNine(targetWork)) {
          return THIRTY_MINUTES_SECONDS;
        }
        if (_timewatcher.default.workingOverNine(targetWork)) {
          return FOURTYFIVE_MINUTES_SECONDS;
        }
        return 0;
      } else if (targetWork < totalWork || vacation) {
        if (_timewatcher.default.workingOverSix(totalWork) && !_timewatcher.default.workingOverNine(totalWork)) {
          return THIRTY_MINUTES_SECONDS;
        }
        if (_timewatcher.default.workingOverNine(totalWork)) {
          return FOURTYFIVE_MINUTES_SECONDS;
        }
        return 0;
      }
      return 0;
    })
  });
});