define("frontend/adapters/plan", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    paths: {
      single: "plan",
      collection: "plans"
    }
  });
});