define("frontend/react/helper/intl", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCurrency = formatCurrency;
  var currencyFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  });

  function formatCurrency(number) {
    return currencyFormatter.format(number);
  }
});