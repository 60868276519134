define("frontend/formats", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    time: {
      hhmmss: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      },
      hhmm: {
        hour: "numeric",
        minute: "numeric"
      }
    },
    date: {
      hhmmss: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      },
      "time-style": {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      },
      weekday: {
        weekday: "short",
        day: "numeric",
        month: "numeric"
      }
    },
    number: {
      EUR: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      USD: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    }
  };
});