define("frontend/react/components/accounting/customer/CustomerForm", ["exports", "frontend/react/components/form/InputField", "frontend/react/components/form/SelectField"], function (exports, _InputField, _SelectField) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomerForm = function CustomerForm(_ref) {
    var isCreate = _ref.isCreate,
        isLoading = _ref.isLoading,
        onCancel = _ref.onCancel,
        onSubmit = _ref.onSubmit,
        values = _ref.values,
        errors = _ref.errors,
        setFieldValue = _ref.setFieldValue,
        countryOptions = _ref.countryOptions;
    return React.createElement(
      "form",
      { className: "ui form", onSubmit: onSubmit },
      React.createElement(_InputField.default, {
        name: "name",
        value: values.name,
        onUpdate: setFieldValue,
        label: "Name oder Firma",
        required: true,
        error: errors.name,
        width: "ten"
      }),
      React.createElement(_InputField.default, {
        name: "street",
        value: values.street,
        onUpdate: setFieldValue,
        label: "Stra\xDFe & Hausnummer",
        required: true,
        error: errors.street,
        width: "ten"
      }),
      React.createElement(_InputField.default, {
        name: "addressAddition",
        value: values.addressAddition,
        onUpdate: setFieldValue,
        label: "Adresszusatz",
        error: errors.addressAddition,
        width: "ten"
      }),
      React.createElement(
        "div",
        { className: "three fields" },
        React.createElement(_InputField.default, {
          name: "zip",
          value: values.zip,
          onUpdate: setFieldValue,
          label: "PLZ",
          required: true,
          error: errors.zip,
          width: "three",
          maxLength: 5
        }),
        React.createElement(_InputField.default, {
          name: "city",
          value: values.city,
          onUpdate: setFieldValue,
          label: "Ort",
          required: true,
          error: errors.city,
          width: "four"
        }),
        React.createElement(_SelectField.default, {
          name: "country",
          value: values.country,
          options: countryOptions,
          onUpdate: setFieldValue,
          label: "Land",
          placeholder: "- Bitte w\xE4hlen -",
          required: true,
          error: errors.country,
          width: "three"
        })
      ),
      React.createElement(_InputField.default, {
        name: "email",
        type: "email",
        value: values.email,
        onUpdate: setFieldValue,
        label: "E-Mail Adresse f\xFCr Rechnungsempfang",
        required: true,
        error: errors.email,
        width: "ten"
      }),
      React.createElement(_InputField.default, {
        name: "euVatId",
        value: values.euVatId,
        onUpdate: setFieldValue,
        error: errors.euVatId,
        label: "USt-IdNr.",
        disabled: !isCreate,
        width: "ten"
      }),
      React.createElement(
        "div",
        { className: "actions" },
        !isCreate && React.createElement(
          "button",
          { className: "ui basic button", type: "button", onClick: onCancel },
          "Abbrechen"
        ),
        React.createElement(
          "button",
          {
            className: ["ui", "teal", "button", isLoading && "loading"].join(" "),
            type: "submit"
          },
          React.createElement("i", { className: "checkmark icon" }),
          isCreate ? "Weiter" : "Speichern"
        )
      )
    );
  };

  exports.default = CustomerForm;
});