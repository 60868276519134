define("frontend/models/vacation", ["exports", "ember-data/model", "ember-data"], function (exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VACATION_TYPES = undefined;
  var VACATION_TYPES = exports.VACATION_TYPES = ["holiday", "sick-leave", "sick-with-child", "special-leave", "compensation-time", "vocational-training", "public-holiday"];

  exports.default = _model.default.extend({
    start: _emberData.default.attr("date"),
    end: _emberData.default.attr("date"),
    type: _emberData.default.attr("string"),
    comment: _emberData.default.attr("string"),
    employeeId: _emberData.default.attr("string"),
    editedBy: _emberData.default.belongsTo("user"),
    editedOn: _emberData.default.attr("date"),
    versionCount: _emberData.default.attr("number"),
    history: _emberData.default.hasMany("vacation-history-entry")
  });
});