define("frontend/helpers/report-uri", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reportUri = reportUri;
  function reportUri(params, hash) {
    var employee = hash.employee,
        year = hash.year,
        month = hash.month,
        format = hash.format;

    var url = _environment.default.apiUrlPrefix + "/presences/report";
    var query = "&year=" + year + "&month=" + month + "&accept=" + format;
    if (employee) {
      return url + "?employee_id=" + employee.get("id") + query;
    }
    return url + "?" + query;
  }

  exports.default = Ember.Helper.helper(reportUri);
});