define("frontend/routes/application", ["exports", "moment", "frontend/util/log", "frontend/util/error-status", "frontend/util/features", "frontend/util/event-bus"], function (exports, _moment, _log, _errorStatus, _features, _eventBus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationRoute = Ember.Route.extend({
    auth: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    stream: Ember.inject.service(),
    logger: Ember.inject.service(),

    // Store if the application route was activated
    activated: false,

    beforeModel: function beforeModel() {
      this.get("intl").setLocale("de-de");
      _moment.default.locale("de");
    },
    afterModel: function afterModel() {
      var _this = this;

      var promises = [this.get("auth.user").catch(function (error) {
        _log.default.error("Could not load user, resetting authentication", error);
        _this.get("auth").reset();
        _this.transitionTo("login");
      })];
      if (this.get("auth.authenticated")) {
        promises.concat(this.afterAuthenticated());
      }
      return Ember.RSVP.all(promises);
    },
    activate: function activate() {
      var _this2 = this;

      // Used to check how a catched error can be presented (without activation there is no rendered application)
      this.set("activated", true);
      this._closeSplashScreen();

      _eventBus.default.on("subscription-changed", function () {
        var organisation = _this2.get("auth.user.organisation");
        if (organisation) {
          organisation.reload();
        }
      });
    },
    afterAuthenticated: function afterAuthenticated() {
      var promises = [];
      if ((0, _features.default)("custom-status")) {
        promises.push(this.store.findAll("status-definition"));
      }
      var stream = this.get("stream");
      stream.listen();
      stream.on("reconnected", this, this._reloadModel);

      return promises;
    },
    _reloadModel: function _reloadModel() {
      var employee = this.get("auth.user.employee");
      if (employee) {
        employee.reload();
      }
    },
    _closeSplashScreen: function _closeSplashScreen() {
      document.body.classList.add("stop");
    },


    actions: {
      authenticated: function authenticated() {
        var _this3 = this;

        this.store.unloadAll();
        // TODO Preload organisation before transition
        Ember.RSVP.all(this.afterAuthenticated()).then(function () {
          _this3.transitionTo("index");
        });
      },
      error: function error(_error) {
        this._closeSplashScreen();

        var status = (0, _errorStatus.default)(_error);
        if (status === 401) {
          this.get("auth").reset();
          // TODO Intl
          this.get("notify").alert("Anmeldung wird benötigt");
          return this.transitionTo("login");
        }

        this.get("logger").catch(_error, "Application error action", {}, this.get("activated"));

        return true;
      }
    }
  });

  exports.default = ApplicationRoute;
});