define('frontend/components/tracking/matomo-tracking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isTrackingEnabled: true,
    trackingUrl: '//matomo.networkteam.com',
    siteId: 43,

    didInsertElement: function didInsertElement() {
      if (!this.isTrackingEnabled) {
        return;
      }

      window._paq = window._paq || [];
      window._paq.push(['disableCookies']);
      window._paq.push(['setTrackerUrl', this.trackingUrl + '/matomo.php']);
      window._paq.push(['setSiteId', this.siteId]);
    }
  });
});