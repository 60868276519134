define("frontend/routes/user/password-reset", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    token: null,
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get("auth.authenticated")) {
        this.get("notify").alert("Bitte Abmelden zum Zurücksetzen des Passworts");
        this.transitionTo("index");
      }
    },
    model: function model(params) {
      return {
        token: params.token
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      return this.get("ajax").request("/api/users/validate-password-reset-token?token=" + model.token, {
        dataType: "text"
      }).catch(function () {
        _this.get("notify").alert("Ungültiger Link, bitte Passwort erneut anfragen");
        _this.transitionTo("reset-password");
      });
    },
    activate: function activate() {
      document.body.classList.add("public");
    },
    deactivate: function deactivate() {
      document.body.classList.remove("public");
    }
  });
});