define("frontend/components/dashboard/dashboard-tooltips", ["exports", "frontend/util/dashboard/timeconversions", "frontend/util/timewatcher"], function (exports, _timeconversions, _timewatcher) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showTooltip: null,

    data: null,

    context: Ember.computed.readOnly("data.context"),
    direction: Ember.computed.readOnly("data.direction"),

    intl: Ember.inject.service(),

    buffer: Ember.computed("context", function () {
      var ctx = this.get("context");
      if (ctx === "pause-bar") {
        return 20;
      } else if (ctx === "work-bar" || ctx === "target-reached-work-bar") {
        return 30;
      } else if (ctx === "chart-props") {
        return {
          x: 73,
          y: 40
        };
      }
      return null;
    }),

    container: Ember.computed("context", function () {
      var ctx = this.get("context");
      if (ctx === "pause-bar" || ctx === "work-bar" || ctx === "target-reached-work-bar") {
        return this.get("element").closest(".doughnutwr").getBoundingClientRect();
      } else if (ctx === "chart-props") {
        return this.get("element").closest(".view-wrapper").getBoundingClientRect();
      }
      return null;
    }),

    pos: Ember.computed("data", "context", "container", "buffer", function () {
      var ctx = this.get("context");
      if (ctx === "pause-bar") {
        return {
          bottom: this.get("container").height - this.get("buffer") + "px",
          top: "auto",
          left: this.get("container").width / 4 + window.pageXOffset - document.documentElement.scrollLeft - this.get("buffer") + "px",
          right: "auto"
        };
      } else if (ctx === "work-bar" || ctx === "target-reached-work-bar") {
        if (this.get("direction") === "left") {
          return {
            bottom: "auto",
            top: "25%",
            left: "auto",
            right: this.get("container").width + window.pageXOffset - document.documentElement.scrollLeft + "px"
          };
        }

        return {
          bottom: "auto",
          top: "25%",
          left: this.get("container").width + window.pageXOffset - document.documentElement.scrollLeft + "px",
          right: "auto"
        };
      } else if (ctx === "chart-props") {
        return {
          bottom: "auto",
          top: this.get("data").target.y1 + this.get("buffer").y + "px",
          left: this.get("data").target.x1 - this.get("buffer").x + "px",
          right: "auto"
        };
      }

      return null;
    }),

    style: Ember.computed("pos", function () {
      var pos = this.get("pos");
      return Ember.String.htmlSafe("left:" + pos.left + "; right:" + pos.right + ";top:" + pos.top + "; bottom:" + pos.bottom + ";");
    }),

    tooltip: Ember.computed("data", "context", function () {
      var data = this.get("data");
      var vacationLabel = data.vacationType && this.get("intl").lookup("vacation.type." + data.vacationType);
      if (this.get("context") === "work-bar") {
        if (data.didAbort) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Aufgrund nicht erfolgter Abmeldung, wurde Ihre Anwesenheit",
            labelText: "abgebrochen",
            digits: "",
            color: "red"
          };
        } else if (!data.presence.isCheckinToday && data.targetWork !== 0) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Heute eingeplante Soll-Arbeitszeit",
            labelText: "",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.targetWork),
            color: "grey"
          };
        } else if (data.targetWork < data.totalWork && data.targetWork > 0) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Tagessoll erreicht",
            labelText: "mehr",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalWork - data.targetWork),
            color: "black"
          };
        } else if (data.targetWork === 0) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Keine Soll-Arbeitszeit vorgegeben",
            labelText: data.totalWork > 0 ? "gearbeitet" : "",
            digits: data.totalWork > 0 ? (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalWork) : "",
            color: "grey"
          };
        } else {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Soll-Arbeitszeit nicht erreicht",
            labelText: "noch",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.targetWork - data.totalWork),
            color: "grey"
          };
        }
      } else if (this.get("context") === "target-reached-work-bar") {
        if (data.targetWork <= 0) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Hier wird ihre gearbeite Zeit visualisiert. Achten Sie auf ausreichend Pausen.",
            labelText: "",
            digits: "",
            color: "black"
          };
        } else if ((0, _timewatcher.workingOverTen)(data.totalWork) && !data.isVacation) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Sie arbeiten über die gesetzliche Höchstarbeitszeit nach § 3 ArbZG.",
            labelText: "zuviel",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalWork - 36000),
            color: "red"
          };
        } else {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: "Tagessoll erreicht",
            labelText: "mehr",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalWork - data.targetWork),
            color: "black"
          };
        }
      } else if (this.get("context") === "pause-bar") {
        if (data.status === "paused") {
          return {
            header: "in der Pause",
            labelText: "insgesamt",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalPause),
            color: "grey"
          };
        } else if (!data.presence.isCheckinToday || data.presence.isCheckinToday && !(0, _timewatcher.workingOverSix)(data.totalWork) && data.targetPause >= data.totalPause) {
          return {
            header: "Heute eingeplante Pausenzeit",
            labelText: "",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.targetPause),
            color: "grey"
          };
        } else if (data.targetPause <= data.totalPause) {
          return {
            header: "Pausenzeiten eingehalten",
            labelText: "insgesamt",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalPause),
            color: "grey"
          };
        } else {
          return {
            header: "Legen Sie eine Pause ein!",
            labelText: "unter Mindestpause",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.totalPause - data.targetPause),
            color: "red"
          };
        }
      } else if (this.get("context") === "chart-props") {
        if (data.target.series.name === "WORKDURATION" && data.didAbort) {
          return {
            header: data.weekday,
            digits: null,
            labelText: "Arbeitszeit abgebrochen",
            color: "red"
          };
        } else if (data.target.series.name === "WORKDURATION" && !data.didAbort) {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: data.weekday,
            labelText: "Arbeitszeit",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.workDuration),
            color: !data.isVacation && (0, _timewatcher.workingOverTen)(data.workDuration) ? "red" : "black"
          };
        } else if (data.target.series.name === "PAUSE") {
          var displayedPauseDuration = data.target.value.y;
          var hadEnoughPause = (0, _timewatcher.enoughPause)((0, _timeconversions.convertHoursToSeconds)(displayedPauseDuration), data.workDuration);
          return {
            header: hadEnoughPause ? data.weekday : "Nicht eingehaltene Pause: ",
            labelText: "Pausenzeit",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.pauseDuration),
            color: hadEnoughPause ? "grey" : "red"
          };
        } else if (data.target.series.name === "PAUSETARGETBG") {
          return !(0, _timewatcher.enoughPause)(data.pauseDuration, data.workDuration) ? {
            header: "Nicht eingehaltene Pause: ",
            labelText: "Mindestpausenzeit",
            digits: (0, _timewatcher.workingOverNine)(data.workDuration) ? "0:45" : "0:30",
            color: "red"
          } : null;
        } else if (data.target.series.name === "TARGETBG") {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            header: data.weekday,
            labelText: "Soll-Arbeitszeit",
            digits: (0, _timeconversions.convertSecondsToDigitsMinute)(data.targetWorkDuration),
            color: "grey"
          };
        } else if (data.target.series.name === "NOWORKDAY") {
          return {
            vacationTitle: data.isVacation ? vacationLabel : null,
            holidayTitle: data.isHoliday ? data.holidayTitle : null,
            header: data.weekday
          };
        }
      }

      return false;
    })
  });
});