define("frontend/components/employee/vacation-row", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "tr",

    vacation: null,
    editing: false,
    enableEdit: true,

    logger: Ember.inject.service(),

    actions: {
      edit: function edit() {
        this.set("editing", true);
      },
      success: function success() {
        this.set("editing", false);
        if (this.get("on-success")) {
          this.get("on-success")();
        }
      },
      cancel: function cancel() {
        this.set("editing", false);
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      },
      showDeleteConfirmation: function showDeleteConfirmation(name) {
        this.$(".ui." + name + ".modal").modal("show");
      },
      delete: function _delete() {
        var _this = this;

        var promise = this.get("vacation").destroyRecord().catch(function (error) {
          _this.get("logger").catch(error, "Error deleting vacation", {
            vacationId: _this.get("vacation.id")
          });
        });
        if (this.get("on-delete")) {
          promise.then(function () {
            _this.get("on-delete")();
          });
        }
        return promise;
      }
    }
  });
});