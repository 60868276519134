define("frontend/react/context/OrganisationContext", ["exports", "react"], function (exports, _react) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OrganisationContextProvider = undefined;


  var OrganisationContext = (0, _react.createContext)({});

  var OrganisationContextProvider = exports.OrganisationContextProvider = OrganisationContext.Provider;

  exports.default = OrganisationContext;
});