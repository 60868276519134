define("frontend/mixins/presence-statistics-calculation", ["exports", "frontend/util/timewatcher", "moment"], function (exports, _timewatcher, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOT_EXISTENT = 0;

  var isBeforeDay = function isBeforeDay(entry, now) {
    return _moment.default.utc(entry.date).isBefore(now, "day");
  };

  exports.default = Ember.Mixin.create({
    clock: Ember.inject.service("slow-clock"),

    workDurationSum: Ember.computed("monthlyPresenceReport", "clock.time", function () {
      var now = (0, _moment.default)(this.get("clock.time")).utc();
      if (this.get("monthlyPresenceReport")) {
        return this.get("monthlyPresenceReport").filter(function (entry) {
          return isBeforeDay(entry, now);
        }).map(function (entry) {
          return entry.workDuration;
        }).reduce(function (value, sum) {
          return value + sum;
        }, 0);
      }
      return 0;
    }),

    targetWorkDurationSum: Ember.computed("monthlyPresenceReport", "clock.time", function () {
      var now = (0, _moment.default)(this.get("clock.time")).utc();
      if (this.get("monthlyPresenceReport")) {
        return this.get("monthlyPresenceReport").filter(function (entry) {
          return isBeforeDay(entry, now);
        }).map(function (entry) {
          return Math.max(0, entry.targetWorkDuration);
        }).reduce(function (value, sum) {
          return value + sum;
        }, 0);
      }
      return 0;
    }),

    workDurationBalance: Ember.computed("monthlyPresenceReport", function () {
      var presences = this.get("monthlyPresenceReport") || {};

      if (!Ember.isArray(presences)) {
        return 0;
      }
      if (presences.length < 1) {
        return 0;
      }

      return presences[presences.length - 1].workDurationBalance;
    }),

    averageWorkDuration: Ember.computed("monthlyPresenceReport.@each.workDuration", function () {
      var presences = this.get("monthlyPresenceReport") || [];

      var presencesWithWorkDuration = presences.filter(function (_ref) {
        var workDuration = _ref.workDuration;
        return workDuration > 0;
      });

      var workDurationSum = presencesWithWorkDuration.reduce(function (total, _ref2) {
        var workDuration = _ref2.workDuration;
        return total + workDuration;
      }, 0);

      return Math.round(workDurationSum / presencesWithWorkDuration.length) || NOT_EXISTENT;
    }),

    averagePauseDuration: Ember.computed("monthlyPresenceReport.@each.pauseDuration", function () {
      var presences = this.get("monthlyPresenceReport") || [];

      var presencesWithPauseDuration = presences.filter(function (_ref3) {
        var pauseDuration = _ref3.pauseDuration;
        return pauseDuration > NOT_EXISTENT;
      });

      var pauseDurationSum = presencesWithPauseDuration.reduce(function (total, _ref4) {
        var pauseDuration = _ref4.pauseDuration;
        return total + pauseDuration;
      }, 0);

      return Math.round(pauseDurationSum / presencesWithPauseDuration.length) || NOT_EXISTENT;
    }),

    blankPresences: Ember.computed("monthlyPresenceReport.@each.{workDuration,targetWorkDuration,vacation}", "clock.time", function () {
      var presences = this.get("monthlyPresenceReport") || [];
      var now = (0, _moment.default)(this.get("clock.time")).utc();
      return presences.filter(function (presence) {
        return isBeforeDay(presence, now) && presence.workDuration === NOT_EXISTENT && presence.targetWorkDuration > NOT_EXISTENT && !presence.vacation;
      }).length;
    }),

    abortedPresences: Ember.computed("monthlyPresenceReport", function () {
      if (this.get("monthlyPresenceReport")) {
        var now = (0, _moment.default)(this.get("clock.time")).utc();

        return this.get("monthlyPresenceReport").filter(function (entry) {
          return isBeforeDay(entry, now);
        }).filter(function (entry) {
          return entry.aborted;
        }).length;
      }
      return 0;
    }),

    presencesOverTime: Ember.computed("monthlyPresenceReport.@each.checkin", function () {
      var presences = this.get("monthlyPresenceReport") || [];

      return presences.filter(function (presence) {
        if (presence.checkin) {
          return true;
        }
        return false;
      }).length;
    }),

    workDurationAbnormalCount: Ember.computed("monthlyPresenceReport.@each.{workDuration,aborted}", function () {
      var presences = this.get("monthlyPresenceReport") || [];

      return presences.filter(function (presence) {
        if (!presence.vacation && (0, _timewatcher.workingOverTen)(presence.workDuration)) {
          return true;
        }
        return false;
      }).length;
    }),

    pauseAbnormalCount: Ember.computed("monthlyPresenceReport.@each.{workDuration,pauseDuration}", function () {
      var presences = this.get("monthlyPresenceReport") || [];
      return presences.filter(function (presence) {
        if (!presence.vacation && !(0, _timewatcher.enoughPause)(presence.pauseDuration, presence.workDuration)) {
          return true;
        }
        return false;
      }).length;
    })
  });
});