define("frontend/components/employee/presence-chart", ["exports", "chartist"], function (exports, _chartist) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["presence-chart"],

    presencesByDayOfMonth: null,
    height: 150,

    loading: false,
    _chartRef: null,

    didInsertElement: function didInsertElement() {
      var data = {
        labels: this.get("labels"),
        series: this.get("series")
      };
      var options = {
        stackBars: true,
        height: this.get("height"),
        chartPadding: 0,
        axisY: {
          referenceValue: 8
        }
      };
      var responsiveOptions = [["screen and (max-width: 1024px)", {
        axisX: {
          labelInterpolationFnc: function labelInterpolationFnc(value, index, values) {
            return index % 2 === 0 || index + 1 === values.length ? value : null;
          }
        }
      }]];
      var chart = new _chartist.default.Bar(this.get("element"), data, options, responsiveOptions);
      this._chartRef = chart;
    },
    willDestroyElement: function willDestroyElement() {
      if (this._chartRef) {
        this._chartRef.detach();
      }
    },


    labels: Ember.computed("presencesByDayOfMonth", function () {
      var presencesByDay = this.get("presencesByDayOfMonth") || [];
      return presencesByDay.map(function (presence, i) {
        return i + 1;
      });
    }),

    series: Ember.computed("presencesByDayOfMonth", function () {
      var presencesByDay = this.get("presencesByDayOfMonth") || [];
      return [presencesByDay.map(function (presence) {
        return presence.workDuration / 3600.0;
      }), presencesByDay.map(function (presence) {
        return presence.pauseDuration / 3600.0;
      })];
    }),

    updateChart: Ember.observer("presencesByDayOfMonth", function () {
      this._chartRef.update({
        labels: this.get("labels"),
        series: this.get("series")
      });
    })
  });
});