define("frontend/app", ["exports", "ember-load-initializers", "@sentry/browser", "@sentry/integrations", "frontend/resolver", "frontend/config/environment", "frontend/util/promise-polyfill"], function (exports, _emberLoadInitializers, _browser, _integrations, _resolver, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  if (_environment.default.sentry.development === false) {
    _browser.init({
      dsn: _environment.default.sentry.dsn,
      integrations: [new _integrations.Ember()],
      environment: _environment.default.sentry.environment,
      debug: _environment.default.sentry.debug,
      release: _environment.default.APP.version,

      beforeSend: function beforeSend(event, hint) {
        var message = hint.originalException.message;

        // Ignore specific errors that are not really errors
        if (message && message.match(/TransitionAborted|The ajax operation was aborted|The adapter operation was aborted/)) {
          return null;
        }
        return event;
      }
    });
  }

  if (!navigator.cookieEnabled) {
    document.body.innerHTML = "<div class=\"ui label red\"><p>Sie m\xFCssen Cookies in ihrem Browser aktivieren, um diese Seite nutzen zu k\xF6nnen.</p></div>";
  }

  exports.default = App;
});