define("frontend/react/components/accounting/subscription/SubscriptionForm", ["exports", "frontend/react/helper/intl"], function (exports, _intl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SubscriptionForm = function SubscriptionForm(_ref) {
    var subscription = _ref.subscription,
        isLoading = _ref.isLoading,
        onSubmit = _ref.onSubmit,
        subscriptionPreview = _ref.subscriptionPreview;
    return React.createElement(
      "form",
      { className: "ui form accounting", onSubmit: onSubmit },
      isLoading ? React.createElement("div", { className: "ui centered raised card loading" }) : React.createElement(
        "div",
        { className: "ui centered raised card" },
        React.createElement(
          "div",
          { className: "content" },
          React.createElement(
            "div",
            { className: "header" },
            "Werkzeit Zeiterfassung und Urlaubsverwaltung"
          ),
          React.createElement(
            "ul",
            null,
            React.createElement(
              "li",
              null,
              "jederzeit k\xFCndbar"
            ),
            React.createElement(
              "li",
              null,
              "nur ",
              (0, _intl.formatCurrency)(subscriptionPreview.totalSingle),
              " pro Mitarbeiter*in im Monat"
            ),
            React.createElement(
              "li",
              null,
              "Abrechnung monatlich bequem per Rechnung"
            )
          )
        ),
        React.createElement(
          "div",
          { className: "extra content" },
          React.createElement(
            "table",
            { className: "ui table unstackable" },
            React.createElement(
              "tbody",
              null,
              React.createElement(
                "tr",
                null,
                React.createElement(
                  "th",
                  { className: "four wide" },
                  React.createElement(
                    "h3",
                    null,
                    "Preis"
                  )
                ),
                React.createElement(
                  "td",
                  null,
                  React.createElement(
                    "span",
                    { className: "large value" },
                    subscription.employeeCount,
                    " "
                  ),
                  React.createElement(
                    "span",
                    { className: "small-text" },
                    "Mitarbeitende"
                  )
                ),
                React.createElement(
                  "td",
                  null,
                  React.createElement(
                    "span",
                    { className: "value" },
                    "="
                  )
                ),
                React.createElement(
                  "td",
                  { className: "right aligned" },
                  React.createElement(
                    "span",
                    { className: "large value" },
                    (0, _intl.formatCurrency)(subscriptionPreview.subtotal)
                  )
                )
              ),
              React.createElement(
                "tr",
                null,
                React.createElement(
                  "th",
                  { className: "four wide" },
                  React.createElement(
                    "h4",
                    null,
                    "zzgl. USt."
                  )
                ),
                React.createElement(
                  "td",
                  null,
                  React.createElement(
                    "span",
                    { className: "value" },
                    subscriptionPreview.valueAddedTaxPercent,
                    "%"
                  )
                ),
                React.createElement(
                  "td",
                  null,
                  React.createElement(
                    "span",
                    { className: "value" },
                    React.createElement(
                      "div",
                      { className: "value" },
                      " = "
                    )
                  )
                ),
                React.createElement(
                  "td",
                  { className: "right aligned" },
                  React.createElement(
                    "span",
                    { className: "value" },
                    (0, _intl.formatCurrency)(subscriptionPreview.valueAddedTaxAmount)
                  )
                )
              ),
              React.createElement(
                "tr",
                null,
                React.createElement(
                  "th",
                  { className: "four wide" },
                  React.createElement(
                    "h3",
                    null,
                    "Gesamt"
                  )
                ),
                React.createElement(
                  "td",
                  { colSpan: 3, className: "right aligned" },
                  React.createElement("span", { className: "small-text" }),
                  React.createElement(
                    "span",
                    { className: "large value" },
                    (0, _intl.formatCurrency)(subscriptionPreview.total)
                  ),
                  React.createElement("br", null),
                  React.createElement(
                    "span",
                    { className: "small-text" },
                    "pro Monat"
                  )
                )
              )
            )
          )
        ),
        React.createElement(
          "div",
          { className: "extra content" },
          React.createElement(
            "p",
            null,
            "Mit der Bestellung stimme ich den",
            " ",
            React.createElement(
              "a",
              {
                href: "https://werkzeit.net/nutzungsbedingungen.html",
                target: "_blank",
                rel: "noopener noreferrer",
                className: "ui"
              },
              "Nutzungsbedingungen"
            ),
            ", sowie der",
            " ",
            React.createElement(
              "a",
              {
                href: "https://werkzeit.net/datenschutz.html",
                target: "_blank",
                rel: "noopener noreferrer",
                className: "ui"
              },
              "Datenschutzerkl\xE4rung"
            ),
            " ",
            "zu."
          ),
          React.createElement("div", { className: "ui divider hidden" }),
          React.createElement(
            "div",
            { className: "right aligned" },
            React.createElement(
              "button",
              {
                className: ["ui", "teal", "button", isLoading && "loading"].join(" "),
                type: "submit"
              },
              "Zahlungspflichtig bestellen"
            )
          )
        )
      )
    );
  };

  exports.default = SubscriptionForm;
});