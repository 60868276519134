define("frontend/components/employee/calendar-monthly/day", ["exports", "moment", "frontend/mixins/pending-presence-calculation"], function (exports, _moment, _pendingPresenceCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pendingPresenceCalculation.default, {
    tagName: "td",
    classNameBindings: ["day.today:today", "day.inactive:inactive", "selected", "noWorkday:no-workday"],

    employee: null,
    // Day is a full calendar entry and not an int!
    day: null,
    presencesByDay: null,

    clock: Ember.inject.service(),
    store: Ember.inject.service(),

    presenceForDay: Ember.computed("day.{inactive,day}", "presencesByDay.content", function () {
      var presencesByDay = this.get("presencesByDay.content");

      var currentDay = (0, _moment.default)(this.get("day.date"));
      if (presencesByDay) {
        return presencesByDay.find(function (presence) {
          var presenceActual = (0, _moment.default)(presence["date"]).utc();
          return currentDay.utc().isSame(presenceActual, "day");
        });
      }

      return null;
    }),

    earliestCheckin: Ember.computed("pendingPresence.checkin", "presenceForDay.checkin", function () {
      if (this.get("presenceForDay.checkin")) {
        return this.get("presenceForDay.checkin");
      } else {
        return this.get("pendingPresence.checkin");
      }
    }),

    totalWorkDuration: Ember.computed("pendingWorkDuration", "presenceForDay.workDuration", function () {
      return this.get("pendingWorkDuration") + this.getWithDefault("presenceForDay.workDuration", 0);
    }),

    totalWorkDurationMinusTarget: Ember.computed("totalWorkDuration", "presenceForDay.targetWorkDuration", function () {
      var targetWorkDuration = 0;
      if (this.get("presenceForDay.targetWorkDuration") > 0) {
        targetWorkDuration = this.get("presenceForDay.targetWorkDuration");
      }
      return this.get("totalWorkDuration") - targetWorkDuration;
    }),

    totalPauseDuration: Ember.computed("pendingPauseDuration", "presenceForDay.pauseDuration", function () {
      return this.get("pendingPauseDuration") + this.getWithDefault("presenceForDay.pauseDuration", 0);
    }),

    hasStatus: Ember.computed("presenceForDay.status", function () {
      return !Ember.isEmpty(this.get("presenceForDay.status"));
    }),

    noWorkday: Ember.computed("presenceForDay.targetWorkDuration", function () {
      return this.get("presenceForDay.targetWorkDuration") === 0;
    }),

    vacation: Ember.computed.readOnly("presenceForDay.vacation"),

    click: function click() {
      if (this.get("on-click")) {
        this.get("on-click")();
      }
    }
  });
});