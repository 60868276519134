define("frontend/components/input-datetime", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    classNames: ["ui right action large datetime input"],

    date: null,
    onchange: NOOP,
    required: true,

    minimumDate: null,
    maximumDate: null,
    referenceDate: null,

    _preliminaryDateValue: null,

    timeValue: Ember.computed("date", "referenceDate", {
      get: function get() {
        var d = this.get("date");
        if (!d) {
          return "";
        }
        var hours = d.getHours(),
            minutes = d.getMinutes();
        return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
      },
      set: function set(key, value) {
        if (value.match) {
          var matches = value.match(/([0-9]{1,2}):([0-9]{2})/);
          if (matches) {
            if (!this.get("date")) {
              this.set("date", this.get("referenceDate"));
            }
            var d = this.get("date"),
                d1 = (0, _moment.default)(d);
            d1.set({ hour: matches[1], minute: matches[2] });
            this.get("onchange")(d1.toDate());
          }
        }
        return value;
      }
    }),

    dateValues: Ember.computed("minimumDate", "maximumDate", function () {
      var min = this.get("minimumDate"),
          max = this.get("maximumDate"),
          values = [];

      if (min && max) {
        var d = (0, _moment.default)(min).startOf("day"),
            upper = (0, _moment.default)(max).endOf("day");
        do {
          values.push({
            date: d.format("YYYY-MM-DD"),
            day: d.format("DD"),
            month: d.format("MM")
          });
          d.add(1, "day");
        } while (d.isSameOrBefore(upper));
      }

      return values;
    }),

    dateValue: Ember.computed("date", "referenceDate", {
      get: function get() {
        var d = this.get("date");
        return (0, _moment.default)(d).format("YYYY-MM-DD");
      },
      set: function set(key, value) {
        if (value.match) {
          var matches = value.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
          if (matches) {
            if (!this.get("date")) {
              this.set("date", (0, _moment.default)(this.get("referenceDate")).startOf("day").toDate());
            }
            var d = this.get("date");
            var d1 = (0, _moment.default)(d);
            d1.set({ year: matches[1], month: matches[2] - 1, date: matches[3] });
            this.get("onchange")(d1.toDate());
          }
        }
        return value;
      }
    })
  });
});