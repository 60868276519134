define("frontend/components/employee/presence-statistics", ["exports", "moment", "frontend/mixins/pending-presence-calculation"], function (exports, _moment, _pendingPresenceCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pendingPresenceCalculation.default, {
    classNames: ["ui", "basic", "presence-statistics", "segment"],
    classNameBindings: ["loading"],

    employee: null,
    presencesByDay: null,
    selectedYear: null,
    selectedMonth: null,
    presenceForDay: null,

    loading: Ember.computed.readOnly("presencesByDay.isPending"),

    // Inject default clock for mixin dependencies
    clock: Ember.inject.service(),
    slowClock: Ember.inject.service("slow-clock"),

    // Reduce the presences to the actual month
    presencesByDayOfMonth: Ember.computed("presencesByDay.content", "selectedMonth", function () {
      var presences = this.get("presencesByDay.content") || [];
      var month = this.get("selectedMonth");
      return presences.filter(function (presence) {
        return month == (0, _moment.default)(presence.date).month() + 1;
      });
    }),

    // Calculate the sum of work durations, but don't include anything after the current day
    workDurationSum: Ember.computed("presencesByDay.content", "pendingWorkDuration", "slowClock.time", "selectedMonth", function () {
      var presences = this.get("presencesByDay.content") || [];
      var now = (0, _moment.default)(this.get("slowClock.time")).utc();
      var month = this.get("selectedMonth");
      if (month === now.month() + 1) {
        presences = presences.filter(function (presence) {
          return !_moment.default.utc(presence.date).isAfter(now);
        });
      }
      return presences.filter(function (presence) {
        return month == (0, _moment.default)(presence.date).month() + 1;
      }).map(function (presence) {
        return presence.workDuration;
      }).reduce(function (value, sum) {
        return value + sum;
      }, 0) + this.get("pendingWorkDuration");
    }),

    // Calculate the sum of target work durations, but don't include anything after the current day
    targetWorkDurationSum: Ember.computed("presencesByDay.content", "slowClock.time", "selectedMonth", function () {
      var presences = this.get("presencesByDay.content") || [];
      var now = (0, _moment.default)(this.get("slowClock.time")).utc();
      var month = this.get("selectedMonth");
      if (month === now.month() + 1) {
        presences = presences.filter(function (presence) {
          return !_moment.default.utc(presence.date).isAfter(now);
        });
      }
      return presences.filter(function (presence) {
        return month == (0, _moment.default)(presence.date).month() + 1;
      }).map(function (presence) {
        return Math.max(0, presence.targetWorkDuration);
      }).reduce(function (value, sum) {
        return value + sum;
      }, 0);
    }),

    durationSpanLabel: Ember.computed("selectedMonth", "slowClock.time", function () {
      var thisMonth = (0, _moment.default)(this.get("slowClock.time")).month() + 1;
      var selMonth = parseInt(this.get("selectedMonth"));
      return thisMonth === selMonth ? "heute" : "Ende des Monats";
    }).readOnly(),

    targetWorkTooltip: Ember.computed("durationSpanLabel", function () {
      return "Soll-Arbeitszeit dieses Monats (Stand: " + this.get("durationSpanLabel") + ")";
    }),

    workDurationTooltip: Ember.computed("durationSpanLabel", function () {
      return "Gebuchte Arbeitszeit dieses Monats (Stand: " + this.get("durationSpanLabel") + ")";
    }),

    // Override pending presence to get the pending presence from the selected year and month (and not by day)
    pendingPresence: Ember.computed("selectedYear", "selectedMonth", "employee.{isAbsent,presence.checkin}", function () {
      if (!this.get("employee.isAbsent")) {
        var checkin = this.get("employee.presence.checkin");
        if (checkin && (0, _moment.default)(checkin).year() === this.get("selectedYear") && (0, _moment.default)(checkin).month() + 1 === this.get("selectedMonth")) {
          return this.get("employee.presence");
        }
      }
      return null;
    })
  });
});