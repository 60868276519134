define("frontend/serializers/organisation", ["exports", "ember-data", "frontend/mixins/serializer/extract-errors"], function (exports, _emberData, _extractErrors) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_extractErrors.default, _emberData.default.EmbeddedRecordsMixin, {
    // Do not use a root property when sending data to the server
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      var data = this.serialize(snapshot, options),
          p;
      for (p in hash) {
        delete hash[p];
      }
      for (p in data) {
        hash[p] = data[p];
      }
    }
  });
});