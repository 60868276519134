define("frontend/react/components/accounting/payment/PaymentData", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PaymentData = function PaymentData(_ref) {
    var payment = _ref.payment,
        onShowForm = _ref.onShowForm;

    return React.createElement(
      "div",
      null,
      React.createElement(
        "table",
        { className: "ui very basic table unstackable" },
        React.createElement(
          "tbody",
          null,
          React.createElement(
            "tr",
            null,
            React.createElement(
              "th",
              { className: "four wide" },
              "Bezahlart"
            ),
            React.createElement(
              "td",
              { className: "twelve wide" },
              paymentMethodLabel(payment.paymentMethod)
            )
          ),
          payment.paymentMethod === "sepaDebit" && React.createElement(
            "tr",
            null,
            React.createElement(
              "th",
              { className: "four wide" },
              "Kontodaten"
            ),
            React.createElement(
              "td",
              { className: "twelve wide" },
              payment.accountHolderName,
              React.createElement("br", null),
              payment.sepaDebitIdentifier
            )
          )
        )
      ),
      React.createElement(
        "button",
        { className: "ui basic button", type: "submit", onClick: onShowForm },
        "Bearbeiten"
      )
    );
  };

  var paymentMethodLabel = function paymentMethodLabel(paymentMethod) {
    switch (paymentMethod) {
      case "sepaDebit":
        return "SEPA Lastschrift";
      case "invoice":
        return "monatliche Rechnung";
    }
    return "-";
  };

  exports.default = PaymentData;
});