define("frontend/util/log", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function prepend(args) {
    return ["[WERKZEIT]"].concat(args);
  }

  /* eslint-disable no-console */
  exports.default = {
    debug: function debug() {
      if (_environment.default.log.debug && console && console.debug) {
        var _console;

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        (_console = console).debug.apply(_console, _toConsumableArray(prepend(args)));
      }
    },
    info: function info() {
      if (_environment.default.log.info && console && console.info) {
        var _console2;

        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        (_console2 = console).info.apply(_console2, _toConsumableArray(prepend(args)));
      }
    },
    warn: function warn() {
      if (_environment.default.log.warn && console && console.warn) {
        var _console3;

        for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        (_console3 = console).warn.apply(_console3, _toConsumableArray(prepend(args)));
      }
    },
    error: function error() {
      if (_environment.default.log.error && console && console.error) {
        var _console4;

        for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }

        (_console4 = console).error.apply(_console4, _toConsumableArray(prepend(args)));
      }
    }
  };
});