define("frontend/components/ui/top-menu", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["application", "ui", "borderless", "top", "menu"],

    auth: Ember.inject.service(),

    hasPermission: Ember.computed("auth.user.permissions", function () {
      var permissions = this.get("auth.user.permissions");

      // Administration and System Administration have same auth rights in Webapp,
      // so we check if Account is linked to employee to determine system admin
      var employee = this.get("auth.user.employee");
      if (!Array.isArray(permissions)) {
        return false;
      }

      return permissions.indexOf("permissionAllEmployeeWrite") > -1 && !!employee;
    })
  });
});