define("frontend/components/settings/accounting/accounting-component", ["exports", "frontend/components/react-component", "frontend/react/components/accounting/AccountingContainer"], function (exports, _reactComponent, _AccountingContainer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _reactComponent.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.reactRender(_AccountingContainer.default);
    }
  });
});