define("frontend/components/employee/presence-history/button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    presence: null,

    versionCount: Ember.computed("presence.versions", function () {
      var versions = this.get("presence.versions");
      return versions && versions.length;
    }),

    actions: {
      buttonClick: function buttonClick(name) {
        this.$(".ui." + name + ".modal").modal("show");
      }
    }
  });
});