define("frontend/components/employee/employee-form", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "frontend/util/error-status", "frontend/models/employee"], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _errorStatus, _employee) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmployeeValidations = {
    firstname: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    lastname: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    password: (0, _validators.validateLength)({
      min: 8,
      allowBlank: true,
      message: "Password needs at least 8 characters"
    })
  };

  exports.default = Ember.Component.extend({
    employee: null,
    availableGroups: null,

    payment: Ember.inject.service(),
    logger: Ember.inject.service(),
    notify: Ember.inject.service(),
    auth: Ember.inject.service(),

    hasSubscription: Ember.computed.readOnly("payment.hasSubscription"),
    roles: Ember.computed.readOnly("auth.roles"),

    init: function init() {
      this._super.apply(this, arguments);
      var employee = this.get("employee");
      this.changeset = new _emberChangeset.default(employee, (0, _emberChangesetValidations.default)(EmployeeValidations), EmployeeValidations);

      // Special handling for array properties to prevent updates of original array until execute()
      var groups = employee.get("groups").toArray();
      this.changeset.set("groups", groups);
    },


    showAccessForeignCalendars: Ember.computed("changeset.role", function () {
      var role = this.get("changeset.role");
      return !(0, _employee.isAccessForeignCalendarsGrantedByRole)(role);
    }),

    showVacationSelfServiceLevel: Ember.computed("changeset.role", function () {
      var role = this.get("changeset.role");
      return !(0, _employee.isVacationSelfServiceGrantedByRole)(role);
    }),

    actions: {
      save: function save() {
        var _this = this;

        var employee = this.get("employee");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              if (_this.get("on-success")) {
                _this.get("on-success")(employee);
              }
            }, function (error) {
              // Remote validation error
              if (!employee.get("isValid")) {
                var modelErrors = employee.get("errors");
                modelErrors.forEach(function (modelError) {
                  changeset.pushErrors(modelError.attribute, modelError.message);
                });
                // Tricky part: reset (existing) model to prevent view of invalid changes, changeset keeps modifications for later save
                if (!employee.get("isNew")) {
                  employee.rollbackAttributes();
                }
              }
              // Other remote error
              else {
                  if ((0, _errorStatus.default)(error) === 413) {
                    changeset.pushErrors("image", "Das hochgeladene Bild überschreitet die maximale Größe von 10 MB");
                  } else {
                    _this.get("logger").catch(error, "Error saving employee", {
                      employeeId: _this.get("employee.id")
                    });
                  }
                }
            });
          }
        });
      },
      cancel: function cancel() {
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      },
      showSubscriptionConfirmation: function showSubscriptionConfirmation(showModal) {
        var _this2 = this;

        this.changeset.validate().then(function () {
          if (_this2.changeset.get("isValid")) {
            showModal();
          }
        });
      }
    }
  });
});