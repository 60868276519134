define("frontend/routes/employees/employee/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      month: {
        replace: true
      },
      year: {
        replace: true
      },
      day: {
        replace: true
      }
    },

    actions: {
      // Inform the controller to disable selection of calendar entries as soon
      // as a transition from this route starts
      willTransition: function willTransition() {
        this.controller.disableSelection();
      },

      // Inform the controller to enable selection (again) if a transition into this route
      // was performed
      didTransition: function didTransition() {
        this.controller.enableSelection();
      }
    }
  });
});