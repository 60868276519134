define("frontend/components/file-upload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    content: null,

    actions: {
      select: function select(event) {
        if (event.target.files.length > 0) {
          this.set("content", event.target.files[0]);
        } else {
          this.set("content", null);
        }
      }
    }
  });
});