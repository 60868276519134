define("frontend/routes/account", ["exports", "frontend/mixins/route/secure"], function (exports, _secure) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_secure.default, {
    auth: Ember.inject.service(),
    ajax: Ember.inject.service(),

    model: function model() {
      var employee = this.get("auth.user.employee");
      return Ember.RSVP.hash({
        calendars: employee ? this.get("ajax").request("/api/employee/" + employee.get("id") + "/calendars").then(function (data) {
          return data.calendars;
        }) : Ember.RSVP.resolve(null)
      });
    }
  });
});