define("frontend/components/user/request-password-reset", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    logger: Ember.inject.service(),

    email: null,
    passwordRequested: false,
    loading: false,
    additionalPasswordRequested: false,
    "on-success": NOOP,

    actions: {
      requestPasswordReset: function requestPasswordReset() {
        var _this = this;

        var data = {
          email: this.get("email")
        };
        this.set("loading", true);
        this.set("additionalPasswordRequested", false);
        this.get("ajax").post("/api/users/request-password-reset", {
          dataType: "text",
          data: data
        }).then(function () {
          if (_this.get("passwordRequested")) {
            _this.set("additionalPasswordRequested", true);
          }
          _this.set("passwordRequested", true);
          _this.incrementProperty("sentEmails");
          _this.get("on-success")();
        }, function (error) {
          _this.get("logger").catch(error, "Error while requesting password reset", { emailAddress: _this.get("email") });
        }).finally(function () {
          _this.set("loading", false);
        });
      },
      reset: function reset() {
        this.set("email", null);
        this.set("passwordRequested", false);
        this.set("additionalPasswordRequested", false);
      }
    }
  });
});