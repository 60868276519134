define("frontend/models/vacation-history-entry", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    changeType: _emberData.default.attr("string"),
    editedBy: _emberData.default.belongsTo("user"),
    editedAt: _emberData.default.attr("date"),
    startChange: _emberData.default.attr("time-change"),
    endChange: _emberData.default.attr("time-change"),
    typeChange: _emberData.default.attr("string-change"),
    commentChange: _emberData.default.attr("string-change")
  });
});