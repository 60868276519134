define("frontend/util/objectid", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.newObjectId = newObjectId;
  /*
   * the following is inspired by:
   * Justin Dearing (zippy1981@gmail.com)
   * https://github.com/justaprogrammer/ObjectId.js/blob/master/src/main/javascript/Objectid.js
   */

  var TODAY = new Date();
  var YEARS = 20;

  var BsonObjectId = function () {
    var inc = Math.floor(Math.random() * 16777216);
    var pid = Math.floor(Math.random() * 65536);
    var machine = Math.floor(Math.random() * 16777216);

    // store a machine code for consistence
    var setMachineCookie = function setMachineCookie() {
      var cookieList = document.cookie.split("; ");
      for (var i in cookieList) {
        var cookie = cookieList[i].split("=");
        var cookieMachineId = parseInt(cookie[1], 10);
        if (cookie[0] == "mongoMachineId" && cookieMachineId && cookieMachineId >= 0 && cookieMachineId <= 16777215) {
          machine = cookieMachineId;
          break;
        }
      }

      var expireDate = new Date(TODAY.setFullYear(TODAY.getFullYear() + YEARS));

      document.cookie = "mongoMachineId=" + machine + ";expires=" + expireDate.toGMTString() + ";path=/";
    };

    if (typeof localStorage != "undefined") {
      try {
        var mongoMachineId = parseInt(localStorage["mongoMachineId"]);
        if (mongoMachineId >= 0 && mongoMachineId <= 16777215) {
          machine = Math.floor(localStorage["mongoMachineId"]);
        }

        localStorage["mongoMachineId"] = machine;
      } catch (e) {
        setMachineCookie();
      }
    } else {
      setMachineCookie();
    }

    function ObjectId() {
      this.timestamp = Math.floor(new Date().valueOf() / 1000);
      this.machine = machine;
      this.pid = pid;
      this.inc = inc++;
      if (inc > 0xffffff) {
        inc = 0;
      }
    }
    return ObjectId;
  }();

  BsonObjectId.prototype.toString = function () {
    if (this.timestamp === undefined || this.machine === undefined || this.pid === undefined || this.inc === undefined) {
      return "Invalid ObjectId";
    }

    var timestamp = this.timestamp.toString(16);
    var machine = this.machine.toString(16);
    var pid = this.pid.toString(16);
    var inc = this.inc.toString(16);

    return "00000000".substr(0, 8 - timestamp.length) + timestamp + "000000".substr(0, 6 - machine.length) + machine + "0000".substr(0, 4 - pid.length) + pid + "000000".substr(0, 6 - inc.length) + inc;
  };

  function newObjectId() {
    return new BsonObjectId();
  }
});