define("frontend/routes/settings/groups/edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    }
  });
});