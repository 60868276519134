define("frontend/components/account/profile-form", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function validateCurrentPassword() {
    return function (key, newValue, oldValue, changes) {
      var valid = newValue || !(changes.email || changes.password);
      if (!valid) {
        return "Required";
      }
      return true;
    };
  }

  var ProfileValidations = {
    email: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    password: (0, _validators.validateLength)({
      min: 8,
      allowBlank: true,
      message: "Password needs at least 8 characters"
    }),
    currentPassword: validateCurrentPassword()
  };

  exports.default = Ember.Component.extend({
    user: null,

    logger: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var user = this.get("user");
      this.changeset = new _emberChangeset.default(user, (0, _emberChangesetValidations.default)(ProfileValidations), ProfileValidations);
    },


    emailOrPasswordChanged: Ember.computed.or("changeset.change.email", "changeset.change.password"),

    actions: {
      save: function save() {
        var _this = this;

        var user = this.get("user");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              if (_this.get("on-success")) {
                _this.get("on-success")(user);
              }
            }, function (error) {
              // Remote validation error
              if (!user.get("isValid")) {
                var modelErrors = user.get("errors");
                modelErrors.forEach(function (modelError) {
                  changeset.pushErrors(modelError.attribute, modelError.message);
                });
                // Tricky part: reset (existing) model to prevent view of invalid changes, changeset keeps modifications for later save
                if (!user.get("isNew")) {
                  user.rollbackAttributes();
                }
              }
              // Other remote error
              else {
                  _this.get("logger").catch(error, "Error saving user", {
                    userId: _this.get("user.id")
                  });
                }
            });
          }
        });
      },
      cancel: function cancel() {
        this.set("user.password", "");
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      }
    }
  });
});