define("frontend/routes/planning/capacity", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        employees: this.store.findAll("employee"),
        groups: this.store.findAll("group")
      });
    }
  });
});