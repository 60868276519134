define("frontend/components/employee/presence-buttons", ["exports", "frontend/models/employee"], function (exports, _employee) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function presenceActionFactory(action, expectedError) {
    return function () {
      var _this = this;

      var promise = this.get("employee")[action]();
      return promise.catch(function (error) {
        if (error === expectedError) {
          _this.send("reload");
          return;
        }
        _this.get("logger").catch(error, "Error performing " + action + " on employee", { employeeId: _this.get("employee.id") });
        throw error;
      });
    };
  }

  exports.default = Ember.Component.extend({
    classNames: ["employee-presence"],

    employee: null,

    auth: Ember.inject.service(),
    logger: Ember.inject.service(),

    paused: Ember.computed("employee.status", function () {
      if (this.get("employee.status") === "paused") {
        return true;
      }
      return false;
    }),

    absent: Ember.computed("employee.status", function () {
      if (this.get("employee.status") === "absent") {
        return true;
      }
      return false;
    }),

    hasPermission: Ember.computed("auth.user.{permissions,employee.id}", "employee.id", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      if (this.get("employee.id") === this.get("auth.user.employee.id")) {
        return permissions.indexOf("permissionSelfPresenceChangeState") >= 0;
      } else {
        return permissions.indexOf("permissionAllPresenceChangeState") >= 0;
      }
    }),

    actions: {
      checkin: presenceActionFactory(_employee.ActionCheckin, _employee.ErrorAlreadyCheckedIn),
      checkout: presenceActionFactory(_employee.ActionCheckout, _employee.ErrorAlreadyCheckedOut),
      pause: presenceActionFactory(_employee.ActionPause, _employee.ErrorNotCheckedInOrAlreadyPaused),
      unpause: presenceActionFactory(_employee.ActionUnpause, _employee.ErrorNotPaused),
      reload: function reload() {
        this.get("employee").reload();
      }
    }
  });
});