define("frontend/components/ui/connection-status", ["exports", "frontend/templates/components/ui/connection-status"], function (exports, _connectionStatus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _connectionStatus.default,
    classNames: ["connection-status"],

    connected: true,
    loading: false,
    loadingDelay: 300,
    offlinePopupDelay: 1000,

    // Pixel offset for popup center
    popupOffset: 12,

    _showLoadingIndicatorTask: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(document).on("ajaxStart", function () {
        _this._showLoadingIndicatorTask = Ember.run.debounce(_this, _this._setLoading, _this.get("loadingDelay"));
      }).on("ajaxStop", function () {
        var task = _this._showLoadingIndicatorTask;
        if (task) {
          Ember.run.cancel(task);
        }
        _this.set("loading", false);
      });
    },
    _setLoading: function _setLoading() {
      this.set("loading", true);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off("ajaxStart").off("ajaxStop");
    },


    connectedDidChange: Ember.observer("connected", function () {
      var _this2 = this;

      if (!this.get("connected")) {
        Ember.run.later(function () {
          _this2.$(".offline.label").popup("show");
        }, this.get("offlinePopupDelay"));
      }
    })
  });
});