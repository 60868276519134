define("frontend/react/components/accounting/Wizard", ["exports", "react", "frontend/react/components/accounting/customer/CustomerFormContainer", "frontend/react/components/accounting/payment/PaymentFormContainer", "frontend/react/components/accounting/subscription/SubscriptionFormContainer"], function (exports, _react, _CustomerFormContainer, _PaymentFormContainer, _SubscriptionFormContainer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Wizard = function Wizard(_ref) {
    var customer = _ref.customer,
        payment = _ref.payment,
        subscription = _ref.subscription,
        subscriptionPreview = _ref.subscriptionPreview,
        fetchSubscriptionPreview = _ref.fetchSubscriptionPreview,
        setCustomer = _ref.setCustomer,
        setPayment = _ref.setPayment,
        setSubscription = _ref.setSubscription;

    var hasCustomer = Boolean(customer.email);
    var hasPaymentMethod = Boolean(payment.paymentMethod);
    var initialState = hasPaymentMethod ? "subscription" : hasCustomer ? "payment" : "customer";

    var _useState = (0, _react.useState)(initialState),
        _useState2 = _slicedToArray(_useState, 2),
        step = _useState2[0],
        setStep = _useState2[1];

    return React.createElement(
      "div",
      null,
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "h5",
          null,
          "Abonnement abschlie\xDFen"
        )
      ),
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "div",
          { className: "ui three tiny top attached steps" },
          step == "customer" ? React.createElement(
            "div",
            { className: "step active" },
            React.createElement("i", { className: "address card outline icon" }),
            React.createElement(
              "div",
              { className: "content" },
              React.createElement(
                "div",
                { className: "title" },
                "Rechnungsdaten"
              )
            )
          ) : React.createElement(
            "a",
            { className: "step", onClick: function onClick() {
                return setStep("customer");
              } },
            React.createElement("i", { className: "address card outline icon" }),
            React.createElement(
              "div",
              { className: "content" },
              React.createElement(
                "div",
                { className: "title" },
                "Rechnungsdaten"
              )
            )
          ),
          step === "payment" || step === "customer" ? React.createElement(
            "div",
            { className: ["step", step === "payment" && "active"].join(" ") },
            React.createElement("i", { className: "payment icon" }),
            React.createElement(
              "div",
              { className: "content" },
              React.createElement(
                "div",
                { className: "title" },
                "Bezahlung"
              )
            )
          ) : React.createElement(
            "a",
            { className: "step", onClick: function onClick() {
                return setStep("payment");
              } },
            React.createElement("i", { className: "payment icon" }),
            React.createElement(
              "div",
              { className: "content" },
              React.createElement(
                "div",
                { className: "title" },
                "Bezahlung"
              )
            )
          ),
          React.createElement(
            "div",
            {
              className: ["step", step === "subscription" && "active"].join(" ")
            },
            React.createElement("i", { className: "handshake outline icon" }),
            React.createElement(
              "div",
              { className: "content" },
              React.createElement(
                "div",
                { className: "title" },
                "Buchung"
              )
            )
          )
        ),
        React.createElement(
          "div",
          { className: "ui attached segment" },
          step === "customer" && React.createElement(_CustomerFormContainer.default, {
            isWizard: true,
            customer: customer,
            onUpdated: function onUpdated(customer) {
              setCustomer(customer);
              fetchSubscriptionPreview();
              setStep("payment");
            }
          }),
          step === "payment" && React.createElement(_PaymentFormContainer.default, {
            isWizard: true,
            customer: customer,
            payment: payment,
            onUpdated: function onUpdated(payment) {
              setPayment(payment);
              setStep("subscription");
            }
          }),
          step === "subscription" && React.createElement(_SubscriptionFormContainer.default, {
            subscription: subscription,
            isWizard: true,
            subscriptionPreview: subscriptionPreview,
            onUpdated: function onUpdated(subscription) {
              setSubscription(subscription);
            }
          })
        )
      )
    );
  };

  exports.default = Wizard;
});