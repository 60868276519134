define("frontend/components/dashboard/daily-overview", ["exports", "frontend/util/dashboard/timeconversions", "frontend/util/timewatcher", "frontend/mixins/pending-presence-calculation"], function (exports, _timeconversions, _timewatcher, _pendingPresenceCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var twelveHoursInSeconds = 42780;
  var secondStrokeDashOffset = 5;
  var secondStrokeOffset = 120;
  var fullProgressCircleUnits = 720; // 12 hours in minutes
  var fullPauseCircleUnits = 180; // 3 hours in minutes

  exports.default = Ember.Component.extend(_pendingPresenceCalculation.default, {
    classNames: ["daily-overview"],

    // Service for Mixin dependency
    clock: Ember.inject.service(),

    employee: null,
    didAbort: null,
    summaryOnDay: null,

    showTooltip: false,

    tooltipData: null,

    /* ----- PROGRESS dasharray calc ------ */
    // You can adjust the size of the circle just by changing progressSize. Integers represent Size in Pixels
    progressSize: 208,
    strokeWidth: 25,

    cxcy: Ember.computed("progressSize", function () {
      return this.get("progressSize") / 2;
    }),
    svgSize: Ember.computed("progressSize", function () {
      return this.get("progressSize") + 2;
    }),
    radius: Ember.computed("progressSize", function () {
      return this.get("progressSize") / 2 - this.get("strokeWidth") / 2;
    }),
    dashArray: Ember.computed("radius", function () {
      return 2 * Math.PI * this.get("radius");
    }),
    lengthOfDash: Ember.computed("dashArray", function () {
      return this.get("dashArray") / fullProgressCircleUnits;
    }),
    progressDashOffset: Ember.computed("dashArray", function () {
      return this.get("dashArray") / 4;
    }),

    /* ------ PAUSEBAR dasharray calc ------*/
    pauseSize: 146,
    pauseWidth: 10,

    // ** DO NOT EDIT ANY OF THE BELOW DATA

    pauseRadius: Ember.computed("pauseSize", "pauseWidth", function () {
      return this.get("pauseSize") / 2 - this.get("pauseWidth") / 2;
    }),
    pauseDashArray: Ember.computed("pauseRadius", function () {
      return 2 * Math.PI * this.get("pauseRadius");
    }),
    pauseLengthOfDash: Ember.computed("pauseDashArray", function () {
      return this.get("pauseDashArray") / fullPauseCircleUnits;
    }),
    pauseDashOffset: Ember.computed("pauseDashArray", function () {
      return this.get("pauseDashArray") / 4;
    }),

    /* ----- Logical Operators ---- */
    targetReached: Ember.computed("totalWorkDuration", "pendingTargetWorkDuration", "didAbort", function () {
      return (0, _timewatcher.targetTimeReached)(this.get("pendingTargetWorkDuration"), this.get("totalWorkDuration"), this.get("didAbort"));
    }),

    // Calculation of dash and when to use which
    progressDash: Ember.computed("totalWorkDuration", "pendingTargetWorkDuration", "dashArray", "lengthOfDash", function () {
      var targetWorkDuration = this.get("pendingTargetWorkDuration");
      var hasTargetWorkDuration = targetWorkDuration > 0;
      var workDuration = this.get("totalWorkDuration") > targetWorkDuration && hasTargetWorkDuration ? targetWorkDuration : this.get("totalWorkDuration");

      return (0, _timeconversions.convertTimeToDash)(workDuration, this.get("lengthOfDash"), this.get("dashArray"));
    }),

    progressDash2: Ember.computed("totalWorkDuration", "targetReached", "pendingTargetWorkDuration", "dashArray", "lengthOfDash", function () {
      if (this.get("targetReached")) {
        var twelveHourMark = twelveHoursInSeconds - this.get("pendingTargetWorkDuration");
        var workDuration = this.get("totalWorkDuration") > twelveHoursInSeconds ? twelveHourMark - secondStrokeOffset : this.get("totalWorkDuration") - this.get("pendingTargetWorkDuration");

        return (0, _timeconversions.convertTimeToDash)(workDuration, this.get("lengthOfDash"), this.get("dashArray"));
      } else {
        return "0 " + this.get("dashArray");
      }
    }),

    /* ------ Styling Operators ------*/
    // Progressbar 1 up until targetReached === true
    pBarStyle: Ember.computed("progressDashOffset", "strokeWidth", function () {
      return Ember.String.htmlSafe("stroke-width:" + this.get("strokeWidth") + ";\n      stroke-dashoffset:" + this.get("progressDashOffset") + ";");
    }),

    // Progressbar 2 up from when targetReached === true
    pBarStyle2: Ember.computed("pendingTargetWorkDuration", "targetReached", "strokeWidth", "lengthOfDash", "progressDashOffset", function () {
      var offset = fullProgressCircleUnits - ((0, _timeconversions.convertSecondsToMinutes)(this.get("pendingTargetWorkDuration")) * this.get("lengthOfDash") + secondStrokeDashOffset);

      if (!this.get("targetReached")) {
        return Ember.String.htmlSafe("display: none;");
      } else if (this.get("pendingTargetWorkDuration") <= 0) {
        return Ember.String.htmlSafe("stroke-width: " + this.get("strokeWidth") + ";\n          stroke-dashoffset: " + this.get("progressDashOffset") + ";");
      }

      return Ember.String.htmlSafe("stroke-width: " + this.get("strokeWidth") + ";\n        stroke-dashoffset: " + offset + ";");
    }),

    pBarText: Ember.computed("progressSize", function () {
      return Ember.String.htmlSafe("width:" + this.get("progressSize") + "px;\n      height:" + this.get("progressSize") + "px;\n      line-height:" + this.get("progressSize") + "px;");
    }),

    // TODO: Enable color changing features only if option is activated
    progressTextColor: Ember.computed("employee.status", "summaryOnDay.vacation", "totalWorkDuration", function () {
      var hasWorkedOverTen = (0, _timewatcher.workingOverTen)(this.get("totalWorkDuration"));
      var status = this.get("employee.status");
      var vacation = this.get("summaryOnDay.vacation");

      if (hasWorkedOverTen && status !== "absent" && !vacation) {
        return "red";
      } else if (!hasWorkedOverTen && status !== "present" || vacation && status !== "present") {
        return "grey";
      }

      return "black";
    }),

    progressStrokeColor: Ember.computed("employee.{status,presence.isCheckinToday}", "totalWorkDuration", "didAbort", "summaryOnDay.vacation", function () {
      var hasWorkedOverTen = (0, _timewatcher.workingOverTen)(this.get("totalWorkDuration"));
      var status = this.get("employee.status");
      var isCheckinToday = this.get("employee.presence.isCheckinToday");

      if (!this.get("summaryOnDay.vacation") && hasWorkedOverTen || this.get("didAbort")) {
        return "red";
      } else if (this.get("summaryOnDay.vacation") || status !== "present" && isCheckinToday && !hasWorkedOverTen) {
        return "grey";
      } else if (status === "absent" && isCheckinToday === false) {
        return "no-visibility";
      }

      return "maincolor";
    }),

    pauseTextColor: Ember.computed("employee.{status,presence.isCheckinToday}", "totalPauseDuration", "totalWorkDuration", "summaryOnDay.vacation", function () {
      var enoughPauseTime = (0, _timewatcher.enoughPause)(this.get("totalPauseDuration"), this.get("totalWorkDuration"));
      var status = this.get("employee.status");
      var isCheckinToday = this.get("employee.presence.isCheckinToday");

      if (status === "absent" && !isCheckinToday) {
        return "no-visibility";
      } else if (!enoughPauseTime && status !== "paused" && !this.get("summaryOnDay.vacation")) {
        return "red";
      } else if (status === "paused") {
        return "black";
      }
      return "grey";
    }),

    actions: {
      closeTooltip: function closeTooltip() {
        this.set("showTooltip", false);
        this.set("tooltipData", null);
      },
      showTooltip: function showTooltip(context, direction) {
        var data = {
          presence: this.get("employee.presence"),
          status: this.get("employee.status"),
          targetWork: this.get("pendingTargetWorkDuration"),
          totalWork: this.get("totalWorkDuration"),
          targetPause: this.get("pendingTargetPauseDuration"),
          totalPause: this.get("totalPauseDuration"),
          didAbort: this.get("didAbort"),
          isVacation: !!this.get("summaryOnDay.vacation"),
          vacationType: this.get("summaryOnDay.vacation") && this.get("summaryOnDay.vacation.type"),
          isHoliday: this.get("summaryOnDay.holiday"),
          holidayTitle: this.get("summaryOnDay.holidayTitle"),
          context: context,
          direction: direction
        };

        this.set("showTooltip", true);
        this.set("tooltipData", data);
      }
    }
  });
});