define("frontend/services/debugger", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    auth: Ember.inject.service(),

    isActive: false,
    maxLogLength: 1000,
    messages: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("messages", []);
    },
    logMessage: function logMessage(message) {
      var messages = this.get("messages");
      messages.unshift(this.buildLog(message));
      this.set("messages", messages.slice(0, this.get("maxLogLength")));
    },
    buildLog: function buildLog(m) {
      var date = new Date();
      if (m.employee) {
        return date.toISOString() + ", OrganisationId: " + m.organisationId + ", EmployeeId: " + m.employee.id + ", ClientId: " + this.get("auth.clientId") + ", Event: " + m.event;
      }

      return date.toISOString() + m;
    }
  });
});