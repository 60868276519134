define("frontend/util/features", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.APP_FEATURES = undefined;
  exports.default = isEnabled;
  var APP_FEATURES = exports.APP_FEATURES = _environment.default.APP.FEATURES; // jshint ignore:line

  function isEnabled(feature) {
    var featureValue = APP_FEATURES[feature];

    if (featureValue === true) {
      return true;
    } else {
      return false;
    }
  }
});