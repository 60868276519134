define("frontend/components/employee/vacation-history/details", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    vacation: null,

    history: Ember.computed.readOnly("vacation.history")
  });
});