define("frontend/components/ui/labeled-button", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    tagName: "button",
    classNames: ["ui", "left", "labeled", "button"],
    // TODO Add title  title="Änderungsverlauf ansehen"

    onClick: NOOP,

    click: function click() {
      this.get("onClick")();
    }
  });
});