define("frontend/router", ["exports", "frontend/config/environment", "frontend/util/features"], function (exports, _environment, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    if ((0, _features.default)("registration")) {
      this.route("signup");
    }

    this.route("login");

    this.route("status");

    this.route("employees", function () {
      this.route("new");
      this.route("employee", { path: ":employee_id" }, function () {
        this.route("edit");
      });
      this.route("archive");
    });

    this.route("account", function () {
      this.route("edit");
    });

    if ((0, _features.default)("dashboard")) {
      this.route("dashboard", function () {});
    }

    this.route("settings", function () {
      this.route("groups", function () {
        this.route("new");
        this.route("edit", { path: ":group_id/edit" });
      });
      if ((0, _features.default)("custom-status")) {
        this.route("status-definitions", function () {
          this.route("new");
          // eslint-disable-next-line ember/routes-segments-snake-case
          this.route("edit", { path: ":status-definition_id/edit" });
        });
      }
      if ((0, _features.default)("payment")) {
        this.route("accounting", function () {
          this.route("invoices");
        });
      }
      this.route("organisation", function () {});
    });

    this.route("reset-password");
    this.route("user", function () {
      this.route("password-reset", { path: "password-reset/:token" });
    });

    this.route("reports", function () {
      this.route("monthly");
    });

    this.route("planning", function () {
      this.route("capacity");
    });

    this.route("not-found", { path: "/*path" });
  });

  exports.default = Router;
});