define("frontend/helpers/time-duration", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.timeDuration = timeDuration;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function convertSecondsToDecimal(duration) {
    var seconds = duration || 0;
    return seconds / 3600;
  }

  function convertSecondsToDigits(duration) {
    var sign = 1;
    if (duration < 0) {
      sign = -1;
      duration = duration * -1;
    }
    var hours = Math.floor(duration / 3600),
        minutes = Math.floor(duration % 3600 / 60),
        seconds = duration % 60;
    return (sign === -1 ? "-" : "") + hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
  }

  function convertSecondsToDigitsMinute(duration) {
    var sign = 1;
    if (duration < 0) {
      sign = -1;
      duration = duration * -1;
    }
    var hours = Math.floor(duration / 3600),
        minutes = Math.floor(duration % 3600 / 60);
    return (sign === -1 ? "-" : "") + hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
  }

  function timeDuration(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        duration = _ref2[0];

    if (options && options.style === "digits") {
      return convertSecondsToDigits(duration);
    } else if (options && options.style === "digits-minute") {
      return convertSecondsToDigitsMinute(duration);
    }
    return convertSecondsToDecimal(duration);
  }

  exports.default = Ember.Helper.helper(timeDuration);
});