define("frontend/routes/signup", ["exports", "frontend/models/signup", "frontend/util/matomo-tracking"], function (exports, _signup, _matomoTracking) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    notify: Ember.inject.service(),
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get("auth.authenticated")) {
        this.get("notify").alert("Bitte Abmelden für eine erneute Registrierung");
        this.transitionTo("index");
      }
    },
    model: function model() {
      return _signup.default.create();
    },
    setupController: function setupController(controller, model) {
      controller.setup(model);
    },
    activate: function activate() {
      (0, _matomoTracking.default)("Signup");
      document.body.classList.add("public");
    },
    deactivate: function deactivate() {
      document.body.classList.remove("public");
    }
  });
});