define("frontend/controllers/login", ["exports", "frontend/util/error-status"], function (exports, _errorStatus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoginController = Ember.Controller.extend({
    email: null,
    password: null,

    error: false,

    auth: Ember.inject.service(),
    ajax: Ember.inject.service(),
    logger: Ember.inject.service(),

    actions: {
      login: function login() {
        var _this = this;

        var email = this.get("email"),
            password = this.get("password");
        this.get("ajax").post("/api/login", {
          data: {
            email: email,
            password: password,
            context: "webapp"
          }
        }).then(function (loginData) {
          _this.set("error", false);

          _this.set("auth.token", loginData.token);
          _this.set("auth.userId", loginData.id);

          _this.send("authenticated");
        }, function (error) {
          var status = (0, _errorStatus.default)(error);
          if (status === 401) {
            _this.set("error", true);
          } else {
            _this.get("logger").catch(error, "Error performing login");
          }
        });
      }
    },

    reset: function reset() {
      this.setProperties({
        email: null,
        password: null,
        error: false
      });
    }
  });
  exports.default = LoginController;
});