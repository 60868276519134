define("frontend/components/ui/async-button", ["exports", "frontend/templates/components/ui/async-button"], function (exports, _asyncButton) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var positionalParamsMixin = {
    positionalParams: "params"
  };

  var AsyncButtonComponent = Ember.Component.extend(positionalParamsMixin, {
    layout: _asyncButton.default,
    tagName: "button",
    textState: "default",
    asyncState: Ember.computed.alias("default"),
    reset: false,
    classNames: ["async-button"],
    classNameBindings: ["textState"],
    attributeBindings: ["disabled", "type", "_href:href", "tabindex"],

    type: "submit",
    disabled: Ember.computed("textState", "disableWhen", function () {
      var textState = Ember.get(this, "textState");
      var disableWhen = Ember.get(this, "disableWhen");
      return disableWhen || textState === "pending";
    }),

    click: function click() {
      var _this = this;

      var params = Ember.getWithDefault(this, "params", []);
      var callbackHandler = function callbackHandler(promise) {
        Ember.set(_this, "promise", promise);
      };

      var action = Ember.get(this, "action");
      if (typeof action === "function") {
        var deprecatingCallbackHandler = function deprecatingCallbackHandler(promise) {
          Ember.deprecate("When using closure style actions with ember-async-button,\nplease return the promise instead of using the callback argument.\nThe callback for closure actions will be removed in future versions.", false, { id: "ember-async-button.action-callback", until: "0.8.0" });

          callbackHandler(promise);
        };

        var promise = action.apply(undefined, [deprecatingCallbackHandler].concat(_toConsumableArray(params)));

        if (promise && typeof promise.then === "function") {
          callbackHandler(promise);
        }
      } else {
        var actionArguments = ["action", callbackHandler].concat(_toConsumableArray(params));

        // eslint-disable-next-line ember/closure-actions
        this.sendAction.apply(this, _toConsumableArray(actionArguments));
      }

      Ember.set(this, "textState", "pending");

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    },


    text: Ember.computed("textState", "default", "pending", "resolved", "fulfilled", "rejected", function () {
      return Ember.getWithDefault(this, this.textState, Ember.get(this, "default"));
    }),

    resetObserver: Ember.observer("textState", "reset", function () {
      var states = ["resolved", "rejected", "fulfilled"];
      var found = false;
      var textState = Ember.get(this, "textState");

      for (var idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }

      if (Ember.get(this, "reset") && found) {
        Ember.set(this, "textState", "default");
      }
    }),

    handleActionPromise: Ember.observer("promise", function () {
      var _this2 = this;

      var promise = Ember.get(this, "promise");

      if (!promise) {
        return;
      }

      promise.then(function () {
        if (!_this2.isDestroyed) {
          Ember.set(_this2, "textState", "fulfilled");
        }
      }).catch(function () {
        if (!_this2.isDestroyed) {
          Ember.set(_this2, "textState", "rejected");
        }
      });
    }),

    setUnknownProperty: function setUnknownProperty(key, value) {
      if (key === "resolved") {
        Ember.deprecate("The 'resolved' property is deprecated. Please use 'fulfilled'", false);
        key = "fulfilled";
      }

      this[key] = null;
      this.set(key, value);
    },


    _href: Ember.computed("href", function () {
      var href = Ember.get(this, "href");
      if (href) {
        return href;
      }

      var tagName = Ember.get(this, "tagName").toLowerCase();
      if (tagName === "a" && href === undefined) {
        return "";
      }
    }),

    _stateObject: Ember.computed("textState", function () {
      var textState = Ember.get(this, "textState");
      var isFulfilled = textState === "fulfilled" || textState === "resolved";
      return {
        isPending: textState === "pending",
        isFulfilled: isFulfilled,
        isResolved: isFulfilled,
        isRejected: textState === "rejected",
        isDefault: textState === "default"
      };
    })
  });

  // Ember 1.13.6 will deprecate specifying `positionalParams` on the
  // instance in favor of class level property
  //
  // Having both defined keeps us compatible with Ember 1.13+ (all patch versions)
  AsyncButtonComponent.reopenClass(positionalParamsMixin);

  exports.default = AsyncButtonComponent.extend({
    classNameBindings: ["loading"],

    loading: Ember.computed.equal("textState", "pending")
  });
});