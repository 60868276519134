define("frontend/components/ui/main-menu", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),

    permissions: Ember.computed.readOnly("auth.user.permissions"),

    classNames: ["ui", "secondary", "pointing", "menu"]
  });
});