define("frontend/services/auth", ["exports", "@sentry/browser", "frontend/config/environment", "frontend/util/uuid"], function (exports, _browser, _environment, _uuid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    clientId: null,

    store: Ember.inject.service("store"),

    authenticated: Ember.computed.notEmpty("token"),

    createClientId: Ember.on("init", function () {
      this.set("clientId", (0, _uuid.default)());
    }),

    token: Ember.computed({
      get: function get() {
        var token = localStorage.getItem("token");
        return token;
      },
      set: function set(key, value) {
        if (value) {
          localStorage.setItem("token", value);
        } else {
          localStorage.removeItem("token");
        }
        return value;
      }
    }),

    userId: Ember.computed({
      get: function get() {
        var userId = localStorage.getItem("userId");
        return userId;
      },
      set: function set(key, value) {
        if (value) {
          _browser.configureScope(function (scope) {
            scope.setUser({ id: value });
          });
          localStorage.setItem("userId", value);
        } else {
          _browser.configureScope(function (scope) {
            scope.setUser({ id: null });
          });
          localStorage.removeItem("userId");
        }
        return value;
      }
    }),

    user: Ember.computed("userId", function () {
      if (this.get("userId") !== null) {
        return this.get("store").findRecord("user", this.get("userId"));
      }
      return Ember.RSVP.resolve(null);
    }),

    roles: Ember.computed(function () {
      return ["readOnly", "booking", "bookingWithCorrection", "personnelManagement", "administration"];
    }),

    ensureRestrictedCookie: function ensureRestrictedCookie() {
      if (this.get("authenticated")) {
        if (document.cookie.indexOf("access_key") === -1) {
          var cookieValue = "access_key=" + this.get("token");
          if (_environment.default.apiUrlHost) {
            var url = _environment.default.apiUrlHost;

            var _url$match = url.match(/.*:\/\/([^/]*)/),
                _url$match2 = _slicedToArray(_url$match, 2),
                domain = _url$match2[1];

            cookieValue += ";domain=" + domain;
          }
          document.cookie = cookieValue;
        }
      }
    },
    removeRestrictedCookie: function removeRestrictedCookie() {
      document.cookie = "access_key=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    },
    reset: function reset() {
      this.removeRestrictedCookie();

      this.set("token", null);
      this.set("userId", null);
      this.set("clientId", (0, _uuid.default)());
    }
  });
});