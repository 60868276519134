define("frontend/controllers/employees/employee/edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    availableGroups: null,
    isNewEmployee: false,

    notify: Ember.inject.service(),
    router: Ember.inject.service(),

    indexController: Ember.inject.controller("employees.employee.index"),

    actions: {
      success: function success(employee) {
        this.get("notify").success("Personal aktualisiert");
        this.get("router").transitionTo("employees.employee", employee);
      },
      cancel: function cancel() {
        this.get("router").transitionTo("employees.employee", this.get("model"));
      },
      refreshIndex: function refreshIndex() {
        this.get("indexController").refresh();
      }
    }
  });
});