define("frontend/routes/login", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get("auth.authenticated")) {
        this.transitionTo("index");
      }
    },
    setupController: function setupController(controller) {
      controller.reset();
    },
    activate: function activate() {
      document.body.classList.add("public");
    },
    deactivate: function deactivate() {
      document.body.classList.remove("public");
    }
  });
});