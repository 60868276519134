define("frontend/util/timewatcher", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.workingOverTen = workingOverTen;
  exports.workingOverNine = workingOverNine;
  exports.workingOverSix = workingOverSix;
  exports.pauseOver30 = pauseOver30;
  exports.pauseOver45 = pauseOver45;
  exports.targetTimeReached = targetTimeReached;
  exports.enoughPause = enoughPause;
  exports.targetPauseDurationByWorkDuration = targetPauseDurationByWorkDuration;
  /*
   * Controlling if German "Arbeitszeitgesetz" (ArbZG) is being fulfilled
   *
   */
  function workingOverTen(seconds) {
    if (seconds >= 36000) {
      return true;
    }
    return false;
  }

  function workingOverNine(seconds) {
    if (seconds >= 32400) {
      return true;
    }
    return false;
  }

  function workingOverSix(seconds) {
    if (seconds >= 21600) {
      return true;
    }
    return false;
  }

  function pauseOver30(seconds) {
    if (seconds >= 1800) {
      return true;
    }
    return false;
  }

  function pauseOver45(seconds) {
    if (seconds >= 2700) {
      return true;
    }
    return false;
  }

  function targetTimeReached(targetTime, duration) {
    var aborted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (targetTime > 0 && duration >= targetTime) {
      return true;
    }
    if (aborted) {
      return true;
    }
    return false;
  }

  function enoughPause(pauseDuration, workDuration) {
    if (workingOverSix(workDuration) && !workingOverNine(workDuration) && !pauseOver30(pauseDuration) || workingOverNine(workDuration) && !pauseOver45(pauseDuration)) {
      return false;
    }
    return true;
  }

  function targetPauseDurationByWorkDuration(seconds) {
    // Between 6 and 9 hours a pause of 30 minutes is required
    if (workingOverSix(seconds) && !workingOverNine(seconds)) {
      return 1800;
    }
    // After 9 hours a pause of 45 minutes is required
    else if (workingOverNine(seconds)) {
        return 2700;
      } else return 0;
  }
});