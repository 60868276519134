define("frontend/react/components/form/SelectField", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var SelectField = function SelectField(_ref) {
    var name = _ref.name,
        value = _ref.value,
        options = _ref.options,
        onUpdate = _ref.onUpdate,
        error = _ref.error,
        label = _ref.label,
        placeholder = _ref.placeholder,
        required = _ref.required,
        width = _ref.width,
        fieldProps = _objectWithoutProperties(_ref, ["name", "value", "options", "onUpdate", "error", "label", "placeholder", "required", "width"]);

    var classes = ["field", "wide"];

    var handleUpdate = function handleUpdate(evt) {
      onUpdate(name, evt.target.value);
    };

    required && classes.push("required");
    width && classes.push(width);
    error && classes.push("error");

    return React.createElement(
      "div",
      { className: classes.join(" ") },
      React.createElement(
        "label",
        null,
        label
      ),
      React.createElement(
        "select",
        _extends({
          value: value,
          className: "ui fluid dropdown",
          onChange: handleUpdate
        }, fieldProps),
        placeholder && React.createElement(
          "option",
          { value: "", disabled: true },
          placeholder
        ),
        options.map(function (_ref2) {
          var value = _ref2.value,
              label = _ref2.label;
          return React.createElement(
            "option",
            { key: value, value: value },
            label
          );
        })
      ),
      error && React.createElement(
        "div",
        { className: "ui pointing red label" },
        error
      )
    );
  };

  exports.default = SelectField;
});