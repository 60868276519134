define("frontend/controllers/user/password-reset", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      success: function success() {
        this.get("notify").info("Passwort geändert, Sie können sich jetzt mit dem neuen Passwort anmelden");
        this.get('router').transitionTo("login");
      }
    }
  });
});