define("frontend/components/ui/input-working-durations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    classNames: ["seven", "fields"],
    classNameBindings: ["error"],
    error: false,
    "on-change": NOOP,

    value: Ember.computed({
      // Get a default or cloned array
      get: function get() {
        var mutableValue = this.get("mutableValue") || [0, 0, 0, 0, 0, 0, 0];
        var clonedArray = mutableValue.map(function (val) {
          return val;
        });
        return clonedArray;
      },

      // Set a cloned array as a mutable value (bound to input)
      set: function set(_, value) {
        var clonedArray = value.map(function (val) {
          return val;
        });
        this.set("mutableValue", clonedArray);
        return clonedArray;
      }
    }),
    mutableValue: null,

    secondsToHours: function secondsToHours(value, reverse) {
      var ONE_HOUR_SECONDS = 3600;

      if (reverse) {
        // seconds are being rounded, for seconds can only be of type INT
        return Math.round(value * ONE_HOUR_SECONDS);
      }
      return value / ONE_HOUR_SECONDS;
    },

    actions: {
      // On change of each input, the complete working durations array will be sent as changed
      "on-change": function onChange() {
        this["on-change"](this.get("value"));
      }
    }
  });
});