define("frontend/services/ajax", ["exports", "ember-ajax/services/ajax", "frontend/config/environment"], function (exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    auth: Ember.inject.service(),

    host: _environment.default.apiHost,

    headers: Ember.computed("auth.{token,clientId}", function () {
      var headers = {
        "X-Client-Id": this.get("auth.clientId")
      };
      var token = this.get("auth.token");
      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }
      return headers;
    })
  });
});