define("frontend/adapters/employment", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function buildUrlWithId(id, modelName, snapshot) {
    var url = [];
    var host = this.get("host");
    var prefix = this.urlPrefix();

    url.push("employee/" + encodeURIComponent(snapshot.attr("employeeId")) + "/employment");

    if (id) {
      url.push(encodeURIComponent(id));
    }
    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join("/");
    if (!host && url && url.charAt(0) !== "/") {
      url = "/" + url;
    }

    return url;
  } /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  exports.default = _application.default.extend({
    paths: {
      single: "employment",
      collection: "employments"
    },

    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var url = [];
      var host = this.get("host");
      var prefix = this.urlPrefix();

      url.push("employee/" + encodeURIComponent(snapshot.attr("employeeId")) + "/employments");

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join("/");
      if (!host && url && url.charAt(0) !== "/") {
        url = "/" + url;
      }

      return url;
    },

    urlForUpdateRecord: buildUrlWithId,
    urlForDeleteRecord: buildUrlWithId
  });
});