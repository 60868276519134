define("frontend/mixins/form-data-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ["POST", "PUT", "PATCH"],

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && "data" in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData !== "undefined" && data && this.formDataTypes.includes(type)) {
        var formData = new FormData();

        Object.keys(data).forEach(function (key) {
          if (typeof data[key] !== "undefined") {
            if (Ember.isArray(data[key])) {
              Ember.A(data[key]).forEach(function (item) {
                formData.append(key, item);
              });
            } else {
              formData.append(key, data[key]);
            }
          }
        });

        hash.processData = false;
        hash.contentType = false;
        hash.data = formData;
      }

      return hash;
    }
  });
});