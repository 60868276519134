define("frontend/services/stream", ["exports", "frontend/util/log", "frontend/config/environment"], function (exports, _log, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function wsUrlPrefixFromLocation() {
    var _window$location = window.location,
        protocol = _window$location.protocol,
        host = _window$location.host;

    var websocketProtocol = protocol === "https:" ? "wss:" : "ws:";
    return websocketProtocol + "//" + host;
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    auth: Ember.inject.service(),
    socketService: Ember.inject.service("websockets"),

    debugService: Ember.inject.service("debugger"),

    connected: false,

    socketRef: null,
    intervalId: null,

    reconnectDelay: 500,
    maximumReconnectDelay: 30000,
    isReconnect: false,

    listen: function listen() {
      var wsUrlPrefix = _environment.default.wsUrlPrefix || wsUrlPrefixFromLocation();

      if (!_environment.default.wsEnabled || !window.WebSocket) {
        this.set("connected", true);
        return;
      }

      if (this.get("connected")) {
        // Alread connected
        return;
      }

      this.get("auth").ensureRestrictedCookie();

      var socket = this.get("socketService").socketFor(wsUrlPrefix + "/api/stream?client_id=" + this.get("auth.clientId"));

      socket.on("open", this._socketOpened, this);
      socket.on("message", this._handleMessage, this);
      socket.on("close", this._socketClosed, this);

      _log.default.debug("Stream listening");
      var debug = this.get("debugService");
      if (debug.isActive) {
        debug.logMessage("Stream listening");
      }

      this.set("socketRef", socket);
    },
    _socketOpened: function _socketOpened() {
      var debug = this.get("debugService");
      if (this.get("isReconnect")) {
        _log.default.debug("Stream socket reconnected");
        if (debug.isActive) {
          debug.logMessage("Stream socket reconnected");
        }
        this.trigger("reconnected");
      } else {
        _log.default.debug("Stream socket opened");
        if (debug.isActive) {
          debug.logMessage("Stream socket opened");
        }
      }

      this.set("connected", true);
      this.set("reconnectDelay", 500);

      this._startPinger();
    },
    _handleMessage: function _handleMessage(e) {
      var message = JSON.parse(e.data);

      var debug = this.get("debugService");
      if (debug.isActive) {
        debug.logMessage(message);
      }

      // Directly update employees in store, since the stream message contains all data
      if (message.type === "employee" && message.id) {
        _log.default.debug("Stream socket employee message", message);

        var employee = this.get("store").peekRecord("employee", message.id);
        if (message.event === "Delete") {
          if (employee && !employee.get("isDeleted")) {
            this.get("store").unloadRecord(employee);
          }
        } else {
          if (employee && employee.get("isSaving")) {
            return;
          }
          this.get("store").pushPayload("employee", {
            employee: message.employee
          });
        }
      }

      this.trigger("received", message);
    },
    _socketClosed: function _socketClosed() {
      var connected = this.get("connected");

      _log.default.debug("Stream socket closed");
      var debug = this.get("debugService");
      if (debug.isActive) {
        debug.logMessage("Stream socket closed");
      }

      this.set("connected", false);
      this._stopPinger();
      this._reconnectWithDecay();

      this.trigger("closed", { disconnect: connected });
    },
    stop: function stop() {
      var socket = this.get("socketRef");

      if (!socket) {
        return;
      }

      socket.off("open", this._socketOpened);
      socket.off("message", this._handleMessage);
      socket.off("close", this._socketClosed);

      _log.default.debug("Stream stopped");
    },
    _startPinger: function _startPinger() {
      var _this = this;

      if (!this.get("intervalId")) {
        var intervalId = setInterval(function () {
          var socket = _this.get("socketRef");
          socket.send(JSON.stringify({
            type: "Ping"
          }));
        }, 20000);
        this.set("intervalId", intervalId);
      }
    },
    _stopPinger: function _stopPinger() {
      var intervalId = this.get("intervalId");
      if (intervalId) {
        clearInterval(intervalId);
        this.set("intervalId", null);
      }
    },
    _reconnectWithDecay: function _reconnectWithDecay() {
      var _this2 = this;

      var reconnectDelay = this.get("reconnectDelay");

      _log.default.debug("Stream socket reconnect", reconnectDelay);
      var debug = this.get("debugService");
      if (debug.isActive) {
        debug.logMessage("Stream socket reconnect");
      }

      Ember.run.later(this, function () {
        var socket = _this2.get("socketRef");
        _this2.set("isReconnect", true);
        socket.reconnect();
      }, reconnectDelay);

      reconnectDelay = Math.min(reconnectDelay * 1.5, this.get("maximumReconnectDelay"));
      this.set("reconnectDelay", reconnectDelay);
    }
  });
});