define("frontend/components/dashboard/time-difference", ["exports", "frontend/mixins/presence-statistics-calculation"], function (exports, _presenceStatisticsCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_presenceStatisticsCalculation.default, {
    classNames: ["ui", "three", "column", "grid", "time-difference"],
    monthlyPresenceReport: null
  });
});