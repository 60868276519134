define("frontend/components/employee/vacation-statistics", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    classNames: ["ui", "segment", "vacation-statistics"],
    classNameBindings: ["loading", "basic", "compact", "centered", "noborder"],

    basic: true,
    compact: false,
    loading: Ember.computed.readOnly("vacationReport.isPending"),
    noborder: Ember.computed("hasActiveEmployment", function () {
      return !this.get("hasActiveEmployment");
    }),
    intl: Ember.inject.service(),

    hasActiveEmployment: Ember.computed("vacationReport.report", function () {
      var report = this.get("vacationReport.report");
      return report && (!!report.takenVacations || report.entitlement > 0);
    }),

    remainingUntil: Ember.computed("vacationReport.report.remainingUntil", function () {
      return (0, _moment.default)(this.get("vacationReport.report.remainingUntil")).utc();
    }),

    availableRemainingDays: Ember.computed("vacationReport.report.{remainingDays,remainingUntil,takenFromRemaining}", "selectedYear", "selectedMonth", function () {
      var remainingDays = this.get("vacationReport.report.remainingDays");
      var remainingUntil = this.get("vacationReport.report.remainingUntil");
      var takenFromRemaining = this.get("vacationReport.report.takenFromRemaining");

      if (remainingDays < 0) {
        return remainingDays;
      }

      if (remainingUntil) {
        var remainingDate = (0, _moment.default)(remainingUntil);
        var startOfCurrentMonth = _moment.default.utc([this.get("selectedYear"), this.get("selectedMonth") - 1, 1, 0, 0, 0, 0]);

        if (remainingDate < startOfCurrentMonth) {
          remainingDays = 0;
        }
      }

      return Math.max(0, remainingDays - takenFromRemaining);
    }),

    otherTakenVacationCount: Ember.computed("vacationReport.report.takenVacations", function () {
      var takenVacations = this.get("vacationReport.report.takenVacations");

      if (!takenVacations) {
        return 0;
      }

      var count = 0;
      Object.entries(takenVacations).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            type = _ref2[0],
            value = _ref2[1];

        if (type === "holiday") {
          return;
        }

        count += value;
      });

      return count;
    }),

    otherTakenVacations: Ember.computed("vacationReport.report.takenVacations", function () {
      var _this = this;

      var translatedVacationType = function translatedVacationType(type) {
        var t = _this.intl.lookup("vacation.type." + type);

        if (t.indexOf("Missing translation") === 0) {
          return type;
        }

        return t;
      };

      var takenVacations = this.get("vacationReport.report.takenVacations");

      if (!takenVacations) {
        return "";
      }

      var content = "";
      content += '<div class="ui horizontal statistics tiny">';

      var realVacationCount = 0;
      Object.entries(takenVacations).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            type = _ref4[0],
            value = _ref4[1];

        if (type === "holiday") {
          return;
        }

        if (value === 0) {
          return;
        }

        content += '  <div class="grey statistic">';
        content += '    <div class="value">' + value + "</div>";
        content += '    <div class="label">' + translatedVacationType(type) + "</div>";
        content += "  </div>";

        realVacationCount++;
      });

      if (realVacationCount === 0) {
        return "";
      }

      content += "</div>";

      return content;
    }),

    currentlyAvailableDays: Ember.computed("vacationReport.report.availableDays", "availableRemainingDays", function () {
      if (this.get("loading")) {
        return "";
      }

      var remainingDays = this.get("availableRemainingDays");
      var availableDays = this.get("vacationReport.report.availableDays");

      return availableDays + remainingDays;
    }),

    didRender: function didRender() {
      var popup = this.$('.vacation-statistics[data-html!=""]');
      popup.popup();
    }
  });
});