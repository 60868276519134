define("frontend/controllers/planning/capacity", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    from: null,
    to: null,
    groupId: null,

    queryParams: ["from", "to", "groupId"],

    startDate: Ember.computed("from", {
      get: function get() {
        if (this.get("from")) {
          return (0, _moment.default)(this.get("from")).startOf("day").toDate();
        } else {
          // Do not bind to a clock here
          var d = (0, _moment.default)().add(1, "week").startOf("week");
          return d.toDate();
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("from", (0, _moment.default)(value).format("YYYY-MM-DD"));
          return (0, _moment.default)(value).startOf("day").toDate();
        } else {
          var d = (0, _moment.default)().add(1, "week").startOf("week");
          this.set("from", d.format("YYYY-MM-DD"));
          return d.toDate();
        }
      }
    }),
    endDate: Ember.computed("to", {
      get: function get() {
        if (this.get("to")) {
          return (0, _moment.default)(this.get("to")).endOf("day").toDate();
        } else {
          // Do not bind to a clock here
          var d = (0, _moment.default)().add(1, "week").endOf("week");
          return d.toDate();
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("to", (0, _moment.default)(value).format("YYYY-MM-DD"));
          return (0, _moment.default)(value).endOf("day").toDate();
        } else {
          var d = (0, _moment.default)().add(1, "week").endOf("week");
          this.set("to", d.format("YYYY-MM-DD"));
          return d.toDate();
        }
      }
    }),
    selectedGroup: Ember.computed("groupId", "model.groups", {
      get: function get() {
        if (this.get("groupId")) {
          return this.get("model.groups").findBy("id", this.get("groupId"));
        }
        return null;
      },
      set: function set(key, groupOrId) {
        var groupId = groupOrId && groupOrId.get ? groupOrId.get("id") : groupOrId;
        this.set("groupId", groupId);
        return groupId ? this.get("model.groups").findBy("id", groupId) : null;
      }
    }),
    employees: Ember.computed("model.employees", function () {
      return this.get("model.employees").sortBy("lastname");
    })
  });
});