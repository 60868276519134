define("frontend/routes/employees/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var permissions = this.get("auth.user.permissions");
      var employeeId = this.get("auth.user.employee.id");
      if (!Ember.isArray(permissions)) {
        return;
      }
      if (employeeId && permissions.indexOf("permissionAllPresenceList") === -1) {
        this.transitionTo("employees.employee", this.get("auth.user.employee"));
      }
    },


    /*
     * Reload employees if reloadIntervalMs has passed since the initial load
     * of the parent model
     */
    model: function model() {
      var employees = this.modelFor("employees");
      var currentUser = employees.get("firstObject");

      if (currentUser && currentUser.get("id") === this.get("auth.user.employee.id")) {
        currentUser.set("isActiveUser", true);
      }

      return employees.sortBy("lastname");
    }
  });
});