define("frontend/controllers/signup", ["exports", "ember-ajax/errors", "frontend/mixins/serializer/extract-errors", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "frontend/util/features"], function (exports, _errors, _extractErrors, _emberChangeset, _emberChangesetValidations, _validators, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SignupValidations = {
    email: [(0, _validators.validateFormat)({
      type: "email",
      message: "Invalid email adress"
    })],
    organisation: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    password: (0, _validators.validateLength)({
      min: 8,
      message: "Password needs at least 8 characters"
    }),
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: "password",
      message: "doesn't match password"
    }),
    acceptTermsConditions: (0, _validators.validatePresence)({
      presence: true,
      message: "Must be accepted"
    })
  };

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),

    loginController: Ember.inject.controller("login"),

    logger: Ember.inject.service(),
    notify: Ember.inject.service(),

    queryParams: ["deletedAccount"],
    deletedAccount: null,

    showErrorMessage: false,

    setup: function setup(model) {
      this.set("model", model);

      if (!this.changeset) {
        this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(SignupValidations), SignupValidations, { skipValidate: true });
      }
    },


    actions: {
      create: function create() {
        var _this = this;

        var model = this.get("model");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.execute();

            _this.get("ajax").post("/api/users", {
              data: {
                email: model.get("email"),
                password: model.get("password"),
                organisation: model.get("organisation"),
                accept_terms_conditions: true
              }
            }).then(function () {
              _this.get("loginController").set("email", model.get("email"));
              _this.get("loginController").set("password", model.get("password"));
              _this.get("loginController").send("login");
              // TODO I18N
              _this.get("notify").success("Registrierung erfolgreich");

              // Track goal in Matomo
              if ((0, _features.default)("tracking")) {
                window._paq = window._paq || [];
                window._paq.push(["trackGoal", 2, 50]);
              }
            }, function (error) {
              _this.set("showErrorMessage", true);

              model.reset();

              if (error instanceof _errors.InvalidError) {
                var modelErrors = (0, _extractErrors.convertFormErrors)(error.payload);
                for (var key in modelErrors) {
                  changeset.pushErrors(key, modelErrors[key]);
                }
              }
              // Other remote error
              else {
                  _this.get("logger").catch(error, "Error registering new user");
                }
            });
          }
        });
      },
      showTermsConditions: function showTermsConditions() {
        Ember.$(".ui.terms-conditions.modal").modal("show");
      }
    }
  });
});