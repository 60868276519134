define("frontend/helpers/has-permission", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasPermission = hasPermission;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function hasPermission(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        permission = _ref2[0];

    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var permissions = hash.permissions;

    var p = permissions || this.get("auth.user.permissions");
    if (!Ember.isArray(p)) {
      return false;
    }
    if (p.indexOf(permission) >= 0) {
      return true;
    }
    return false;
  }

  exports.default = Ember.Helper.extend({
    auth: Ember.inject.service(),
    compute: hasPermission
  });
});