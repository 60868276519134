define("frontend/controllers/employees/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    availableGroups: null,

    notify: Ember.inject.service(),
    router: Ember.inject.service(),

    employeeEditController: Ember.inject.controller("employees.employee.edit"),

    actions: {
      success: function success(employee) {
        var _this = this;

        this.get("notify").success("Personal hinzugefügt");
        // Transition to edit view for hint to employments
        this.get("router").transitionTo("employees.employee.edit", employee).then(function () {
          Ember.run.schedule("afterRender", function () {
            _this.set("employeeEditController.isNewEmployee", true);
            document.getElementById("employment-section").scrollIntoView(true);
          });
        });
      },
      cancel: function cancel() {
        this.get("router").transitionTo("employees");
      }
    }
  });
});