define("frontend/components/employee/subscription-change-confirmation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    "on-approve": NOOP,
    employeeCountDelta: +1,

    tagName: "span",

    payment: Ember.inject.service(),

    hasSubscription: Ember.computed.readOnly("payment.hasSubscription"),
    subscriptionPreviewLoading: false,
    subscriptionPreviewInvoice: null,

    actions: {
      showModal: function showModal() {
        var _this = this;

        this.set("subscriptionPreviewLoading", true);
        this.get("payment").previewChangeEmployee(this.get("employeeCountDelta")).then(function (invoice) {
          _this.set("subscriptionPreviewInvoice", invoice);
        }).finally(function () {
          _this.set("subscriptionPreviewLoading", false);
        });

        this.$(".ui.modal").modal("show");
      }
    }
  });
});