define("frontend/react/hooks/useNotify", ["exports", "react", "frontend/react/context/NotifyContext"], function (exports, _react, _NotifyContext) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var useNotify = function useNotify() {
    return (0, _react.useContext)(_NotifyContext.default);
  };

  exports.default = useNotify;
});