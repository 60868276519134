define("frontend/components/react-component", ["exports", "react-dom", "frontend/react/context/AuthContext", "frontend/react/context/OrganisationContext", "frontend/react/context/NotifyContext"], function (exports, _reactDom, _AuthContext, _OrganisationContext, _NotifyContext) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),

    notify: Ember.inject.service(),

    /**
     * Renders a react component as the current ember element
     * @param {React.Component} ReactComponent
     */
    reactRender: function reactRender(ReactComponent) {
      _reactDom.default.render(React.createElement(
        _NotifyContext.NotifyContextProvider,
        { value: this.get("notify") },
        React.createElement(
          _AuthContext.AuthContextProvider,
          { value: this.get("auth") },
          React.createElement(
            _OrganisationContext.OrganisationContextProvider,
            {
              value: this.get("auth.user.organisation")
            },
            React.createElement(ReactComponent, null)
          )
        )
      ), this.element);
    },


    /**
     * Removes a mounted React component from the DOM and
     * cleans up its event handlers and state.
     */
    unmountReactElement: function unmountReactElement() {
      _reactDom.default.unmountComponentAtNode(this.element);
    },


    /**
     * Cleans up the rendered react component as the ember
     * component gets destroyed
     */
    willDestroyElement: function willDestroyElement() {
      this._super();
      this.unmountReactElement();
    }
  });
});