define("frontend/components/account/calendar-subscription", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    urlPrefix: Ember.computed(function () {
      var loc = window.location;
      return loc.protocol + "//" + loc.host;
    }).volatile()
  });
});