define("frontend/adapters/application", ["exports", "ember-data", "frontend/config/environment"], function (exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ApplicationAdapter = _emberData.default.RESTAdapter.extend({
    namespace: "api",

    urlForSingleRecord: false,

    auth: Ember.inject.service(),

    host: _environment.default.apiHost,

    headers: Ember.computed("auth.{token,clientId}", function () {
      return {
        Authorization: "Bearer " + this.get("auth.token"),
        "X-Client-Id": this.get("auth.clientId")
      };
    }),

    // Override buildURL to set a flag for pathForType to have different paths depending on a single record or the collection
    buildURL: function buildURL(type, id, snapshot, requestType) {
      this.urlForSingleRecord = !!id;
      var url = this._super(type, id, snapshot, requestType);
      this.urlForSingleRecord = false;
      return url;
    },

    pathForType: function pathForType(type) {
      if (this.paths) {
        if (this.urlForSingleRecord) {
          if (this.paths.single) {
            return this.paths.single;
          }
        } else {
          if (this.paths.collection) {
            return this.paths.collection;
          }
        }
      }
      return this._super(type);
    },

    // Make sure to include the raw errors payload to process validation errors
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isInvalid(status, headers, payload)) {
        var errors = payload;
        return this._super(status, headers, { errors: errors });
      }
      return this._super.apply(this, arguments);
    }
  });

  exports.default = ApplicationAdapter;
});