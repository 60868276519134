define("frontend/serializers/user", ["exports", "ember-data", "frontend/mixins/serializer/extract-errors"], function (exports, _emberData, _extractErrors) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_extractErrors.default, {
    attrs: {
      organisation: "organisationId",
      employee: "employeeId"
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      if (payload.organisation) {
        var organisation = payload.organisation;
        delete payload.organisation;
        payload.organisations = [organisation];
      }
      if (payload.employee) {
        var employee = payload.employee;
        delete payload.employee;
        payload.employees = [employee];
      }
      if (payload.permissions && payload.user) {
        var permissions = payload.permissions;
        delete payload.permissions;
        payload.user.permissions = permissions;
      }
      return this._super.apply(this, arguments);
    },

    // Do not use a root property when sending data to the server
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      var data = this.serialize(snapshot, options),
          p;
      for (p in hash) {
        delete hash[p];
      }
      for (p in data) {
        hash[p] = data[p];
      }
    },

    normalize: function normalize(modelClass, hash) {
      // Always reset the local model password
      if (hash) {
        hash.password = "";
        hash.currentPassword = "";
      }
      return this._super.apply(this, arguments);
    }
  });
});