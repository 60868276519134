define("frontend/controllers/employees/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    filter: null,

    auth: Ember.inject.service(),

    hasEditRight: Ember.computed("auth.user.permissions", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Array.isArray(permissions)) {
        return false;
      }
      return permissions.indexOf("permissionAllEmployeeWrite") > -1;
    }),

    filteredEmployees: Ember.computed("filter", "model", function () {
      var filter = this.get("filter");
      var employees = this.get("model");

      if (Ember.isBlank(filter)) {
        return employees;
      }
      filter = filter.toLowerCase();
      return employees.filter(function (employee) {
        return employee.get("firstname").toLowerCase().indexOf(filter) >= 0 || employee.get("lastname").toLowerCase().indexOf(filter) >= 0;
      });
    })
  });
});