define("frontend/adapters/employee", ["exports", "frontend/adapters/application", "frontend/mixins/form-data-adapter"], function (exports, _application, _formDataAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_formDataAdapter.default, {
    paths: {
      single: "employee",
      collection: "employees"
    }
  });
});