define("frontend/components/employee/presence-icon", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    classNames: ["presence-icon"],

    employee: null
  });
});