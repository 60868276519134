define("frontend/react/components/accounting/customer/CustomerData", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CustomerData = function CustomerData(_ref) {
    var customer = _ref.customer,
        onShowForm = _ref.onShowForm;

    return React.createElement(
      "div",
      null,
      React.createElement(
        "table",
        { className: "ui very basic table unstackable" },
        React.createElement(
          "tbody",
          null,
          React.createElement(
            "tr",
            null,
            React.createElement(
              "th",
              { className: "four wide" },
              "Name"
            ),
            React.createElement(
              "td",
              { className: "twelve wide" },
              customer.name
            )
          ),
          React.createElement(
            "tr",
            { className: "top aligned" },
            React.createElement(
              "th",
              null,
              "Adresse"
            ),
            React.createElement(
              "td",
              null,
              React.createElement(
                "p",
                null,
                customer.street
              ),
              customer.addressAddition && React.createElement(
                "p",
                null,
                customer.addressAddition
              ),
              React.createElement(
                "p",
                null,
                customer.country,
                "-",
                customer.zip,
                " ",
                customer.city
              )
            )
          ),
          React.createElement(
            "tr",
            null,
            React.createElement(
              "th",
              null,
              "E-Mail"
            ),
            React.createElement(
              "td",
              null,
              customer.email
            )
          ),
          customer.euVatId ? React.createElement(
            "tr",
            null,
            React.createElement(
              "th",
              null,
              "USt-IdNr."
            ),
            React.createElement(
              "td",
              null,
              customer.euVatId
            )
          ) : null
        )
      ),
      React.createElement(
        "button",
        { className: "ui basic button", type: "submit", onClick: onShowForm },
        "Bearbeiten"
      )
    );
  };

  exports.default = CustomerData;
});