define("frontend/models/employment", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.workingDurationHours = exports.workingTimePerDay = exports.workingTimePerMonth = exports.workingTimePerWeek = undefined;
  var workingTimePerWeek = exports.workingTimePerWeek = Ember.computed("workingDurationHours", function () {
    return this.get("workingDurationHours").reduce(function (result, val) {
      return result + val;
    }, 0);
  });

  var workingTimePerMonth = exports.workingTimePerMonth = Ember.computed("workingTimePerWeek", function () {
    return this.get("workingTimePerWeek") * 52 / 12;
  });

  var workingTimePerDay = exports.workingTimePerDay = Ember.computed("workingDurationHours", function () {
    var workingDurations = this.get("workingDurationHours");
    var workdayHours = workingDurations.filter(function (val) {
      return val > 0;
    });
    if (workdayHours.length > 0) {
      return workdayHours.reduce(function (result, val) {
        return result + val;
      }, 0) / workdayHours.length;
    }
    return 0;
  });

  var workingDurationHours = exports.workingDurationHours = Ember.computed("workingDurations", function () {
    return this.get("workingDurations").map(function (val) {
      return val / 3600;
    });
  });

  exports.default = _emberData.default.Model.extend({
    employeeId: _emberData.default.attr("string"),
    workingTimeModel: _emberData.default.attr("string"),
    validFrom: _emberData.default.attr("date"),
    validTo: _emberData.default.attr("date"),
    vacationDays: _emberData.default.attr("number"),
    vacationDaysOverride: _emberData.default.attr("number", { default: null }),
    vacationCarryOverMonths: _emberData.default.attr("number", { defaultValue: 3 }),
    workingDurations: _emberData.default.attr("array"),
    locationCountry: _emberData.default.attr("string"),
    locationState: _emberData.default.attr("string"),
    carryOverWorkDuration: _emberData.default.attr("number"),

    workingTimePerWeek: workingTimePerWeek,
    workingTimePerMonth: workingTimePerMonth,
    workingTimePerDay: workingTimePerDay,
    workingDurationHours: workingDurationHours,

    vacationCarryOverMonthsForm: Ember.computed("vacationCarryOverMonths", {
      get: function get() {
        var carryOverMonths = this.get("vacationCarryOverMonths");

        if (carryOverMonths === null) {
          return "infinite";
        }

        return carryOverMonths;
      },
      set: function set(key, value) {
        var carryOverMonths = value;
        if (value === null) {
          carryOverMonths = null;
        }

        this.set("vacationCarryOverMonths", carryOverMonths);
      }
    }),

    carryOverWorkDurationHours: Ember.computed("carryOverWorkDuration", {
      get: function get() {
        var carryOverWorkDuration = this.get("carryOverWorkDuration");
        if (carryOverWorkDuration === null) {
          return null;
        }
        return carryOverWorkDuration / 3600;
      },
      set: function set(key, value) {
        var seconds = Math.round(value * 3600);
        this.set("carryOverWorkDuration", seconds);
        return value;
      }
    })
  });
});