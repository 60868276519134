define("frontend/controllers/reports/monthly", ["exports", "frontend/util/computed"], function (exports, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var offsetMonth = -1;

  exports.default = Ember.Controller.extend({
    month: null,
    year: null,

    queryParams: ["month", "year"],

    employees: Ember.computed("model.employees", function () {
      return this.get("model.employees").sortBy("lastname");
    }),

    selectedMonth: (0, _computed.monthWithDefault)("month", offsetMonth),
    selectedYear: (0, _computed.yearWithDefault)("year", offsetMonth)
  });
});