define("frontend/mixins/component/mixed-validation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GLOBAL_ERROR_ATTRIBUTE = exports.GLOBAL_ERROR_ATTRIBUTE = "__global__";

  exports.default = Ember.Mixin.create({
    // Should be bound via a computed property
    modelErrors: null,
    validationErrors: null,

    displayErrors: false,

    formErrors: Ember.computed("validationErrors", "modelErrors.@each.message", "displayErrors", function () {
      if (!this.get("displayErrors")) {
        return {};
      }

      // Do not clone the validationErrors object, we need the binding to the original object for local validation updates
      var errors = this.get("validationErrors") || Ember.Object.create();
      var modelErrors = this.get("modelErrors") || Ember.A();
      modelErrors.forEach(function (error) {
        // Do not try to merge / append errors here, TODO this could be improved
        var messages = [error.message];
        errors.set(error.attribute, messages);
      });
      return errors;
    })
  });
});