define("frontend/util/dashboard/timeconversions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertSecondsToDigitsMinute = convertSecondsToDigitsMinute;
  exports.convertSecondsToMinutes = convertSecondsToMinutes;
  exports.convertSecondsToHours = convertSecondsToHours;
  exports.convertMinutesToSeconds = convertMinutesToSeconds;
  exports.convertHoursToSeconds = convertHoursToSeconds;
  exports.convertTimeToDash = convertTimeToDash;
  /*
   * Converting time Variables to correct format
   * imported in dashboard components
   *
   */
  function convertSecondsToDigitsMinute(seconds) {
    var sign = 1;
    if (seconds < 0) {
      sign = -1;
      seconds = seconds * -1;
    }
    var hours = Math.floor(seconds / 3600),
        minutes = Math.floor(seconds % 3600 / 60);
    return (sign === -1 ? "-" : "") + hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
  }

  function convertSecondsToMinutes(seconds) {
    return seconds / 60;
  }

  function convertSecondsToHours(seconds) {
    return seconds / 3600;
  }

  function convertMinutesToSeconds(minutes) {
    return minutes * 60;
  }

  function convertHoursToSeconds(hours) {
    return hours * 3600;
  }

  function convertTimeToDash(workDuration, dashLength, dashArray) {
    var targetBackground = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    var dash = void 0;
    if (targetBackground) {
      dash = parseInt(convertSecondsToMinutes(workDuration) * dashLength + 2);
      return dash + " " + (dashArray - dash);
    }
    dash = parseInt(convertSecondsToMinutes(workDuration) * dashLength);
    return dash + " " + (dashArray - dash);
  }
});