define("frontend/components/employee/presence-row", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    employee: null,
    presence: null,
    editing: false,
    enableEdit: true,

    clock: Ember.inject.service(),
    logger: Ember.inject.service(),

    // Work duration for the current (pending) checkin (without taking stored presence into account)
    pendingWorkDuration: Ember.computed("clock.time", "presence", "presence.checkin", "pendingPauseDuration", function () {
      var now = this.get("clock.time");
      var checkinDuration = (0, _moment.default)(now).diff(this.get("presence.checkin"), "seconds");
      var pauseDuration = this.get("pendingPauseDuration");
      var workDuration = checkinDuration - pauseDuration;
      return workDuration;
    }),

    pendingPauseDuration: Ember.computed("clock.time", "presence", "presence.pauseIntervals.@each.{start,end}", function () {
      var now = this.get("clock.time");
      var pauseDuration = this.get("presence.pauseIntervals").map(function (interval) {
        if (interval.end) {
          return (0, _moment.default)(interval.end).diff(interval.start, "seconds");
        } else {
          return (0, _moment.default)(now).diff(interval.start, "seconds");
        }
      }).reduce(function (s, x) {
        return s + x;
      }, 0);
      return pauseDuration;
    }),

    cancelWhenPendingPresenceStatusChanges: Ember.observer("employee.status", function () {
      if (this.get("editing") && this.get("presence.isPending") && this.get("presence.employeeId") === this.get("employee.id")) {
        this.send("cancel");
      }
    }),

    actions: {
      edit: function edit() {
        this.set("editing", true);
      },
      success: function success() {
        this.set("editing", false);
        if (this.get("on-success")) {
          this.get("on-success")();
        }
      },
      cancel: function cancel() {
        this.set("editing", false);
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      },
      showDeleteConfirmation: function showDeleteConfirmation(modalIndex) {
        var selector = "tr#presence-row-" + modalIndex + " .ui.modal.delete";
        Ember.$(selector).modal("show");
      },
      delete: function _delete() {
        var _this = this;

        var promise = this.get("presence").destroyRecord().catch(function (error) {
          _this.get("logger").catch(error, "Error deleting presence", {
            presenceId: _this.get("presence.id")
          });
        });
        if (this.get("on-delete")) {
          promise.then(function () {
            _this.get("on-delete")();
          });
        }
        return promise;
      }
    }
  });
});