define("frontend/routes/index", ["exports", "frontend/util/features"], function (exports, _features) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      if ((0, _features.default)("dashboard")) {
        this.transitionTo("dashboard");
      } else {
        this.transitionTo("employees");
      }
    }
  });
});