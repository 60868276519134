define("frontend/react/api/client", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var fetchIt = function fetchIt(_ref) {
    var method = _ref.method,
        apiUrlPrefix = _ref.apiUrlPrefix,
        query = _ref.query,
        authToken = _ref.authToken,
        clientId = _ref.clientId,
        payload = _ref.payload;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (authToken) {
      headers["Authorization"] = "Bearer " + authToken;
    }

    if (clientId) {
      headers["X-Client-Id"] = clientId;
    }

    var params = {
      method: method,
      headers: headers
    };

    if (payload) {
      params.body = JSON.stringify(payload);
    }

    return fetch(apiUrlPrefix + query, params);
  };

  var getApiClient = function getApiClient(_ref2) {
    var apiUrlPrefix = _ref2.apiUrlPrefix,
        authToken = _ref2.authToken,
        clientId = _ref2.clientId;

    return {
      get: function get(_ref3) {
        var query = _ref3.query;
        return fetchIt({
          method: "GET",
          apiUrlPrefix: apiUrlPrefix,
          query: query,
          authToken: authToken,
          clientId: clientId
        });
      },
      post: function post(_ref4) {
        var query = _ref4.query,
            payload = _ref4.payload;
        return fetchIt({
          method: "POST",
          apiUrlPrefix: apiUrlPrefix,
          query: query,
          authToken: authToken,
          clientId: clientId,
          payload: payload
        });
      },
      put: function put(_ref5) {
        var query = _ref5.query,
            payload = _ref5.payload;
        return fetchIt({
          method: "PUT",
          apiUrlPrefix: apiUrlPrefix,
          query: query,
          authToken: authToken,
          clientId: clientId,
          payload: payload
        });
      },
      delete: function _delete(_ref6) {
        var query = _ref6.query;
        return fetchIt({
          method: "DELETE",
          apiUrlPrefix: apiUrlPrefix,
          query: query,
          authToken: authToken,
          clientId: clientId
        });
      }
    };
  };

  exports.default = getApiClient;
});