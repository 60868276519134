define("frontend/adapters/user", ["exports", "frontend/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    paths: {
      single: "user",
      collection: "users"
    },

    // TODO We do not always need all the includes! Just for loading the auth user...
    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);
      return url + "?include=organisation,employee,permissions";
    }
  });
});