define("frontend/controllers/settings/organisation/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    logger: Ember.inject.service(),
    auth: Ember.inject.service(),

    hasActiveSubscription: Ember.computed("auth.user.organisation.subscription", function () {
      return !!this.get("auth.user.organisation.subscription") && !this.get("auth.user.organisation.subscription.canceledAt");
    }),

    actions: {
      delete: function _delete() {
        var _this = this;

        if (this.get("auth.user.permissions").includes("permissionOrganisationWrite")) {
          var promise = this.get("model.organisation").destroyRecord().then(function () {
            _this.get("auth").reset();
            window.location.href = "/signup?deletedAccount";
          }).catch(function (err) {
            if (_this.get("auth.user.organisation.subscription") && !_this.get("auth.user.organisation.subscription.canceledAt")) {
              _this.get("notify").alert("Sie müssen zuvor ihr Abonnement kündigen.");
            } else {
              _this.get("logger").catch(err, "Error deleting organisation " + _this.get("model.organisation.id"));
            }
          });

          return promise;
        }

        this.get("logger").catch("user has no permission", "Error deleting organisation " + this.get("model.organisation.id"));
      }
    }
  });
});