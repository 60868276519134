define("frontend/transforms/intervals", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var v = Ember.isArray(serialized) ? serialized.map(function (interval) {
        return {
          start: interval.start ? new Date(interval.start) : null,
          end: interval.end ? new Date(interval.end) : null,
          type: interval.type ? interval.type : null
        };
      }) : [];
      return v;
    },

    serialize: function serialize(deserialized) {
      return Ember.isArray(deserialized) ? deserialized.map(function (interval) {
        return {
          start: interval.start ? interval.start.toISOString() : null,
          end: interval.end ? interval.end.toISOString() : null,
          type: interval.type ? interval.type : null
        };
      }) : [];
    }
  });
});