define("frontend/components/employee/employments-list", ["exports", "frontend/util/objectid"], function (exports, _objectid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    employee: null,
    newEmployment: null,
    editingEmployment: null,
    isNewEmployee: false,

    store: Ember.inject.service(),

    employmentsSortedByNewest: Ember.computed("employee.employments", function () {
      var employments = this.get("employee.employments").map(function (employment) {
        return employment;
      });

      return employments.sort(function (a, b) {
        return new Date(b.validFrom) - new Date(a.validFrom);
      });
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get("isNewEmployee")) {
        this.send("add");
      }
    },


    actions: {
      add: function add() {
        // No need to add this to the employee, since it will be returned embedded on save
        var employment = this.get("store").createRecord("employment", {
          id: (0, _objectid.newObjectId)().toString(),
          employeeId: this.get("employee.id"),
          workingDurations: [0, 0, 0, 0, 0, 0, 0]
        });
        this.set("newEmployment", employment);
        this.set("editingEmployment", null);
      },
      reset: function reset() {
        this.set("newEmployment", null);
        this.set("editingEmployment", null);
      },
      resetAndRefresh: function resetAndRefresh() {
        this.set("newEmployment", null);
        this.set("editingEmployment", null);
        if (this.get("on-change")) {
          this.get("on-change")();
        }
      },
      editEmployment: function editEmployment(employment) {
        this.set("editingEmployment", employment);
        if (this.get("newEmployment")) {
          this.set("newEmployment", null);
        }
      }
    }
  });
});