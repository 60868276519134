define("frontend/components/trial-reminder", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    classNames: ["trial-reminder"],

    clock: Ember.inject.service("slow-clock"),
    auth: Ember.inject.service("auth"),
    payment: Ember.inject.service("payment"),

    organisation: Ember.computed("auth.user.organisation", function () {
      return this.get("auth.user.organisation");
    }),

    permissions: Ember.computed("auth.user.permissions", function () {
      return this.get("auth.user.permissions");
    }),

    showReminder: Ember.computed("organisation", "organisation.{trialEnd,subscription}", "clock.time", function () {
      var trialEnd = this.get("organisation.trialEnd");
      var now = this.get("clock.time");
      return (typeof trialEnd === "undefined" ? "undefined" : _typeof(trialEnd)) === "object" && now < trialEnd.getTime() && !this.get("payment.hasSubscription");
    }),

    showNoSubscription: Ember.computed("organisation", "organisation.{trialEnd,subscription}", "clock.time", function () {
      var trialEnd = this.get("organisation.trialEnd");
      var now = this.get("clock.time");
      return (typeof trialEnd === "undefined" ? "undefined" : _typeof(trialEnd)) === "object" && now >= trialEnd.getTime() && !this.get("payment.hasSubscription");
    })
  });
});