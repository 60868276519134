define("frontend/components/employee/vacation-form", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "frontend/models/vacation"], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _vacation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VacationValidations = {
    start: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    end: (0, _validators.validatePresence)({ presence: true, message: "Required" }),
    type: (0, _validators.validateInclusion)({ list: _vacation.VACATION_TYPES, message: "Invalid value" })
  };

  exports.default = Ember.Component.extend({
    logger: Ember.inject.service(),

    VACATION_TYPES: _vacation.VACATION_TYPES,

    init: function init() {
      this._super.apply(this, arguments);
      var vacation = this.get("vacation");
      this.changeset = new _emberChangeset.default(vacation, (0, _emberChangesetValidations.default)(VacationValidations), VacationValidations);
    },


    actions: {
      save: function save() {
        var _this = this;

        var vacation = this.get("vacation");
        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.save().then(function () {
              _this.set("editing", false);
              if (_this.get("on-success")) {
                _this.get("on-success")();
              }
            }, function (error) {
              // Remote validation error
              if (!vacation.get("isValid")) {
                var modelErrors = vacation.get("errors");
                modelErrors.forEach(function (modelError) {
                  changeset.pushErrors(modelError.attribute, modelError.message);
                });
                // Tricky part: reset (existing) model to prevent view of invalid changes, changeset keeps modifications for later save
                if (!vacation.get("isNew")) {
                  vacation.rollbackAttributes();
                }
              }
              // Other remote error
              else {
                  _this.get("logger").catch(error, "Error saving vacation", {
                    vacationId: _this.get("vacation.id")
                  });
                }
            });
          }
        });
      },
      close: function close() {
        this.changeset.rollback();
        if (this.get("on-cancel")) {
          this.get("on-cancel")();
        }
      }
    }
  });
});