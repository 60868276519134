define("frontend/components/dashboard/chart-overview", ["exports", "chartist", "moment", "frontend/util/dashboard/timeconversions", "frontend/util/timewatcher"], function (exports, _chartist, _moment, _timeconversions, _timewatcher) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var barBackgroundPadding = 0.05;
  var targetWorkBreakHeight = 0.1;
  var dispayDurationIfNoTargetWork = 8;
  var nothingToDisplay = 0;

  exports.default = Ember.Component.extend({
    classNames: ["chart-overview"],
    classNameBindings: ["showMonth:show-month"],

    // Service
    slowClock: Ember.inject.service("slow-clock"),

    presencesByDay: null,
    selectedMonth: null,
    selectedYear: null,

    // Needs to be set to true in component helper if desired to show last month
    showMonth: null,
    showTooltip: false,

    tooltipData: null,

    // Current Chartist instance
    chart: null,

    presences: Ember.computed("showMonth", "presencesByDay", "selectedMonth", "selectedYear", "slowClock.time", function () {
      var presencesByDay = this.get("presencesByDay") || [];

      if (this.get("showMonth")) {
        var currentYear = this.get("selectedYear");
        var currentMonth = this.get("selectedMonth") - 1; // moment.js starts counting months by 0

        var from = (0, _moment.default)([currentYear, currentMonth]).startOf("month");
        var to = (0, _moment.default)([currentYear, currentMonth]).endOf("month");

        return presencesByDay.filter(function (element) {
          return (0, _moment.default)(element.date).isSameOrAfter(from, "days") && (0, _moment.default)(element.date).isSameOrBefore(to, "days");
        });
      }

      return presencesByDay;
    }),

    renderedDates: Ember.computed("showMonth", "presences", function () {
      var format = this.get("showMonth") ? "DD." : "DD.MM.";
      var presences = this.get("presences") || [];

      return presences.map(function (element) {
        return (0, _moment.default)(element.date).format(format);
      });
    }),

    workDurations: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        if (element.aborted) {
          var EIGHT_HOURS_IN_SECONDS = 3600 * 8;
          return (0, _timeconversions.convertSecondsToHours)(element.targetWorkDuration ? element.targetWorkDuration : EIGHT_HOURS_IN_SECONDS);
        }
        return (0, _timeconversions.convertSecondsToHours)(element.workDuration);
      });
    }),

    workDurationsColor: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        if (!element.vacation && (0, _timewatcher.workingOverTen)(element.workDuration) || element.aborted) {
          return "red";
        } else if (element.vacation) {
          return "grey";
        }
        return "green";
      });
    }),

    targetWorkDurations: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];

      return presences.map(function (element) {
        var hasTargetWorkDuration = !isNaN(element.targetWorkDuration) && element.targetWorkDuration >= 0;
        return hasTargetWorkDuration ? (0, _timeconversions.convertSecondsToHours)(element.targetWorkDuration) : nothingToDisplay;
      });
    }),

    pauseDurations: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        if (element.vacation) {
          return (0, _timeconversions.convertSecondsToHours)((0, _timewatcher.targetPauseDurationByWorkDuration)(element.workDuration)) + (0, _timeconversions.convertSecondsToHours)(element.pauseDuration);
        }
        return (0, _timeconversions.convertSecondsToHours)(element.pauseDuration);
      });
    }),

    pauseDurationsColor: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        var targetPauseDuration = (0, _timewatcher.targetPauseDurationByWorkDuration)(element.workDuration);
        var pauseDuration = element.pauseDuration;

        return element.vacation ? "grey" : targetPauseDuration > pauseDuration ? "red" : "yellow";
      });
    }),

    targetPauseDurations: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        return (0, _timeconversions.convertSecondsToHours)((0, _timewatcher.targetPauseDurationByWorkDuration)(element.workDuration));
      });
    }),

    targetWorkBG: Ember.computed("targetWorkDurations", function () {
      return this.get("targetWorkDurations").map(function (element) {
        return Math.max(0, element - barBackgroundPadding);
      });
    }),

    targetPauseDurationsBG: Ember.computed("targetPauseDurations", function () {
      return this.get("targetPauseDurations").map(function (pause) {
        return Math.max(0, pause - barBackgroundPadding);
      });
    }),

    targetWorkBreak: Ember.computed("workDurations", function () {
      return this.get("workDurations").map(function () {
        return targetWorkBreakHeight;
      });
    }),

    noWorkDay: Ember.computed("presences", function () {
      var presences = this.get("presences") || [];
      return presences.map(function (element) {
        var hasNoTargetWork = element.targetWorkDuration <= 0;
        return hasNoTargetWork ? dispayDurationIfNoTargetWork : nothingToDisplay;
      });
    }),

    chartSize: Ember.computed("showMonth", function () {
      return this.get("showMonth") ? "ct-major-twelth" : "ct-major-sixth";
    }),

    didInsertElement: function didInsertElement() {
      this.send("drawGraph");
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get("chart") !== null) {
        this.get("chart").detach();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.send("drawGraph");
    },


    actions: {
      showTooltip: function showTooltip(elem) {
        var presence = this.get("presences")[elem.index];
        var workDuration = presence.workDuration;
        var pauseDuration = presence.pauseDuration;
        var targetWorkDuration = presence.targetWorkDuration;

        var data = {
          context: "chart-props",
          weekday: (0, _moment.default)(presence.date).format("dddd"),
          workDuration: workDuration,
          pauseDuration: pauseDuration,
          targetWorkDuration: targetWorkDuration,
          target: elem,
          didAbort: presence.aborted,
          isVacation: !!presence.vacation,
          vacationType: presence.vacation && presence.vacation.type,
          isHoliday: presence.holiday,
          holidayTitle: presence.holidayTitle,
          direction: "bottom"
        };
        this.set("showTooltip", true);
        this.set("tooltipData", data);
      },
      closeTooltip: function closeTooltip() {
        this.set("showTooltip", false);
        this.set("tooltipData", null);
      },
      drawGraph: function drawGraph() {
        var _this = this;

        if (this.get("chart") !== null) {
          this.get("chart").detach();
        }

        var el = this.get("element");
        var chartEl = el && el.querySelector(".ct-chart");
        var chart = new _chartist.default.Bar(chartEl, {
          labels: this.get("renderedDates"),
          series: [{
            name: "NOWORKDAY",
            data: this.get("noWorkDay")
          }, {
            name: "TARGET",
            data: this.get("targetWorkDurations")
          }, {
            name: "TARGETBG",
            data: this.get("targetWorkBG")
          }, {
            name: "PAUSETARGET",
            data: this.get("targetPauseDurations")
          }, {
            name: "PAUSETARGETBG",
            data: this.get("targetPauseDurationsBG")
          }, {
            name: "WORKDURATION",
            data: this.get("workDurations")
          }, {
            name: "PAUSE",
            data: this.get("pauseDurations")
          }, {
            name: "VBREAK",
            data: this.get("targetWorkBreak")
          }]
        }, {
          axisX: {
            showLabel: true,
            showGrid: true
          },
          axisY: {
            showGrid: true,
            onlyInteger: true,
            scaleMinSpace: 20
          },
          chartPadding: {
            top: 0,
            right: 30,
            bottom: 0,
            left: 0
          },
          fullWidth: true,
          seriesBarDistance: 0,
          // Define seperate class to avoid interferences
          classNames: {
            bar: "ct_bar_week"
          }
        });

        chart.on("draw", function (ctx) {
          if (ctx.type === "bar") {
            // Color
            if (ctx.series.name === "PAUSE") {
              ctx.element.addClass(_this.get("pauseDurationsColor")[ctx.index]);
            }
            if (ctx.series.name === "WORKDURATION") {
              ctx.element.addClass(_this.get("workDurationsColor")[ctx.index]);
            }
            // Break after target work duration is reached
            if (ctx.series.name === "VBREAK" && ctx !== undefined) {
              var targetWorkSibling = ctx.element._node.parentNode.parentNode.childNodes[1].children[ctx.index];
              var targetRect = targetWorkSibling.getBoundingClientRect();
              var targetWorkDuration = _this.get("targetWorkDurations")[ctx.index];
              var workDuration = _this.get("workDurations")[ctx.index];

              if (targetWorkDuration > 0 && workDuration >= targetWorkDuration) {
                var offset = targetRect.height - 1;
                ctx.element.attr({
                  style: "transform: translateY(-" + offset + "px);"
                });
              } else {
                ctx.element.attr({
                  style: "display: none;"
                });
              }
            }
            // Hover
            if (ctx.series.name === "WORKDURATION" || ctx.series.name === "PAUSE" || ctx.series.name === "PAUSETARGETBG" || ctx.series.name === "TARGETBG" || ctx.series.name === "NOWORKDAY") {
              ctx.element._node.addEventListener("mouseenter", function () {
                return _this.send("showTooltip", ctx);
              });
              ctx.element._node.addEventListener("mouseleave", function () {
                return _this.send("closeTooltip");
              });
            }
          }
        });

        this.set("chart", chart);
      }
    }
  });
});