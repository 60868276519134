define("frontend/models/presence", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sameDay(d1, d2) {
    return d1 && d2 && d1.getYear() === d2.getYear() && d1.getMonth() === d2.getMonth() && d1.getDay() === d2.getDay();
  }

  var Presence = _emberData.default.Model.extend({
    checkin: _emberData.default.attr("date"),
    checkout: _emberData.default.attr("date"),
    aborted: _emberData.default.attr("boolean"),
    workDuration: _emberData.default.attr("number"),
    pauseDuration: _emberData.default.attr("number"),
    pauseIntervals: _emberData.default.attr("intervals"),
    employeeId: _emberData.default.attr("string"),
    versions: _emberData.default.hasMany("presence-versions-entry"),
    editedBy: _emberData.default.belongsTo("user"),
    editedOn: _emberData.default.attr("date"),
    comment: _emberData.default.attr("string"),

    clock: Ember.inject.service("slow-clock"),

    isCheckinToday: Ember.computed("checkin", "clock.time", function () {
      return sameDay(this.get("checkin"), new Date(this.get("clock.time")));
    }),

    isCheckoutToday: Ember.computed("checkout", "clock.time", function () {
      return sameDay(this.get("checkout"), new Date(this.get("clock.time")));
    }),

    isPending: Ember.computed("checkout", "aborted", function () {
      return this.get("checkout") === null && !this.get("aborted");
    })
  });
  exports.default = Presence;
});