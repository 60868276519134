define("frontend/routes/settings/groups/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord("group");
    },
    deactivate: function deactivate() {
      if (this.currentModel.get("isNew")) {
        this.currentModel.destroyRecord();
      }
    }
  });
});