define("frontend/serializers/vacation", ["exports", "ember-data", "frontend/mixins/serializer/extract-errors"], function (exports, _emberData, _extractErrors) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _extractErrors.default, {
    attrs: {
      history: { serialize: false, deserialize: "records" }
    },

    // Do not use a root property when sending data to the server
    serializeIntoHash: function serializeIntoHash(hash, type, snapshot, options) {
      var data = this.serialize(snapshot, options),
          p;
      for (p in hash) {
        delete hash[p];
      }
      for (p in data) {
        hash[p] = data[p];
      }
    },

    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload) {
      if (payload.employee) {
        var employee = payload.employee;
        delete payload.employee;
        payload.employees = [employee];
      }
      return this._super.apply(this, arguments);
    }
  });
});