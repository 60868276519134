define("frontend/components/ui/text-input-confirm-modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    type: null,
    title: null,
    okLabel: "Löschen",
    "on-confirm": NOOP,
    buttonLabel: "",
    compareTo: null,

    auth: Ember.inject.service(),

    inputValue: Ember.computed("value", "compareTo", function () {
      return this.get("value") === this.get("compareTo");
    })
  });
});