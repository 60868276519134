define("frontend/services/slow-clock", ["exports", "frontend/services/base-clock"], function (exports, _baseClock) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SECONDS_IN_MINUTE = 60;

  exports.default = _baseClock.default.extend({
    interval: SECONDS_IN_MINUTE * 1000
  });
});