define("frontend/helpers/simple-format-time", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.simpleFormatTime = simpleFormatTime;
  function simpleFormatTime(params, hash) {
    var date = Ember.isArray(params) && params.length > 0 ? params[0] : params;
    if (!date) {
      return "";
    }

    if (typeof date === "string") {
      date = new Date(date);
    }

    var hours = date.getHours(),
        minutes = date.getMinutes(),
        seconds = date.getSeconds(),
        includeSeconds = hash && hash.seconds;
    return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + (includeSeconds ? ":" + (seconds < 10 ? "0" + seconds : seconds) : "");
  }

  exports.default = Ember.Helper.helper(simpleFormatTime);
});