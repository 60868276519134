define("frontend/controllers/employees", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),

    // TODO: Add setting for enabling other sort methods
    sortBy: "lastname",

    // Initialize controller with default values
    init: function init() {
      this._super.apply(this, arguments);

      // Show first item by default
      this.set("isActive", Ember.Object.create({ 0: true }));
    },


    groupedEmployees: Ember.computed("model.@each.{groups,isNew}", "auth.user.permissions", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Ember.isArray(permissions) || permissions.indexOf("permissionAllPresenceList") === -1) {
        return null;
      }

      var employeesByGroup = {};
      var defaultGroupWithEmployees = null;
      this.get("model").filterBy("isNew", false).forEach(function (employee) {
        var groups = employee.get("groups");
        if (Ember.isEmpty(groups)) {
          var groupId = "0";
          var group = {
            id: groupId,
            // TODO I18N
            name: "weitere"
          };
          defaultGroupWithEmployees = defaultGroupWithEmployees || {
            group: group,
            employees: []
          };
          defaultGroupWithEmployees.employees.push(employee);
        } else {
          groups.forEach(function (group) {
            var groupId = group.get("id");
            var groupWithEmployees = employeesByGroup[groupId] || {
              group: group,
              employees: []
            };
            groupWithEmployees.employees.push(employee);
            employeesByGroup[groupId] = groupWithEmployees;
          });
        }
      });
      var result = [];
      for (var groupId in employeesByGroup) {
        var sortedEmployees = employeesByGroup[groupId].employees;
        var currentUser = sortedEmployees.get("firstObject");

        if (currentUser && currentUser.get("id") === this.get("auth.user.employee.id")) {
          currentUser.set("isActiveUser", true);
        }

        employeesByGroup[groupId].employees = sortedEmployees.sortBy(this.get("sortBy"));
        result.push(employeesByGroup[groupId]);
      }
      result = result.sortBy("group.name");
      if (defaultGroupWithEmployees !== null) {
        result.push(defaultGroupWithEmployees);
      }

      return result;
    }),

    actions: {
      // This is really a bad hack to keep the "state" of the jQuery based Semantic UI accordion when the view updates
      onAccordionChange: function onAccordionChange(el) {
        var items = document.querySelectorAll("#" + el.elementId + " > .title");
        var active = Array.prototype.map.call(items, function (item) {
          return item.classList.contains("active");
        });
        this.set("isActive", active);
      }
    }
  });
});