define("frontend/components/dashboard/daily-overview/target-work", ["exports", "frontend/util/dashboard/timeconversions"], function (exports, _timeconversions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var backgroundBorder = 2;
  var backgroundDashArray = 1;

  exports.default = Ember.Component.extend({
    tagName: "",

    radius: null,
    cxcy: null,
    lengthOfDash: null,
    dashArray: null,
    progressDashOffset: null,
    progressStrokeWidth: null,
    borderColor: null,

    pauseWidth: null,
    pauseRadius: null,
    pauseDashArray: null,
    pauseDashOffset: null,
    pauseLengthOfDash: null,

    status: null,
    didAbort: null,
    pendingTargetWorkDuration: null,
    pendingTargetPauseDuration: null,

    strokeColor: Ember.computed("borderColor", "status", function () {
      if (this.get("status") !== "present") {
        return "grey";
      }

      return this.get("borderColor");
    }),

    inPause: Ember.computed("status", function () {
      return this.get("status") === "paused";
    }),

    /* ----- Logical Operators ---- */
    progressTargetDashBG: Ember.computed("pendingTargetWorkDuration", "lengthOfDash", "dashArray", function () {
      return (0, _timeconversions.convertTimeToDash)(this.get("pendingTargetWorkDuration"), this.get("lengthOfDash"), this.get("dashArray"), true);
    }),

    progressTargetDash: Ember.computed("pendingTargetWorkDuration", "lengthOfDash", "dashArray", function () {
      return (0, _timeconversions.convertTimeToDash)(this.get("pendingTargetWorkDuration"), this.get("lengthOfDash"), this.get("dashArray"));
    }),

    pauseDashBG: Ember.computed("pendingTargetPauseDuration", "pauseLengthOfDash", "pauseDashArray", function () {
      return (0, _timeconversions.convertTimeToDash)(this.get("pendingTargetPauseDuration"), this.get("pauseLengthOfDash"), this.get("pauseDashArray"), true);
    }),

    pauseDash: Ember.computed("pendingTargetPauseDuration", "pauseLengthOfDash", "pauseDashArray", function () {
      return (0, _timeconversions.convertTimeToDash)(this.get("pendingTargetPauseDuration"), this.get("pauseLengthOfDash"), this.get("pauseDashArray"));
    }),

    noTargetWork: Ember.computed("pendingTargetWorkDuration", function () {
      return this.get("pendingTargetWorkDuration") > 0 ? false : true;
    }),

    noTargetPause: Ember.computed("pendingTargetPauseDuration", function () {
      return this.get("pendingTargetPauseDuration") > 0 ? false : true;
    }),

    /* ------ Styling Operators ------*/
    progressBGWidth: Ember.computed("progressStrokeWidth", function () {
      return Ember.String.htmlSafe("stroke-width: " + (this.get("progressStrokeWidth") + backgroundBorder) + "px;");
    }),

    progressWidth: Ember.computed("progressStrokeWidth", function () {
      return Ember.String.htmlSafe("stroke-width: " + this.get("progressStrokeWidth") + "px;");
    }),

    pauseBarBGWidth: Ember.computed(function () {
      return Ember.String.htmlSafe("stroke-width: " + (this.get("pauseWidth") + backgroundBorder) + "px;");
    }),

    pauseBarWidth: Ember.computed(function () {
      return Ember.String.htmlSafe("stroke-width: " + this.get("pauseWidth") + "px;");
    }),

    progressDashOffsetBG: Ember.computed("progressDashOffset", function () {
      return this.get("progressDashOffset") + backgroundDashArray;
    }),

    pauseDashOffsetBG: Ember.computed("pauseDashOffset", function () {
      return this.get("pauseDashOffset") + backgroundDashArray;
    })
  });
});