define("frontend/react/components/accounting/Accounting", ["exports", "frontend/react/components/accounting/Subscription", "frontend/react/components/accounting/Customer", "frontend/react/components/accounting/Invoices", "frontend/react/components/accounting/Payment"], function (exports, _Subscription, _Customer, _Invoices, _Payment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Accounting = function Accounting(_ref) {
    var customer = _ref.customer,
        payment = _ref.payment,
        subscription = _ref.subscription,
        setCustomer = _ref.setCustomer,
        setPayment = _ref.setPayment,
        setSubscription = _ref.setSubscription,
        subscriptionPreview = _ref.subscriptionPreview;
    return React.createElement(
      "div",
      { className: "accounting-overview" },
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "h5",
          { className: "ui top attached header" },
          "Abonnement"
        ),
        React.createElement(
          "div",
          { className: "ui attached segment" },
          React.createElement(_Subscription.default, {
            subscription: subscription,
            subscriptionPreview: subscriptionPreview,
            setSubscription: setSubscription
          })
        )
      ),
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "h5",
          { className: "ui top attached header" },
          "Bezahlung"
        ),
        React.createElement(
          "div",
          { className: "ui attached segment" },
          React.createElement(_Payment.default, {
            customer: customer,
            payment: payment,
            onUpdated: function onUpdated(payment) {
              return setPayment(payment);
            }
          })
        )
      ),
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "h5",
          { className: "ui top attached header" },
          "Rechnungsdaten"
        ),
        React.createElement(
          "div",
          { className: "ui attached segment" },
          React.createElement(_Customer.default, {
            customer: customer,
            onUpdated: function onUpdated(customer) {
              return setCustomer(customer);
            }
          })
        )
      ),
      React.createElement(
        "div",
        { className: "ui padded container" },
        React.createElement(
          "h5",
          { className: "ui top attached header" },
          "Rechnungen"
        ),
        React.createElement(
          "div",
          { className: "ui attached segment" },
          React.createElement(_Invoices.default, null)
        )
      )
    );
  };

  exports.default = Accounting;
});