define("frontend/react/context/AuthContext", ["exports", "react"], function (exports, _react) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AuthContextProvider = undefined;


  var AuthContext = (0, _react.createContext)({});

  var AuthContextProvider = exports.AuthContextProvider = AuthContext.Provider;

  exports.default = AuthContext;
});