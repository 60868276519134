define("frontend/components/ui/input-date", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    type: "date",
    placeholder: null,
    value: null,
    minDate: null,
    maxDate: null,

    selectYears: false,
    selectMonths: false,
    buttonClasses: "ui basic button",

    dateValue: Ember.computed("value", function () {
      var value = this.get("value");
      if (value) {
        return (0, _moment.default)(value);
      }
      return null;
    }),

    minDateValue: Ember.computed("minDate", function () {
      var value = this.get("minDate");
      if (value) {
        return (0, _moment.default)(value);
      }
      return null;
    }),

    maxDateValue: Ember.computed("maxDate", function () {
      var value = this.get("maxDate");
      if (value) {
        return (0, _moment.default)(value);
      }
      return null;
    }),

    disableMonthPicker: Ember.computed("selectMonths", function () {
      return !this.get("selectMonths");
    }),

    disableYearPicker: Ember.computed("selectYears", function () {
      return !this.get("selectYears");
    }),

    options: Ember.computed(function () {
      return [{
        action: "clearDate",
        label: "löschen",
        classes: "ui basic fluid button"
      }, {
        action: "selectToday",
        label: "heute",
        classes: "ui basic fluid button"
      }];
    }),

    actions: {
      updateValue: function updateValue(momentValue) {
        if (!momentValue) {
          this.set("value", null);
          return null;
        }

        // Selected date is interpreted as local time (at 0:00 with offset), just using toDate() would subtract the timezone offset and return a date on the previous day in UTC (-1 or -2 hours).
        // So we extract date components with toObject() (which do not include offsets) and construct a UTC moment that is then converted to a JS Date.

        var _momentValue$toObject = momentValue.toObject(),
            years = _momentValue$toObject.years,
            months = _momentValue$toObject.months,
            date = _momentValue$toObject.date;

        var dateValue = _moment.default.utc([years, months, date, 0, 0, 0, 0]).toDate();

        this.set("value", dateValue);
      }
    }
  });
});