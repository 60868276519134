define("frontend/components/ui/input-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    type: "number",

    number: null,
    transform: function transform(input) {
      return input;
    },

    value: Ember.computed("number", {
      get: function get() {
        var t = this.get("transform");
        return t(this.get("number"), false);
      },
      set: function set(key, value) {
        if (Ember.isBlank(value)) {
          this.set("number", null);
          return;
        }

        var v = typeof value !== "number" ? parseFloat(value.replace(",", ".")) : value;
        if (isNaN(v)) {
          v = null;
        }
        var t = this.get("transform");
        this.set("number", t(v, true));
        return value;
      }
    })
  });
});