define("frontend/models/signup", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Signup = Ember.Object.extend({
    email: null,
    firstname: null,
    lastname: null,
    organisation: null,
    password: null,
    passwordConfirmation: null,
    termsConditions: null,

    reset: function reset() {
      this.setProperties({
        email: null,
        firstname: null,
        lastname: null,
        organisation: null,
        password: null,
        passwordConfirmation: null,
        termsConditions: null
      });
    }
  });
  exports.default = Signup;
});