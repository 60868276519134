define("frontend/components/user/new-password", ["exports", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PasswordValidations = {
    password: (0, _validators.validateLength)({
      min: 8,
      allowBlank: true,
      message: "Password needs at least 8 characters"
    }),
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: "password",
      message: "doesn't match password"
    })
  };

  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    token: null,
    password: null,
    passwordConfirmation: null,
    loading: false,
    "on-success": NOOP,

    ajax: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default(this, (0, _emberChangesetValidations.default)(PasswordValidations), PasswordValidations);
    },


    actions: {
      setNewPassword: function setNewPassword() {
        var _this = this;

        var changeset = this.changeset;

        changeset.validate().then(function () {
          if (changeset.get("isValid")) {
            changeset.execute();

            var data = {
              password: _this.get("password"),
              token: _this.get("token")
            };
            _this.set("loading", true);
            _this.get("ajax").post("/api/users/password-reset", {
              dataType: "text",
              data: data
            }).then(function () {
              _this.get("on-success")();
            }, function (error) {
              _this.get("logger").catch(error, "Error while resetting password", { emailAddress: _this.get("email") });
            }).finally(function () {
              _this.set("loading", false);
            });
          }
        });
      }
    }
  });
});