define("frontend/services/logger", ["exports", "@sentry/browser", "frontend/util/error-status", "frontend/config/environment"], function (exports, _browser, _errorStatus, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),

    ignoreError: function ignoreError(reason) {
      if (reason) {
        if (reason.name === "TransitionAborted") {
          return true;
        }
        // Ignore client errors (e.g. 400, 401, 403, 404, 422)
        var status = (0, _errorStatus.default)(reason);
        if (typeof status === "number" && status >= 400 && status < 500) {
          return true;
        }
      }
      return false;
    },
    catch: function _catch(reason) {
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Catched error";
      var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var notify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      var severity = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "error";

      if (notify) {
        var errorMessage = this.tMessage(message);
        var text = "\n        <div class=\"header\">\n          Es ist ein Fehler aufgetreten\n        </div>\n        <p>" + errorMessage + "</p>\n        <p>Bitte versuchen Sie die Aktion erneut oder <a href=\"/\">laden Sie die Anwendung neu</a>.</p>\n      ";

        this.get("notify").alert({
          html: text,
          closeAfter: null
        });
      }

      if (_environment.default.sentry.development || this.ignoreError(reason)) {
        return;
      }

      _browser.withScope(function (scope) {
        scope.setExtra("level", severity);
        scope.setExtra("context", context);
        if (reason instanceof Error) {
          scope.setExtra("message", message);
          _browser.captureException(reason);
        } else {
          scope.setExtra("reason", reason);
          _browser.captureMessage(message);
        }
      });
    },
    tMessage: function tMessage(eMessage) {
      var t = this.intl.lookup("errors." + eMessage);
      if (t.indexOf("Missing translation") === 0) {
        return eMessage;
      }
      return t;
    }
  });
});