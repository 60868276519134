define("frontend/routes/settings/organisation/index", ["exports", "frontend/mixins/route/secure"], function (exports, _secure) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_secure.default, {
    auth: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        organisation: this.get("auth.user.organisation"),
        user: this.get("auth.user")
      });
    }
  });
});