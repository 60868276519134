define("frontend/mixins/route/secure", ["exports", "frontend/util/log"], function (exports, _log) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    auth: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this;

      return this.get("auth").get("user").then(function (user) {
        if (!user) {
          _this.get("auth").reset();
          _this.transitionTo("login");
        }
      }, function (error) {
        _log.default.error("Could not load user, resetting authentication", error);
        _this.get("auth").reset();
        _this.transitionTo("login");
      });
    }
  });
});