define("frontend/react/components/accounting/payment/IbanForm", ["exports", "react", "@stripe/react-stripe-js"], function (exports, _react, _reactStripeJs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = IbanForm;

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  // Custom styling can be passed as options when creating an Element.
  var IBAN_STYLE = {
    base: {
      "::placeholder": {
        color: "#aab7c4"
      },
      ":-webkit-autofill": {
        color: "#32325d"
      }
    },
    invalid: {
      color: "#DB2828",
      iconColor: "#DB2828",
      ":-webkit-autofill": {
        color: "#DB2828"
      }
    }
  };

  var IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "DE",
    style: IBAN_STYLE
  };

  function IbanForm(_ref) {
    var values = _ref.values,
        setValues = _ref.setValues;

    return _react.default.createElement(
      _react.Fragment,
      null,
      _react.default.createElement(
        "div",
        { className: "field" },
        _react.default.createElement(
          "label",
          null,
          "Name des Kontoinhabers"
        ),
        _react.default.createElement("input", {
          name: "accountholder-name",
          value: values.accountHolderName,
          onChange: function onChange(event) {
            return setValues(_extends({}, values, {
              accountHolderName: event.target.value
            }));
          },
          required: true
        })
      ),
      _react.default.createElement(
        "div",
        { className: "field" },
        _react.default.createElement(
          "label",
          null,
          "IBAN",
          _react.default.createElement(_reactStripeJs.IbanElement, {
            options: IBAN_ELEMENT_OPTIONS,
            onChange: function onChange(event) {
              return setValues(_extends({}, values, {
                ibanComplete: event.complete
              }));
            }
          })
        )
      ),
      _react.default.createElement(
        "div",
        { className: "ui segment raised mandate-acceptance" },
        "Indem Sie Ihre IBAN angeben und diese Zahlung best\xE4tigen, erm\xE4chtigen Sie networkteam GmbH und Stripe, unseren Zahlungsdienstleister, Ihrer Bank Anweisungen zur Belastung Ihres Kontos gem\xE4\xDF diesen Anweisungen zu senden. Sie haben Anspruch auf eine R\xFCckerstattung von Ihrer Bank gem\xE4\xDF den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine R\xFCckerstattung muss innerhalb von acht Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden."
      )
    );
  }
});