define("frontend/util/timecalculations", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calculateFirstDayToShow = calculateFirstDayToShow;
  exports.calculateLastDayToShow = calculateLastDayToShow;
  exports.previousMonth = previousMonth;
  exports.nextMonth = nextMonth;
  function calculateFirstDayToShow(date) {
    var firstDayOfMonth = (0, _moment.default)(date);
    var weekday = firstDayOfMonth.weekday();

    return firstDayOfMonth.subtract(weekday, "days");
  }

  function calculateLastDayToShow(date) {
    var lastDayOfMonth = (0, _moment.default)(date);
    var weekday = lastDayOfMonth.weekday();

    return lastDayOfMonth.add(6 - weekday, "days");
  }

  function previousMonth(y, m) {
    m--;
    if (m === 0) {
      m = 12;
      y--;
    }
    return [y, m];
  }

  function nextMonth(y, m) {
    m++;
    if (m > 12) {
      m = 1;
      y++;
    }
    return [y, m];
  }
});