define("frontend/routes/account/edit", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),

    model: function model() {
      return this.get("auth.user");
    },
    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    }
  });
});