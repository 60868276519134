define('frontend/util/matomo-tracking', ['exports', 'frontend/util/features'], function (exports, _features) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = trackPageVisit;


  /**
   * Implements a helper to push page visits to the matomo server.
   * Use `pageTitle` to set the page title to a proper value.
   * The default page title given by the application is 'Werkzeit'.
   *
   * @param {string} pageTitle overrides the default page title.
   */
  function trackPageVisit() {
    var pageTitle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;


    if (!(0, _features.default)('tracking')) {
      return;
    }

    window._paq = window._paq || [];

    if (pageTitle) {
      window._paq.push(['setDocumentTitle', pageTitle]);
    }

    window._paq.push(['trackPageView']);
  }
});