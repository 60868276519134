define("frontend/models/presence-versions-entry", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    checkin: _emberData.default.attr("date"),
    checkout: _emberData.default.attr("date"),
    aborted: _emberData.default.attr("boolean"),
    workDuration: _emberData.default.attr("number"),
    pauseDuration: _emberData.default.attr("number"),
    pauseIntervals: _emberData.default.attr("intervals"),
    employeeId: _emberData.default.attr("string"),

    editedBy: _emberData.default.belongsTo("user"),
    editedOn: _emberData.default.attr("date"),
    comment: _emberData.default.attr("string")
  });
});