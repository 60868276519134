define("frontend/components/employee/presence-history/details", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    presence: null,

    versions: Ember.computed.readOnly("presence.versions"),

    initialVersion: Ember.computed("versions.content", "presence", function () {
      var versions = this.get("versions.content");
      if (versions && versions.get("length") > 0) {
        return versions.get("firstObject");
      }
      return this.get("presence");
    }),

    editedVersions: Ember.computed("versions.content", "presence", function () {
      var result = [];

      var versions = this.get("versions.content");
      if (versions && versions.get("length") > 0) {
        // Append everything but the first item to result
        versions.forEach(function (version, index) {
          if (index > 0) {
            result.push(version);
          }
        });
        // Append current version to result
        result.push(this.get("presence"));
      }
      return Ember.A(result);
    })
  });
});