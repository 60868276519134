define("frontend/util/error-status", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = errorStatus;
  function errorStatus(err) {
    if (err) {
      if (err.status) {
        return err.status;
      }
      if (err.jqXHR && err.jqXHR.status) {
        return err.jqXHR.status;
      }
      if (err.errors && err.errors[0] && err.errors[0].status) {
        return parseInt(err.errors[0].status);
      }
    }
    return null;
  }
});