define("frontend/util/event-bus", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Singleton instance shared for Ember + React
  var EventBus = Ember.Object.extend(Ember.Evented, {});

  exports.default = EventBus.create();
});