define("frontend/components/employee/calendar-monthly/edit-day", ["exports", "ember-data", "moment", "frontend/mixins/pending-presence-calculation"], function (exports, _emberData, _moment, _pendingPresenceCalculation) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pendingPresenceCalculation.default, {
    classNames: ["ui", "basic", "segment", "edit-day"],
    classNameBindings: ["loading"],

    // Passed to component
    employee: null,
    day: null,
    presencesByDay: null,

    // Instance for adding a new presence or vacation
    newPresence: null,
    newVacation: null,

    // Used to trigger a reload of the data
    reloadCounter: 0,

    clock: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    auth: Ember.inject.service(),

    presenceForDay: Ember.computed("day.{inactive,day}", "presencesByDay.content", function () {
      var presencesByDay = this.get("presencesByDay.content");

      var currentDay = (0, _moment.default)(this.get("day.date"));
      if (presencesByDay) {
        return presencesByDay.find(function (presence) {
          var presenceActual = (0, _moment.default)(presence["date"]).utc();
          return currentDay.utc().isSame(presenceActual, "day");
        });
      }
      return null;
    }),

    hasEditPermission: Ember.computed("auth.user.permissions", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      if (this.get("employee.id") === this.get("auth.user.employee.id")) {
        return permissions.indexOf("permissionSelfPresenceWrite") >= 0;
      } else {
        return permissions.indexOf("permissionAllPresenceWrite") >= 0;
      }
    }),

    // TODO This should go in the auth service / helper
    hasVacationEditPermission: Ember.computed("auth.user.permissions", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      if (permissions.indexOf("permissionAllVacationWrite") >= 0) {
        return true;
      }
      if (this.get("employee.id") === this.get("auth.user.employee.id")) {
        return this.get("auth.user.employee.vacationSelfServiceLevel") === "all";
      }
      return false;
    }),

    // TODO: Store Queries should not appear in component.
    presences: Ember.computed("employee", "day.date", "reloadCounter", "employee.status", function () {
      var date = (0, _moment.default)(this.get("day.date")),
          startOfDay = (0, _moment.default)(date).startOf("day"),
          nextDay = (0, _moment.default)(date).add(1, "days");
      var params = {
        employee_id: this.get("employee.id"),
        from: startOfDay.toJSON(),
        to: nextDay.toJSON()
      };
      var presences = this.get("store").query("presence", params);

      // TODO Include pending presence here

      return _emberData.default.PromiseObject.create({
        promise: presences
      });
    }),

    // TODO: Store Queries should not appear in component.
    // TODO This should happen when loading the presence report, better even get vacations for day from server via separate query
    vacation: Ember.computed("presenceForDay.vacation", function () {
      var vacationPayload = this.get("presenceForDay.vacation");
      if (vacationPayload) {
        // Push would be more suitable but doesn't transform attributes correctly
        this.get("store").pushPayload("vacation", { vacation: vacationPayload });
        var vacation = this.get("store").peekRecord("vacation", vacationPayload.id);
        return vacation;
      }
      return null;
    }),

    loading: Ember.computed.readOnly("presences.isPending"),

    // Remove the hidden overflow once the edit-day is loaded (and expanded), otherwise pickadate will not show completely
    removeOverflowOnLoad: Ember.observer("loading", function () {
      var _this = this;

      if (!this.get("loading")) {
        Ember.run.later(function () {
          var $el = _this.$();
          // The edit day view could alrady be closed after the delay
          if ($el) {
            $el.css({ overflow: "inherit" });
          }
        }, 500);
      }
    }),

    _reload: function _reload() {
      this.incrementProperty("reloadCounter");
      if (this.get("on-change")) {
        this.get("on-change")();
      }
    },


    actions: {
      addPresence: function addPresence() {
        var newPresence = this.get("store").createRecord("presence", {
          employeeId: this.get("employee.id"),
          checkin: (0, _moment.default)(this.get("day.date")).hour(9).toDate(),
          checkout: (0, _moment.default)(this.get("day.date")).hour(18).toDate(),
          pauseIntervals: Ember.A([])
        });
        this.set("newPresence", newPresence);
      },
      addVacation: function addVacation() {
        var newVacation = this.get("store").createRecord("vacation", {
          employeeId: this.get("employee.id"),
          start: (0, _moment.default)(this.get("day.date")).utc().startOf("day").toDate(),
          end: (0, _moment.default)(this.get("day.date")).utc().startOf("day").toDate(),
          type: "holiday",
          comment: ""
        });
        this.set("newVacation", newVacation);
      },
      successAddPresence: function successAddPresence() {
        this.set("newPresence", null);
        // TODO I18N
        this.get("notify").success("Anwesenheit erstellt");
        this._reload();
      },
      cancelAddPresence: function cancelAddPresence() {
        this.set("newPresence", null);
      },
      successUpdatePresence: function successUpdatePresence() {
        // TODO I18N
        this.get("notify").success("Anwesenheit geändert");
        this._reload();
      },
      successDeletePresence: function successDeletePresence() {
        // TODO I18N
        this.get("notify").success("Anwesenheit gelöscht");
        this._reload();
      },
      successAddVacation: function successAddVacation() {
        this.set("newVacation", null);
        // TODO I18N
        this.get("notify").success("Urlaub erstellt");
        this._reload();
      },
      cancelAddVacation: function cancelAddVacation() {
        this.set("newVacation", null);
      },
      successUpdateVacation: function successUpdateVacation() {
        // TODO I18N
        this.get("notify").success("Urlaub geändert");
        this._reload();
      },
      successDeleteVacation: function successDeleteVacation() {
        // TODO I18N
        this.get("notify").success("Urlaub gelöscht");
        this._reload();
      }
    }
  });
});