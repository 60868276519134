define("frontend/components/employee/status-dropdown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NOOP = function NOOP() {};

  exports.default = Ember.Component.extend({
    classNames: ["status-dropdown"],

    onchange: NOOP,

    employee: null,
    pending: false,

    _failedStatusDefinitionId: false,

    auth: Ember.inject.service(),
    store: Ember.inject.service(),
    logger: Ember.inject.service(),

    hasStatusDefinitions: Ember.computed.notEmpty("statusDefinitions"),

    statusDefinitions: Ember.computed(function () {
      return this.get("store").peekAll("status-definition");
    }),

    hasPermission: Ember.computed("auth.user.{permissions,employee.id}", "employee.id", function () {
      var permissions = this.get("auth.user.permissions");
      if (!Ember.isArray(permissions)) {
        return false;
      }
      if (this.get("employee.id") === this.get("auth.user.employee.id")) {
        return permissions.indexOf("permissionSelfPresenceChangeState") >= 0;
      } else {
        return permissions.indexOf("permissionAllPresenceChangeState") >= 0;
      }
    }),

    resetFailedStatusDefinitionId: Ember.observer("employee", "employee.statusInterval.statusDefinitionId", function () {
      this.set("_failedStatusDefinitionId", false);
    }),

    selectedStatusDefinitionId: Ember.computed("employee.statusInterval.statusDefinitionId", "_failedStatusDefinitionId", {
      get: function get() {
        var _failedStatusDefinitionId = this.get("_failedStatusDefinitionId");
        if (_failedStatusDefinitionId !== false) {
          return _failedStatusDefinitionId || "0";
        }
        var id = this.get("employee.statusInterval.statusDefinitionId");
        return id || "0";
      },
      set: function set(key, value) {
        var _this = this;

        var statusDefinitionId = value === "0" ? "" : value,
            previousStatusDefinitionId = this.get("employee.statusInterval.statusDefinitionId");
        this.set("_failedStatusDefinitionId", false);
        this.set("pending", true);
        this.get("employee").setstatus(statusDefinitionId).then(function () {
          _this.get("onchange")(statusDefinitionId);
        }, function (error) {
          _this.set("_failedStatusDefinitionId", previousStatusDefinitionId);
          _this.get("logger").catch(error, "Error performing status change on employee", { employeeId: _this.get("employee.id") });
        }).finally(function () {
          _this.set("pending", false);
        });
        return value;
      }
    })
  });
});