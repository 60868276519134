define("frontend/controllers/settings/groups/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    logger: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      add: function add() {
        this.get('router').transitionTo("settings.groups.new");
      },
      delete: function _delete(group) {
        var _this = this;

        group.destroyRecord().then(function () {
          // I18N
          _this.get("notify").success("Gruppe gelöscht");
          _this.get('router').transitionTo("settings.groups");
        }, function (error) {
          _this.get("logger").catch(error, "Error deleting group", {
            groupId: Ember.get(group, "id")
          });
        });
      }
    }
  });
});