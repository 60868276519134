define("frontend/controllers/settings/groups/new", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    router: Ember.inject.service(),

    actions: {
      success: function success() {
        this.get("notify").success("Gruppe hinzugefügt");
        this.get('router').transitionTo("settings.groups");
      },
      cancel: function cancel() {
        this.get('router').transitionTo("settings.groups");
      }
    }
  });
});