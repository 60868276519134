define("frontend/routes/dashboard/index", ["exports", "moment", "frontend/mixins/route/secure", "frontend/util/promises"], function (exports, _moment, _secure, _promises) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_secure.default, {
    ajax: Ember.inject.service(),
    logger: Ember.inject.service(),
    auth: Ember.inject.service(),
    employee: Ember.computed.readOnly("auth.user.employee"),
    store: Ember.inject.service(),
    stream: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var presencesFrom = (0, _moment.default)().utc().startOf("day").subtract(30, "days"),
          presencesTo = (0, _moment.default)().utc().startOf("day").subtract(1, "day");
      return Ember.RSVP.hash({
        summaryOnDay: this._findSummary(),
        presencesByDay: (0, _promises.reloadablePromiseObject)(function () {
          return _this.get("ajax").request("/api/presences/report?employee_id=" + _this.get("employee.id") + "&from=" + presencesFrom.format("YYYY-MM-DD") + "&to=" + presencesTo.format("YYYY-MM-DD")).catch(function (error) {
            _this.get("logger").catch(error, "Error getting presences report");
          });
        }),
        vacationReport: (0, _promises.reloadablePromiseObject)(function () {
          var year = new Date().getFullYear();
          return _this.get("ajax").request("/api/vacations/report?employee_id=" + _this.get("employee.id") + "&year=" + year).catch(function (error) {
            _this.get("logger").catch(error, "Error getting vacation report");
          });
        })
      });
    },
    activate: function activate() {
      var _this2 = this;

      this.stream.on("received", this, this._onMessageReceived);
      this.stream.on("reconnected", this, this._onReconnect);

      this._refreshOnFocus = function () {
        _this2._onReconnect();
      };
      window.addEventListener("focus", this._refreshOnFocus);
    },
    _findSummary: function _findSummary() {
      return this.store.findRecord("summary", this.get("employee.id") + "_" + (0, _moment.default)(new Date()).format("YYYY-MM-DD"), { reload: true });
    },
    _onReconnect: function _onReconnect() {
      var _this3 = this;

      // Refetch summary for current day and update model of controller
      this._findSummary().then(function (summaryOnDay) {
        Ember.set(_this3.controller, "model.summaryOnDay", summaryOnDay);
      });
    },
    _onMessageReceived: function _onMessageReceived(message) {
      var employeeId = this.get("auth.user.employee.id");

      if (!(message.employee && message.employee.id === employeeId)) {
        return;
      }

      if (message.type === "summary" && message.event === "Change") {
        var _modelFor = this.modelFor(this.routeName),
            summaryOnDay = _modelFor.summaryOnDay;

        if (!summaryOnDay) {
          return;
        }

        if (message.timeRange) {
          var _message$timeRange = message.timeRange,
              fromString = _message$timeRange.from,
              _toString = _message$timeRange.to;

          var from = _moment.default.utc(fromString);
          var to = _moment.default.utc(_toString);

          if (this.controller) {
            this.controller.send("summaryChanged", from, to);
          }
        }
      } else if (message.type === "vacation") {
        var _message$timeRange2 = message.timeRange,
            _fromString = _message$timeRange2.from,
            _toString2 = _message$timeRange2.to;

        var _from = _moment.default.utc(_fromString);
        var _to = _moment.default.utc(_toString2);

        if (this.controller) {
          this.controller.send("vacationChanged", _from, _to);
        }
      }
    },
    deactivate: function deactivate() {
      this.stream.off("received", this, this._onMessageReceived);
      this.stream.off("reconnected", this, this._onReconnect);

      window.removeEventListener("focus", this._refreshOnFocus);
      this._refreshOnFocus = null;
    }
  });
});