define("frontend/components/ui/input-copy-to-clipboard", ["exports", "clipboard"], function (exports, _clipboard) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var buttonEl = this.element.querySelector("button");
      var inputEl = this.element.querySelector("input");
      this.clip = new _clipboard.default(buttonEl, {
        target: function target() {
          return inputEl;
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (this.clip) {
        this.clip.destroy();
      }
    }
  });
});