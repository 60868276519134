define("frontend/helpers/employee-image", ["exports", "frontend/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.employeeImage = employeeImage;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function employeeImage(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        imageIds = _ref2[0];

    var size = options && options.size ? options.size : 512;
    if (imageIds && imageIds[size]) {
      return _environment.default.apiUrlPrefix + "/employee/image/" + imageIds[size];
    } else {
      return "/assets/images/default-profile.png";
    }
  }

  exports.default = Ember.Helper.helper(employeeImage);
});