define("frontend/components/planning/capacity-overview", ["exports", "moment", "ember-data"], function (exports, _moment, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    logger: Ember.inject.service(),
    ajax: Ember.inject.service(),

    classNames: ["planning-capacity-overview"],

    employees: null,
    groups: null,

    // Passed on from controller
    startDate: null,
    endDate: null,
    selectedGroup: null,

    loading: Ember.computed.readOnly("employeesAndReport.isPending"),

    clock: Ember.inject.service("slow-clock"),

    filteredEmployees: Ember.computed("selectedGroup", "employees", function () {
      var employees = this.get("employees");
      var selectedGroup = this.get("selectedGroup");

      if (!selectedGroup) {
        return employees;
      }

      return employees.filter(function (employee) {
        var groups = employee.get("groups");
        return groups.includes(selectedGroup);
      });
    }),

    dates: Ember.computed("startDate", "endDate", function () {
      var dates = [];
      var endDate = (0, _moment.default)(this.get("endDate")),
          startDate = (0, _moment.default)(this.get("startDate"));
      for (var currentDate = startDate; currentDate.isBefore(endDate); currentDate = currentDate.add(1, "day")) {
        dates.push(currentDate.toDate());
      }
      return dates;
    }),

    showActuals: Ember.computed("startDate", "clock.time", function () {
      var now = (0, _moment.default)(this.get("clock.time"));
      var startDate = (0, _moment.default)(this.get("startDate"));
      return startDate.isBefore(now);
    }),

    isPending: Ember.computed("endDate", "clock.time", function () {
      var now = (0, _moment.default)(this.get("clock.time"));
      var endDate = (0, _moment.default)(this.get("endDate"));
      return endDate.isAfter(now);
    }),

    employeesAndReport: Ember.computed("startDate", "endDate", function () {
      var _this = this;

      var fromDate = (0, _moment.default)(this.get("startDate"));
      var toDate = (0, _moment.default)(this.get("endDate"));

      var firstDayToShow = fromDate.format("YYYY-MM-DD");
      var lastDayToShow = toDate.format("YYYY-MM-DD");

      var promise = this.get("ajax").request("/api/presences/report?from=" + firstDayToShow + "&to=" + lastDayToShow);
      promise.catch(function (error) {
        _this.get("logger").catch(error, "Error getting report for employees");
      });

      return _emberData.default.PromiseObject.create({ promise: promise });
    }),

    reportByEmployeeId: Ember.computed("filteredEmployees", "employeesAndReport.content", function () {
      var employeesAndReport = this.get("employeesAndReport.content");
      var employees = this.get("filteredEmployees");
      var result = {};
      var reportByEmployeeId = {};
      if (!Ember.isArray(employeesAndReport)) {
        return result;
      }

      employeesAndReport.forEach(function (employeeAndReport) {
        reportByEmployeeId[employeeAndReport.employeeId] = employeeAndReport.report;
      });

      var totalTargetWorkDurationSum = 0;
      var totalActualWorkDurationSum = 0;
      var dateTotals = [];

      employees.forEach(function (employee) {
        var targetWorkDurationSum = 0;
        var actualWorkDurationSum = 0;
        var dateEntries = [];

        var report = reportByEmployeeId[employee.id];
        if (report) {
          dateEntries = report.map(function (entry) {
            return {
              targetWorkDuration: entry.vacation ? 0 : entry.targetWorkDuration,
              actualWorkDuration: entry.vacation ? 0 : entry.actualWorkDuration,
              vacation: entry.vacation && entry.vacation.type,
              holiday: entry.holiday ? entry.holidayTitle : null
            };
          });

          targetWorkDurationSum = dateEntries.map(function (entry) {
            return Math.max(0, entry.targetWorkDuration);
          }).reduce(function (value, sum) {
            return value + sum;
          }, 0);

          actualWorkDurationSum = dateEntries.map(function (entry) {
            return Math.max(0, entry.actualWorkDuration);
          }).reduce(function (value, sum) {
            return value + sum;
          }, 0);

          dateEntries.forEach(function (entry, index) {
            dateTotals[index] = dateTotals[index] || 0;
            dateTotals[index] += Math.max(0, entry.targetWorkDuration);
          });
        }

        totalTargetWorkDurationSum += targetWorkDurationSum;
        totalActualWorkDurationSum += actualWorkDurationSum;

        result[employee.id] = {
          dateEntries: dateEntries,
          targetWorkDurationSum: targetWorkDurationSum,
          actualWorkDurationSum: actualWorkDurationSum
        };
      });

      result.dateTotals = dateTotals;
      result.totalTargetWorkDurationSum = totalTargetWorkDurationSum;
      result.totalActualWorkDurationSum = totalActualWorkDurationSum;

      return result;
    }),

    actions: {
      selectGroup: function selectGroup(group) {
        this.set("selectedGroup", group);
      }
    }
  });
});